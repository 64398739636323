.screensaver .selected-screensaver {
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 340px;
  height: 72px;

  border: 1px solid $color-grey-2;
  border-radius: 5px;

  color: $color-brand-marengo-ultradark;
  padding: 0 20px 0 12px;
}

.screensaver .selected-screensaver img {
  width: 70px;
  height: 40px;
}

.screensaver .selected-screensaver .selected {
  display: flex;
  align-items: center;

  .label {
    margin-left: 10px;
  }
}

.screensaver .selected-screensaver .remove {
  svg {
    fill: $color-grey-2;
  }
}

.screensaver.btn {
  height: unset;
}
