.form-invite .Select-menu-outer div {
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c9cdd9;
    border-radius: 4px;
  }
}

.Select:not(.form-invite):not(.form-filter):not(.clear) {
  //border: solid 1px #e3eaf2;
  box-sizing: padding-box;
  font-family: 'FontAwesome', sans-serif;

  .Select-control {
    text-align: center;
    border-radius: 20px;
    transition: border-color 0.15s linear;
    border: solid 1px $grey-border;
    //border: solid 2px rgba(0, 0, 0, 0);
    height: 26px;
    padding: 0;
    margin: 0;

    &:hover {
      border: solid 2px $darkest-blue;
      margin-top: -1px;
      margin-right: -1px;
      margin-left: -1px;
    }

    .is-open {
      border-radius: 20px;
      border: solid 2px $darkest-blue;
    }

    .Select-input {
      height: 26px;
      min-width: 100px;
    }

    .Select-value-label {
      margin-left: 1.3em;
      &::after {
        color: inherit;
        font-family: 'Font Awesome 5 Pro', sans-serif;
        font-weight: 400;
        width: 0;
        height: 0;
        vertical-align: middle;
        content: '\F107';
        border: none;
        font-size: inherit;
        margin-left: 1.3em;
      }
    }
  }

  .Select-menu-outer {
    z-index: 1999;
    top: auto;
    bottom: 100%;
    width: 150px;
    margin: 5px;
    color: #000000;
    text-align: left;
    box-shadow: none;
    border: solid 1px $light-grey;
  }

  .Select-arrow-zone {
    display: none;
  }

  &.is-open > .Select-control {
    border-radius: 20px;
    border-color: $darkest-blue;
    border-width: 2px;
    margin-top: -1px;
    margin-right: -1px;
  }
}

.Select-width-150 {
  width: 150px;
}

.Select-width-140 {
  width: 140px;
}

.Select:not(.form-invite):not(.clear).has-value.Select--single
  > .Select-control
  .Select-value
  .Select-value-label,
.Select:not(.form-invite):not(.clear).has-value.is-pseudo-focused.Select--single
  > .Select-control
  .Select-value
  .Select-value-label {
  color: $darkest-blue;
  font-size: 14px;
  line-height: 26px;
}

.dropdown-custom {
  display: flex;
  flex-grow: 1;
  font-family: 'FontAwesome', sans-serif;
}

.dropdown-label-left,
.counts {
  color: #666;
  font-weight: 200;
  font-size: 12px;
  font-family: var(--font-family-primary);
}

.counts {
  padding-left: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 700;
  &.from-start {
    margin-left: -10px;
  }
  &.counts-header {
    //margin-bottom: 20px;
    margin-bottom: -5px;
    margin-top: -15px;
  }
}

.margin-left-auto {
  margin-left: auto;
}

.Select-type-1 {
  width: 134px;
  .Select-value {
    display: flex;
    padding: 0 16px 0 16px !important;
    > span {
      margin: 0 !important;
    }
    > span::after {
      margin-left: 11px !important;
    }
  }
}

.Select-type-2 {
  .Select-value {
    display: flex;
    padding: 0 19px 0 19px !important;
    > span {
      margin: 0 12px 0 auto !important;
    }
    > span::after {
      margin-left: 12px !important;
    }
  }
}

.Select.form-invite {
  .Select-control {
    height: 31px;
    .Select-value {
      height: 31px;
      line-height: 29px;
    }
    .Select-placeholder,
    .Select-value-label {
      line-height: 29px;
    }
    .Select-input {
      height: 29px;
    }
  }
}

.Select.form-filter.Select--multi {
  .Select-control {
    width: calc(100% + 5px);
    .Select-value-label {
      white-space: nowrap;
    }
  }
}

.date-filter-value {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
}

.dropdown-menu {
  width: 300px;
  z-index: 9999;
  .dropdown-header-actions {
    display: block;
    padding: 0.5rem 20px;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    color: $text-normal-grey;
    white-space: nowrap;
  }
  .actions-item {
    color: $grey-400;
    font-weight: 700;
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
  }
}

.slack-alert-item {
  .Select-control {
    height: 26px !important;
  }
}

.saved-views-item {
  i {
    color: transparent;
  }
  &:hover {
    i {
      color: $light-grey-text;
    }
  }
  &.active {
    color: white;
  }
}

.Select.form-filter.has-value.Select--multi {
  .Select-multi-value-wrapper {
    .Select-value {
      display: inline-flex; // for "x" and value stay one line content
    }
  }
}

.Select.form-filter.Select--multi:not(.has-value) {
  width: 250px;
}

.dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  .span-like-select {
    border-bottom: 1px solid #ccc;
    width: 200px;
    padding: 0.3rem 10px;
    //font-size: 16px;
    position: relative;
    &::after {
      content: '';
      right: 10px;
      top: 45%;
      position: absolute;
      border-style: solid;
      display: inline-block;
      height: 0;
      width: 0;
    }
    .span-like-select-value {
      display: block;
      word-break: break-all;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.dropdown:not(.show) {
  .span-like-select {
    &::after {
      border-color: #999 transparent transparent;
      border-width: 5px 5px 2.5px;
    }
  }
}

.dropdown.show {
  .span-like-select {
    &::after {
      border-color: transparent transparent #999;
      border-width: 0 5px 5px;
    }
  }
}

.new-action-item-design {
  display: flex;
  align-items: center;
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-weight: 400;
  color: $grey-500;
  text-align: left;
  padding: 10px 20px;
  transition: 0.5s;
  gap: 8px;
  cursor: pointer;
  &.disabled {
    pointer-events: none;
    color: $grey-400;
  }
  &:hover {
    color: #000;
    background-color: #fff;
  }
  &:active {
    color: #000;
    background-color: #fff;
  }
  &:focus {
    outline: none;
  }
  &:first-of-type {
    margin-top: 10px;
  }
  &:last-of-type {
    margin-bottom: 10px;
  }
}
