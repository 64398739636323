.flex-6 {
  flex: 0.6;
}

.bl-p-align-end {
  display: flex;
  align-items: flex-end;
}

.bl-p-grid-4 {
  display: grid;
  grid-template-columns: 1fr 0.3fr 0.5fr 0.3fr;
  grid-gap: var(--b-gap1);
}

.bl-p-align-self-end {
  align-self: flex-end;
}

.bl-p-align-self-start {
  align-self: flex-start;
}

.bl-p-password-mgmt-grid {
  display: grid;
}

.bl-p-password-mgmt-grid-items {
  display: grid;
  grid-template-columns: 1fr auto 1fr 50px;
  align-items: center;
  grid-gap: var(--b-gap2);
  padding: var(--b-gap);
  border-bottom: 1px solid var(--color-neutral-30);
}

.bl-p-computer-name-options,
.bl-p-computer-name-patterns {
  display: flex;
  padding: var(--b-gap1);
  border: 1px solid var(--color-neutral-30);
  border-radius: 4px;
  flex-wrap: wrap;
  min-height: 65px;
  transition: all 0.2s ease-in;
}

.bl-p-computer-name-option {
  user-select: none;
  font-size: 14px;
  color: #666;
  box-shadow: 0 0 0 1px #ccc;
  background-color: #f0f2f7;
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
  line-height: 24px;
  opacity: 1;
  transition: opacity 0.3s ease;
  margin: var(--b-gap-micro);
}

.bl-p-computer-name-patterns.--hovered,
.bl-p-computer-name-options.--hovered {
  border: 1px solid var(--color-neutral-60);
}

.bl-p-computer-name-option.--is-dragging {
  opacity: 0.4;
}

.bl-p-computer-name-option.--hovered {
  opacity: 0.7;
}

.bl-p-computer-name-option.--selected {
  background-color: #666;
}

.bl-p-computer-name-option.--selected > p {
  color: var(--color-neutral-0);
}
