.k-rule {
  border-bottom: 1px solid var(--color-neutral-20);
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;

  &.facet-parent_os_version {
    .k-rule-field {
      &.input {
        width: 135px;

        .b-select__single-value {
          .b-select__option-icons {
            display: none;
          }
        }
      }
    }
  }

  &.not-applicable,
  .not-applicable {
    .k-rule-field {
      pointer-events: none;
    }

    // <Select />, <MultiSelect />
    .b-select__single-value {
      color: var(--b-primary-marengo-dark);
    }

    .b-select__icon {
      color: var(--b-primary-marengo-light);
    }

    // <MultiSelect />
    .b-select-multi__value-container {
      border: 2px solid var(--b-primary-marengo-ultralight);
      background: var(--b-primary-marengo-ultralight);
    }

    // <Select />
    .b-select__control {
      border: 2px solid var(--b-primary-marengo-ultralight);
      background: var(--b-primary-marengo-ultralight);
    }

    // <TextInput />
    .b-txt-input__elm {
      border: 2px solid var(--b-primary-marengo-ultralight);
      background: var(--b-primary-marengo-ultralight);
      color: var(--b-primary-marengo-dark);
    }

    // <MultiText />
    .b-multi-text {
      border: 2px solid var(--b-primary-marengo-ultralight);
      background: var(--b-primary-marengo-ultralight);
    }

    .b-multi-text__icon {
      color: var(--b-primary-marengo-light);
    }
  }
}

.k-rule + .k-rule {
  padding-top: 10px;
}

.k-rule-field {
  padding-left: 10px;
  width: 250px;

  .b-select__menu {
    min-width: 240px;
  }

  .b-select--is-disabled .b-select__icon {
    color: var(--color-neutral-30);
  }

  &.value {
    width: 400px;

    .b-input-char-count {
      padding-top: 8px;
    }

    .b-txt-input--error-state {
      position: initial;
    }

    .b-txt-input__err-msg {
      margin-top: 44px;
      padding-top: 8px;
      padding-left: 10px;
      max-width: 800px;
      height: fit-content;
      top: 0;
      left: 0;
      overflow: hidden;
      overflow-wrap: anywhere;
    }
  }
}

.k-rule-field-group {
  width: 100%;
  position: relative;
  display: flex;
}

.k-rule-order,
.k-rule-action,
.k-rule-field-separator {
  max-height: 44px;
  display: flex;
  align-items: center;
}

.k-rule-order {
  min-width: 30px;
  font-weight: 600;
}

.k-rule-action {
  padding-left: 10px;
  width: 100%;
  justify-content: flex-end;
}

.k-rule-select-disabled {
  cursor: not-allowed;
}

.k-rule-select-disabled .b-select__control--is-disabled {
  border-color: var(--color-neutral-20) !important;
}

// fields
.k-rule-range {
  display: flex;

  .k-rule-range-input {
    width: 100%;

    .b-txt-input__elm {
      width: 100%;
    }
  }

  .b-txt-input__err-msg {
    background-color: #fff;
  }
}

.k-rule-api-multi-select {
  .b-async-select-multi__value-container-filled {
    padding: 4px 20px 4px 4px;
  }

  .b-async-select-multi__value-remove {
    margin: 4px !important;
    width: auto;
  }
}
