/* =========================== */
/* ==========!ANCHOR========== */
/* =========================== */

$anchor-height: 80px;
$anchor-z-index: 120;

/* ----------!MAIN---------- */
.anchor > .dock {
  padding-top: 5px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: $anchor-z-index;
}

.anchor > .dock > .case {
  background-color: $color-white;
  height: 64px;
  display: flex;
  align-items: center;
  box-shadow: 0px -4px 5px rgba(0, 0, 0, 0.04);
}

/*
.case {
  height: $anchor-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1456px;
  margin: auto;

  .node {
    display: flex;
    padding: $space-paper;
  }

  .btn-default + .btn-default {
    margin-left: 16px;
  }
}
*/

.anchor > .zone > .stub {
  height: (
    $anchor-height - 48px
  ); // 48px is the margin-bottom on <body> to be removed
}

/* ----------!JUSTIFY---------- */
.anchor-justify-right .area > .node {
  width: 100%;
  justify-content: flex-end;
}
