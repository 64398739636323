.lsm__switcher-container {
  width: 100%;
  float: right;
  align-items: right;
  justify-content: right;
  margin-bottom: 20px;
  margin-top: -50px;
  display: flex;
}

.settings-box {
  padding: "var(--b-gap2)";
  margin-top: "-20px";
}

.iphone-frame {
  background-image: url("../assets/iPhone-frame.png");
  width: 60%;
  height: 200px;
  background-image: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
}

.ipad-portrait-frame {
  width: 85%;
  height: 200px;
  background-image: url("../assets/iPad-portrait.png");
  background-image: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
}

.ipad-landscape-frame {
  width: 100%;
  height: 200px;
  background-image: url("../assets/iPad-landscape.png");
  background-image: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;
}

.swipe-bar {
  width: 100%;
  height: 15px;
  background-image: url("../assets/swipe-bar.png");
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center;
  margin-top: 20px;
}

.lockscreen-text {
  margin-top: 60px;
  font-size: 1.5em;
  font-weight: 500;
  position: relative;
}

.lockscreen-message {
  font-size: 10px;
  margin-top: 10px;
  height: 15px;
}

/* . */
.lsm_device-container-lsm {
  width: 100%;
  height: 250px;
  justify-content: center;
  display: flex;
}

.lsm_device-wrapper {
  height: 300px;
  width: 1000px;
  flex: 1;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Aligns child elements horizontally center */
}

.lsm_device-frame-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  margin-top: -40px;
  align-items: center;
}

/* . */
.preview-text {
  color: #aaaaaa;
}

/* . */
.outer-preview-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
}

/* . */
.inner-preview-box {
  min-height: 650px;
  width: 100%;
  max-height: 350px;
  min-height: 350px;
  display: flex;
  flex: 1;
  padding: 24px;
  border-radius: 4px;
  border: 0.5px solid #d7e1ed;
  background: white;
  gap: 24px;
  align-items: center;
  flex-direction: column;
}

.iphone {
  border-radius: 4px 0px 0px 4px;
  border-width: 1px 0 1px 1px;
}
.portrait {
  border-radius: 4px 0px 0px 4px;
  border-width: 1px 0 1px 1px;
}

.landscape {
  border-radius: 0px 4px 4px 0px;
  border-width: 1px 1px 1px 0;
}

.middle {
  border-radius: 0px 0px 0px 0px;
  border-width: 1px 1px 1px 1px;
}

.mentions-chip {
  background-color: #e3edff;
  padding: 1px;
  border-radius: 4px;
}

.help-message {
  color: #d93f18;
  width: 200px;
  float: left;
  display: flex;
  align-items: center;
  height: 100%; /* Ensure the parent element has a defined height */
}
