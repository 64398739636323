div.kandji-table {
  font-family: var(--font-family-primary);
  font-size: 13px;

  table {
    box-shadow:
      0 7px 14px 0 rgba(60, 66, 87, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    background: white;
    border-radius: 8px;
  }

  th {
    border: none;
    text-transform: uppercase;

    font-family: var(--font-family-primary);
    font-size: 11px;
    font-weight: 700;

    color: $grey-500;
    letter-spacing: 0.2em;
  }

  tbody tr:last-child {
    td:first-child {
      border-bottom-left-radius: 8px;
    }
    td:last-child {
      border-bottom-right-radius: 8px;
    }
  }

  .th-status-icon {
    width: 10px;
    padding: 0;
  }

  .td-status-icon {
    padding: 0;
    font-size: 10px;
    vertical-align: middle;

    i {
      vertical-align: middle;
      position: relative;
      left: 3px;
    }
  }
}

div.kandji-table-expandable {
  div.expanded-col {
    text-align: center;
  }

  tbody tr:hover {
    cursor: pointer;
    background-color: $grey-70;
  }

  td.expanded {
    cursor: auto;
    padding-left: 60px;
  }

  &.react-bootstrap-table {
    // header expansion col
    th[data-row-selection] {
      width: 60px;
    }

    // remove slow expansion transition
    div.row-expand-slide-appear-active {
      transition: max-height 400ms linear;
    }

    // keep expansion styles during collapse
    td.reset-expansion-style {
      cursor: auto;
      padding-left: 60px;
    }
  }
}
