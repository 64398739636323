.k-es-config-row {
  display: grid;
  grid-template-areas:
    'controls helper'
    'secondary secondary';
}

.k-es-config-row__helper.k-es-config-row__helper {
  grid-area: helper !important;
}

.k-es-config-row__controls.k-es-config-row__controls {
  grid-area: controls !important;
}

.k-es-config-row__secondary.k-es-config-row__secondary {
  grid-area: secondary !important;
}

@media (max-width: 1440px) {
  .k-es-config-row {
    display: grid;
    grid-template-areas:
      'controls'
      'helper'
      'secondary';
  }

  .k-es-config-row__helper.k-es-config-row__helper {
    /* margin-bottom: var(--b-gap2); */
  }

  .k-es-config-row__secondary.k-es-config-row__secondary {
    margin-top: 0;
  }
}

.b-select-multi__wrapper .b-pos-abs {
  position: unset;
  color: rgb(217, 63, 24);
}
