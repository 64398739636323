/* ========================== */
/* ==========!BASIC========== */
/* ========================== */

/* ----------!HEADING---------- */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  text-transform: inherit;
  line-height: inherit;
  color: inherit;
}

/* ----------!LIST---------- */
.list-reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* ============================= */
/* ==========!POSITION========== */
/* ============================= */

/* ----------!GENERAL---------- */
.absolute {
  position: absolute !important;
}

/* ----------!EXACT---------- */
.exact-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.exact-center-horizontal {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.exact-center-vertical {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

/* ----------!FIT---------- */
.fit {
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  overflow: hidden;
  object-fit: cover;
}
