.li-passport_auth-login-img {
  width: 112px;
  height: 72px;
}

@media (min-width: 1441px) {
  .b-library__setting-controls {
    grid-area: 2/1/3/2;
  }
}

.li-passport-color__inherit.li-passport-color__inherit {
  color: inherit;
}

.k-passport-config-row {
  display: grid;
  grid-template-areas:
    'title helper'
    'controls helper';
}

.k-passport-config-row__title {
  grid-area: title;
}

.k-passport-config-row__helper {
  grid-area: helper !important;
}

.k-passport-config-row__controls {
  grid-area: controls;
}

@media (max-width: 1440px) {
  .k-passport-config-row {
    display: grid;
    grid-template-areas:
      'title'
      'helper'
      'controls';
  }
}

.web-login-clientid {
  padding-bottom: 0;
}

.web-login-clientid::after {
  content: unset;
}

.web-login-clientid > div {
  grid-column: 1 / -1;
}
