.k-custom-os-version {
  .k-rule-field {
    &.device {
      width: 115px;

      .k-text-input-with-icon {
        position: relative;

        .k-device-icon {
          padding: 12px;
          width: 38px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .b-txt-input__elm {
          width: 100%;
          cursor: default;
          pointer-events: none;

          &:active,
          &:hover,
          &:focus {
            border-color: var(--b-primary-marengo-light) !important;
          }

          &::placeholder {
            color: var(--b-primary-marengo-ultradark);
          }

          &:disabled {
            &::placeholder {
              color: var(--b-primary-marengo-dark);
            }
          }
        }
      }
    }

    .b-txt-input__err-msg {
      width: 100%;
    }
  }
}

.k-custom-os-version-rule {
  position: relative;

  .k-rule-tippy {
    width: fit-content;
  }
}

.k-custom-os-version-rule + .k-custom-os-version-rule {
  margin-top: 10px;
}
