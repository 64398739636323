.b-menu__icon-spin {
  animation: menu-icon-spin 2s infinite linear;
}

@keyframes menu-icon-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.b-menu__item-disabled.b-menu__item-disabled {
  color: var(--color-neutral-50) !important;
  pointer-events: none;
}

.tippy-box.tippy-box {
  background-color: unset;
}

.b-menu__link.b-menu__link {
  width: 100%;
}
