.old-table {
  table,
  th,
  td {
    border: none !important;
  }

  table {
    font-family: var(--font-family-primary);
    .sort-column {
      .sort-icon__column-header {
        margin-left: 15px;
        &.fa-sort {
          margin: 6px 0 6px 15px !important;
          vertical-align: middle;
        }
        &.fa-sort-up {
          margin: 8px 5px 4px 15px !important;
          vertical-align: middle;
        }
        &.fa-sort-down {
          margin: 2px 5px 10px 15px !important;
          vertical-align: middle;
        }
      }
    }
    .td-icon,
    .react-bs-table-expand-cell {
      width: 42px;
      text-align: center !important;
    }
    .td-icon-left {
      width: 42px;
      text-align: right !important; // little joke
    }
    tr {
      td:first-child,
      th:first-child {
        padding-left: 15px !important;
        &.td-icon {
          width: 52px;
        }
        &.td-icon-left {
          width: 52px;
          text-align: left !important;
        }
        &.td-icon-right {
          text-align: right !important;
        }
      }
      border-top: 1px solid $grey-130 !important;
      &[width='100%'] {
        border-top: none !important;
        td {
          //padding: 0 0 0 52px !important;
          //padding: 0 0 0 140px !important;
          padding: 0 0 0 110px !important;
          .parameter-details {
            padding-right: 30px;
            margin-left: -28px;
            //border-top: 1px solid $light-grey-border;
            p,
            li {
              color: $grey-500;
              white-space: normal;
              font-size: 14px;
              font-weight: 400;
              margin: 14px 0;
            }
            ul {
              list-style: none;
            }
          }
          .param-history-details {
            margin-left: 5px;
          }
        }
      }
    }
    td,
    th {
      &:first-child {
        //padding-left: 30px !important;
      }
      &:last-child {
        padding-right: 15px !important;
      }
    }
    th {
      font-family: var(--font-family-primary);
      padding: 4px 8px !important;
      color: $grey-500;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      vertical-align: center;
      &:first-child {
        border: none;
      }
      &.first-after-line-column {
        padding-left: 30px !important;
      }
      .drag-icon {
        color: $grey-280 !important;
        svg {
          width: 16px;
          height: 16px;
        }
      }
      .header-column-content {
        display: flex;
        align-items: center;
        float: left;
      }
      .checkbox-custom {
        margin-left: -5px;
        .checkbox-container {
          .checkmark {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    td {
      font-family: var(--font-family-primary);
      font-size: 14px;
      padding: 5px 8px !important;
      font-weight: 400;
      vertical-align: middle !important;
      color: $grey-500;
      //.fa, .fas, .far, .fab {
      //  padding-top: .3125rem;
      //  font-size: 1.125rem;
      //}
      &.td-status-icon {
        text-align: right !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      &.first-after-line-column {
        padding-left: 30px !important;
      }
      .checkbox-custom {
        margin-left: -5px;
        .checkbox-container {
          .checkmark {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    thead {
      tr {
        height: 50px;
        background-color: white;
        &:first-child {
          border: none !important;
        }
      }
    }
    tbody {
      tr {
        height: 50px;
        &.table-row-clickable {
          cursor: pointer;
        }
        &:hover {
          background-color: var(--color-neutral-20) !important;
          //.loader-with-text-data {
          //  background-color: $light-grey!important;
          //}
        }
        &.highlighted-by-expand {
          background-color: var(--color-neutral-20) !important;
        }
        &:hover + .expand-hover {
          background-color: var(--color-neutral-20) !important;
        }
      }
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: 8px;
        }
        td:last-child {
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  .table-dropdown {
    overflow: hidden !important;
  }

  .table-nav {
    /* Width scrollbar */
    overflow: auto;
    overflow-y: hidden;
    width: 100%;
    &::-webkit-scrollbar {
      height: 5px;
      width: 5px;
      background: none;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 0.25rem;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb:horizontal {
      background: #c9cdd9;
      border-radius: 10px;
    }
  }

  .react-bs-table {
    background: none;
    border-radius: 8px !important;
  }

  .react-bs-shadow {
    box-shadow:
      0 7px 14px 0 rgba(60, 66, 87, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
  }

  .react-bs-container-header {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid $grey-230;
    margin-bottom: -1px;
  }

  .react-bs-container-body {
    overflow: visible;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-color: #fff;
    border-bottom: 1px solid $grey-230;
    border-left: 1px solid $grey-230;
    border-right: 1px solid $grey-230;
  }

  .react-bs-table-search-form {
    input {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }

  &.react-bs-table-container {
    background: none;
    overflow-x: auto;
    .react-bs-table-tool-bar {
      background-color: transparent;
      padding: 15px 15px 30px;
      margin: 0;
      //border: 1px solid $light-grey-border;
      border-bottom-width: 0;
      .row > div:first-child {
        display: flex;
        align-items: flex-start;
      }
    }
    .react-bs-table.react-bs-table-bordered {
      //background-color: #fff;
      border: none;
      border-bottom: 1px solid $light-grey-border;
      border-top-width: 0;
      border-radius: 0;
      &.mini {
        border-width: 0 0 1px 0;
      }
    }
  }

  &.bst-borderless {
    .react-bs-table-tool-bar,
    .react-bs-table {
      border: none !important;
    }
  }

  .parameter-table-filters {
    display: flex;
    flex-flow: wrap row;
    > div {
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .btn {
      border: none !important;
    }
    .dropdown-toggle-button {
      border: none !important;
    }
    .btn-sm {
      border: none !important;
    }
  }

  .DayPicker-wrapper {
    &:focus,
    &:active {
      outline: 0;
      outline-offset: 0;
    }
    .DayPicker-NavButton,
    .DayPicker-Day {
      &:focus,
      &:active {
        outline: 0;
        outline-offset: 0;
      }
    }
    .DayPicker-Day--selected:not(.DayPicker-Day--start):not(
        .DayPicker-Day--end
      ):not(.DayPicker-Day--outside) {
      color: #4a90e2;
    }
    .DayPicker-Day {
      border-radius: 0 !important;
    }
    .DayPicker-Day--start {
      border-top-left-radius: 50% !important;
      border-bottom-left-radius: 50% !important;
    }
    .DayPicker-Day--end {
      border-top-right-radius: 50% !important;
      border-bottom-right-radius: 50% !important;
    }
    .DayPicker-Day--disabled {
      color: grey;
      pointer-events: none;
    }
    .DayPicker-Day--today {
      color: $darkest-blue;
      font-weight: 400;
    }
  }

  .td-description {
    width: 50%;
  }

  .td-timeline-description {
    width: 30%;
  }

  .td-icon-history {
    color: $light-grey-text;
    width: 46px;
    text-align: center !important;
  }

  .tab-history {
    tr[width='100%'] {
      td {
        padding: 0 0 0 86px !important;
      }
    }
    .react-bs-table-tool-bar {
      border: none;
    }
  }

  .no-data-table {
    background: inherit;
    height: 20rem;
    box-sizing: border-box;
    .no-data-table-content {
      padding-top: 5.625rem;
      text-align: center;
      > div {
        margin-bottom: 2.5rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $grey-300;

        img {
          height: 3.125rem;
          margin-right: 1.25rem;
        }
        i {
          font-size: 3.125rem;
          margin-right: 0.9375rem;
        }
        p {
          font-size: 1.5rem;
          font-weight: 400;
          margin: 0;
        }
      }
    }
  }

  .no-data-helper-row:hover {
    background: none;
  }

  .expand-no-hover {
    background: #fff !important;
  }

  .param-description {
    color: $dark-grey-text;
  }

  .user-header-helper {
    margin-left: 0 !important;
  }

  .arrow::before {
    border-top-color: #6f6f70 !important;
  }

  &.company-user-table {
    .react-bs-table-tool-bar {
      padding: 12px;
      max-width: 99.5%;
      > .row {
        justify-content: flex-end;
      }
    }
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .underline-cell {
    box-shadow: 0 3px 0 0 #e5e5e5;
    width: min-content;
  }

  .draggable-header {
    cursor: grab !important;
  }

  .resizable-header {
    position: relative;
  }

  .resizer-wrapper {
    // cursor: col-resize; // TODO: revisit this - it's currently misleading.
    position: absolute;
    right: 0;
    bottom: 0;
    width: 10px;
    height: 100%;

    .resizer {
      width: 1px;
      position: absolute;
      height: 60%;
      bottom: 20%;
      right: 0;
      background-color: $grey-130;
    }

    &:hover,
    &:active {
      .resizer {
        width: 1px;
        height: 100%;
        bottom: 0;
        background-color: $grey-130;
      }
    }
  }

  .table-row-status-icon {
    font-size: 10px !important;
  }

  .table-link {
    cursor: pointer;
    color: $grey-500;
    border-bottom: 3px solid $grey-150;
    //padding-bottom: 3px;
    //margin-bottom: -3px;
    //padding: 0 0 4px;
  }

  .react-bs-table-sizePerPage-dropdown {
    width: 100%;
  }

  table {
    .no-data-helper-row,
    .react-bs-table-no-data-row {
      &:hover {
        background-color: inherit !important;
      }
    }
  }

  .column-border-right {
    border-right: 1px solid $grey-130 !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .column-border-right--status-tab {
    @extend .column-border-right;
    font-weight: 400;
  }

  .header-column-border-right {
    @extend .column-border-right;
    font-weight: 600;
  }

  .header-border-right {
    border-right: 1px solid $grey-130 !important;
    height: 50px !important;

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    font-weight: 600;
  }

  .table-border-right {
    border-right: 1px solid #e0e0e0 !important;
  }

  .column-with-padding-left {
    padding-left: 50px !important;
  }
}
