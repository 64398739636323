.enrollment-authentication {
  /* override the global body margin-bottom, background-color, font-family */
  margin-bottom: 0;

  height: 100vh;
  width: 100vw;

  background-color: #fff;
}

.enrollment-authentication #app {
  min-width: unset;
}

.enrollment-container {
  font-family:
    'SF Pro Text',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 60px;
}

.enrollment-content {
  width: 420px;
  margin-top: 60px;

  text-align: center;
}

.enrollment-msg {
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
}

.enrollment-info {
  margin-top: 16px;

  font-size: 13px;
  line-height: 16px;
}

.enrollment-btn {
  width: 175px;
  margin: 25px auto 0 auto;
}

.enrollment-footer {
  margin-top: 115px;

  font-size: 12px;
  line-height: 14px;

  opacity: 0.6;
}
