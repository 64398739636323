.icon-circle {
  display: flex;
  height: 4.5rem;
  width: 4.5rem;
  border-radius: 50%;
  border: 0.5rem solid var(--b-neutrals-grey-ultralight);
  position: relative;
  transition: 0.3s ease;

  &.is-lost {
    justify-content: center;
    align-items: center;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #fff;
      }
    }
  }

  &.editable {
    &:hover {
      &:after {
        content: 'Edit';
        color: #fff;
        font-size: 1.2rem;
        position: absolute;
        display: flex;
        place-items: center;
        place-content: center;
        text-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
        z-index: 2;
        border-radius: 50%;
      }
    }
  }

  &.size-s {
    height: 2.5rem;
    width: 2.5rem;
    border: 0.16rem solid $grey-200;
    i {
      font-size: 1rem;
    }
  }

  &.size-l {
    height: 10rem;
    width: 10rem;
    border: 0.7em solid $grey-200;
    i {
      font-size: 4rem;
    }
  }

  &.size-mm {
    height: 8rem;
    width: 8rem;
    border: 0.7em solid $grey-200;
    i {
      font-size: 3rem;
    }
  }

  i {
    //color: white;
    margin: auto;
    text-shadow: 0 0.05rem $marengo-600-transparent-300;
    font-size: 1.5rem;
  }

  &.bg-green {
    background: $green-500;
  }

  &.bg-two-green {
    background: linear-gradient(90deg, $green-500 50%, $green-400 50%);
  }

  &.bg-two-orange {
    background: linear-gradient(90deg, $orange-500 50%, $orange-400 50%);
  }

  &.bg-two-yellow {
    background: linear-gradient(90deg, $yellow-500 50%, $yellow-400 50%);
  }

  &.bg-two-marengo {
    background: linear-gradient(90deg, $marengo-550 50%, $marengo-450 50%);
  }
}
