.k-library-preview__links {
  margin-top: 48px;
  display: flex;
  justify-content: center;
}

.k-library-preview__links.k-library-preview__links--editing {
  justify-content: flex-start;
  margin-top: 0;
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 16px;
  margin-bottom: var(--b-gap3);
}

.k-library-preview__links.k-library-preview__links--editing
  [aria-roledescription='sortable'] {
  outline: none;
}

.k-ks-add-new-link-button {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding-left: 0 !important;
}

.k-ks-success-icons {
  margin-bottom: var(--b-gap3);
  width: 204px;
}
