// ****************************************************
// ******************** Sharp Sans ********************
// ****************************************************

@font-face {
  font-family: var(--font-family-primary);
  src: url('../../fonts/sharp-sans/SharpSans-Book.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: var(--font-family-primary);
  src: url('../../fonts/sharp-sans/SharpSans-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: var(--font-family-primary);
  src: url('../../fonts/sharp-sans/SharpSans-Light.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: var(--font-family-primary);
  src: url('../../fonts/sharp-sans/SharpSans-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

// ***********************************************************
// ******************** Atlas Grotesk Web ********************
// ***********************************************************

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-Black.otf')
    format('opentype');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-BlackItalic.otf')
    format('opentype');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-Bold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-BoldItalic.otf')
    format('opentype');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-Light.otf')
    format('opentype');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-LightItalic.otf')
    format('opentype');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-Medium.otf')
    format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-MediumItalic.otf')
    format('opentype');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-Regular.otf')
    format('opentype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-RegularItalic.otf')
    format('opentype');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-Thin.otf') format('opentype');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Atlas Grotesk Web';
  src: url('../../fonts/atlas-grotesk/AtlasGrotesk-ThinItalic.otf')
    format('opentype');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

// *************************************************
// ******************** Classes ********************
// *************************************************

.sharp-sans {
  font-family: var(--font-family-primary);
}

.atlas-grotesk {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@charset "UTF-8";

/*
* Symbolset
* www.symbolset.com
* Copyright © 2013 Oak Studios LLC
*
* Upload this file to your web server
* and place this within your <head> tags.
* <link href="webfonts/ss-junior.css" rel="stylesheet" />
*/

@font-face {
  font-family: 'SSJunior';
  src: url('../../fonts/ss-junior/ss-junior.eot');
  src:
    url('../../fonts/ss-junior/ss-junior.eot?#iefix')
      format('embedded-opentype'),
    url('../../fonts/ss-junior/ss-junior.woff') format('woff'),
    url('../../fonts/ss-junior/ss-junior.ttf') format('truetype'),
    url('../../fonts/ss-junior/ss-junior.svg#SSJunior') format('svg');
  font-weight: 400;
  font-style: normal;
}

/* This triggers a redraw in IE to Fix IE8's :before content rendering. */
html:hover [class^='ss-'] {
  -ms-zoom: 1;
}

.ss-icon,
.ss-icon.ss-junior,
[class^='ss-']:before,
[class*=' ss-']:before,
[class^='ss-'].ss-junior:before,
[class*=' ss-'].ss-junior:before,
[class^='ss-'].right:after,
[class*=' ss-'].right:after,
[class^='ss-'].ss-junior.right:after,
[class*=' ss-'].ss-junior.right:after {
  font-family: 'SSJunior';
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
  text-rendering: optimizeLegibility;
  white-space: nowrap;
  /*-webkit-font-feature-settings: "liga"; Currently broken in Chrome >= v22. Falls back to text-rendering. Safari is unaffected. */
  -moz-font-feature-settings: 'liga=1';
  -moz-font-feature-settings: 'liga';
  -ms-font-feature-settings: 'liga' 1;
  -o-font-feature-settings: 'liga';
  font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='ss-'].right:before,
[class*=' ss-'].right:before {
  display: none;
  content: '';
}

.ss-cursor:before,
.ss-cursor.right:after {
  content: '';
}
.ss-crosshair:before,
.ss-crosshair.right:after {
  content: '⌖';
}
.ss-search:before,
.ss-search.right:after {
  content: '🔎';
}
.ss-zoomin:before,
.ss-zoomin.right:after {
  content: '';
}
.ss-zoomout:before,
.ss-zoomout.right:after {
  content: '';
}
.ss-view:before,
.ss-view.right:after {
  content: '👀';
}
.ss-viewdisabled:before,
.ss-viewdisabled.right:after {
  content: '';
}
.ss-binoculars:before,
.ss-binoculars.right:after {
  content: '';
}
.ss-attach:before,
.ss-attach.right:after {
  content: '📎';
}
.ss-link:before,
.ss-link.right:after {
  content: '🔗';
}
.ss-move:before,
.ss-move.right:after {
  content: '';
}
.ss-write:before,
.ss-write.right:after {
  content: '✎';
}
.ss-writingdisabled:before,
.ss-writingdisabled.right:after {
  content: '';
}
.ss-compose:before,
.ss-compose.right:after {
  content: '📝';
}
.ss-lock:before,
.ss-lock.right:after {
  content: '🔒';
}
.ss-unlock:before,
.ss-unlock.right:after {
  content: '🔓';
}
.ss-key:before,
.ss-key.right:after {
  content: '🔑';
}
.ss-backspace:before,
.ss-backspace.right:after {
  content: '⌫';
}
.ss-ban:before,
.ss-ban.right:after {
  content: '🚫';
}
.ss-trash:before,
.ss-trash.right:after {
  content: '';
}
.ss-target:before,
.ss-target.right:after {
  content: '◎';
}
.ss-skull:before,
.ss-skull.right:after {
  content: '💀';
}
.ss-tag:before,
.ss-tag.right:after {
  content: '';
}
.ss-bookmark:before,
.ss-bookmark.right:after {
  content: '🔖';
}
.ss-flag:before,
.ss-flag.right:after {
  content: '⚑';
}
.ss-like:before,
.ss-like.right:after {
  content: '👍';
}
.ss-dislike:before,
.ss-dislike.right:after {
  content: '👎';
}
.ss-heart:before,
.ss-heart.right:after {
  content: '♥';
}
.ss-unheart:before,
.ss-unheart.right:after {
  content: '';
}
.ss-star:before,
.ss-star.right:after {
  content: '⋆';
}
.ss-unstar:before,
.ss-unstar.right:after {
  content: '';
}
.ss-sample:before,
.ss-sample.right:after {
  content: '';
}
.ss-crop:before,
.ss-crop.right:after {
  content: '';
}
.ss-cut:before,
.ss-cut.right:after {
  content: '✂';
}
.ss-clipboard:before,
.ss-clipboard.right:after {
  content: '📋';
}
.ss-ruler:before,
.ss-ruler.right:after {
  content: '';
}
.ss-gridlines:before,
.ss-gridlines.right:after {
  content: '';
}
.ss-pencilbrushpen:before,
.ss-pencilbrushpen.right:after {
  content: '';
}
.ss-paintroller:before,
.ss-paintroller.right:after {
  content: '';
}
.ss-paint:before,
.ss-paint.right:after {
  content: '';
}
.ss-paintdisabled:before,
.ss-paintdisabled.right:after {
  content: '';
}
.ss-paintedit:before,
.ss-paintedit.right:after {
  content: '';
}
.ss-pixels:before,
.ss-pixels.right:after {
  content: '';
}
.ss-phone:before,
.ss-phone.right:after {
  content: '📞';
}
.ss-phonedisabled:before,
.ss-phonedisabled.right:after {
  content: '';
}
.ss-addressbook:before,
.ss-addressbook.right:after {
  content: '📑';
}
.ss-voicemail:before,
.ss-voicemail.right:after {
  content: '⌕';
}
.ss-mailbox:before,
.ss-mailbox.right:after {
  content: '📫';
}
.ss-send:before,
.ss-send.right:after {
  content: '';
}
.ss-paperairplane:before,
.ss-paperairplane.right:after {
  content: '';
}
.ss-mail:before,
.ss-mail.right:after {
  content: '✉';
}
.ss-inbox:before,
.ss-inbox.right:after {
  content: '📥';
}
.ss-inboxes:before,
.ss-inboxes.right:after {
  content: '';
}
.ss-outbox:before,
.ss-outbox.right:after {
  content: '📤';
}
.ss-chat:before,
.ss-chat.right:after {
  content: '💬';
}
.ss-textchat:before,
.ss-textchat.right:after {
  content: '';
}
.ss-ellipsischat:before,
.ss-ellipsischat.right:after {
  content: '';
}
.ss-ellipsis:before,
.ss-ellipsis.right:after {
  content: '…';
}
.ss-smile:before,
.ss-smile.right:after {
  content: '☻';
}
.ss-frown:before,
.ss-frown.right:after {
  content: '☹';
}
.ss-surprise:before,
.ss-surprise.right:after {
  content: '😮';
}
.ss-user:before,
.ss-user.right:after {
  content: '👤';
}
.ss-femaleuser:before,
.ss-femaleuser.right:after {
  content: '👧';
}
.ss-users:before,
.ss-users.right:after {
  content: '👥';
}
.ss-robot:before,
.ss-robot.right:after {
  content: '';
}
.ss-ghost:before,
.ss-ghost.right:after {
  content: '👻';
}
.ss-contacts:before,
.ss-contacts.right:after {
  content: '📇';
}
.ss-pointup:before,
.ss-pointup.right:after {
  content: '👆';
}
.ss-pointright:before,
.ss-pointright.right:after {
  content: '👉';
}
.ss-pointdown:before,
.ss-pointdown.right:after {
  content: '👇';
}
.ss-pointleft:before,
.ss-pointleft.right:after {
  content: '👈';
}
.ss-cart:before,
.ss-cart.right:after {
  content: '';
}
.ss-shoppingbag:before,
.ss-shoppingbag.right:after {
  content: '';
}
.ss-store:before,
.ss-store.right:after {
  content: '🏪';
}
.ss-creditcard:before,
.ss-creditcard.right:after {
  content: '💳';
}
.ss-banknote:before,
.ss-banknote.right:after {
  content: '💵';
}
.ss-calculator:before,
.ss-calculator.right:after {
  content: '';
}
.ss-calculate:before,
.ss-calculate.right:after {
  content: '';
}
.ss-bank:before,
.ss-bank.right:after {
  content: '🏦';
}
.ss-presentation:before,
.ss-presentation.right:after {
  content: '';
}
.ss-barchart:before,
.ss-barchart.right:after {
  content: '📊';
}
.ss-piechart:before,
.ss-piechart.right:after {
  content: '';
}
.ss-activity:before,
.ss-activity.right:after {
  content: '';
}
.ss-box:before,
.ss-box.right:after {
  content: '📦';
}
.ss-home:before,
.ss-home.right:after {
  content: '⌂';
}
.ss-fence:before,
.ss-fence.right:after {
  content: '';
}
.ss-buildings:before,
.ss-buildings.right:after {
  content: '🏢';
}
.ss-lodging:before,
.ss-lodging.right:after {
  content: '🏨';
}
.ss-globe:before,
.ss-globe.right:after {
  content: '🌐';
}
.ss-navigate:before,
.ss-navigate.right:after {
  content: '';
}
.ss-compass:before,
.ss-compass.right:after {
  content: '';
}
.ss-signpost:before,
.ss-signpost.right:after {
  content: '';
}
.ss-map:before,
.ss-map.right:after {
  content: '';
}
.ss-location:before,
.ss-location.right:after {
  content: '';
}
.ss-pin:before,
.ss-pin.right:after {
  content: '📍';
}
.ss-pushpin:before,
.ss-pushpin.right:after {
  content: '📌';
}
.ss-code:before,
.ss-code.right:after {
  content: '';
}
.ss-puzzle:before,
.ss-puzzle.right:after {
  content: '';
}
.ss-floppydisk:before,
.ss-floppydisk.right:after {
  content: '💾';
}
.ss-window:before,
.ss-window.right:after {
  content: '';
}
.ss-music:before,
.ss-music.right:after {
  content: '♫';
}
.ss-mic:before,
.ss-mic.right:after {
  content: '🎤';
}
.ss-headphones:before,
.ss-headphones.right:after {
  content: '🎧';
}
.ss-mutevolume:before,
.ss-mutevolume.right:after {
  content: '';
}
.ss-volume:before,
.ss-volume.right:after {
  content: '🔈';
}
.ss-lowvolume:before,
.ss-lowvolume.right:after {
  content: '🔉';
}
.ss-highvolume:before,
.ss-highvolume.right:after {
  content: '🔊';
}
.ss-radio:before,
.ss-radio.right:after {
  content: '📻';
}
.ss-airplay:before,
.ss-airplay.right:after {
  content: '';
}
.ss-disc:before,
.ss-disc.right:after {
  content: '💿';
}
.ss-camera:before,
.ss-camera.right:after {
  content: '📷';
}
.ss-picture:before,
.ss-picture.right:after {
  content: '🌄';
}
.ss-pictures:before,
.ss-pictures.right:after {
  content: '';
}
.ss-video:before,
.ss-video.right:after {
  content: '📹';
}
.ss-film:before,
.ss-film.right:after {
  content: '';
}
.ss-clapboard:before,
.ss-clapboard.right:after {
  content: '🎬';
}
.ss-tv:before,
.ss-tv.right:after {
  content: '📺';
}
.ss-flatscreen:before,
.ss-flatscreen.right:after {
  content: '';
}
.ss-play:before,
.ss-play.right:after {
  content: '▶';
}
.ss-pause:before,
.ss-pause.right:after {
  content: '';
}
.ss-stop:before,
.ss-stop.right:after {
  content: '■';
}
.ss-record:before,
.ss-record.right:after {
  content: '●';
}
.ss-rewind:before,
.ss-rewind.right:after {
  content: '⏪';
}
.ss-fastforward:before,
.ss-fastforward.right:after {
  content: '⏩';
}
.ss-skipforward:before,
.ss-skipforward.right:after {
  content: '⏭';
}
.ss-skipback:before,
.ss-skipback.right:after {
  content: '⏮';
}
.ss-eject:before,
.ss-eject.right:after {
  content: '⏏';
}
.ss-filecabinet:before,
.ss-filecabinet.right:after {
  content: '';
}
.ss-books:before,
.ss-books.right:after {
  content: '📚';
}
.ss-notebook:before,
.ss-notebook.right:after {
  content: '📓';
}
.ss-newspaper:before,
.ss-newspaper.right:after {
  content: '📰';
}
.ss-grid:before,
.ss-grid.right:after {
  content: '';
}
.ss-rows:before,
.ss-rows.right:after {
  content: '';
}
.ss-columns:before,
.ss-columns.right:after {
  content: '';
}
.ss-thumbnails:before,
.ss-thumbnails.right:after {
  content: '';
}
.ss-menu:before,
.ss-menu.right:after {
  content: '';
}
.ss-filter:before,
.ss-filter.right:after {
  content: '';
}
.ss-desktop:before,
.ss-desktop.right:after {
  content: '💻';
}
.ss-laptop:before,
.ss-laptop.right:after {
  content: '';
}
.ss-tablet:before,
.ss-tablet.right:after {
  content: '';
}
.ss-cell:before,
.ss-cell.right:after {
  content: '📱';
}
.ss-battery:before,
.ss-battery.right:after {
  content: '🔋';
}
.ss-highbattery:before,
.ss-highbattery.right:after {
  content: '';
}
.ss-mediumbattery:before,
.ss-mediumbattery.right:after {
  content: '';
}
.ss-lowbattery:before,
.ss-lowbattery.right:after {
  content: '';
}
.ss-emptybattery:before,
.ss-emptybattery.right:after {
  content: '';
}
.ss-batterydisabled:before,
.ss-batterydisabled.right:after {
  content: '';
}
.ss-lightbulb:before,
.ss-lightbulb.right:after {
  content: '💡';
}
.ss-flashlight:before,
.ss-flashlight.right:after {
  content: '🔦';
}
.ss-flashlighton:before,
.ss-flashlighton.right:after {
  content: '';
}
.ss-picnictable:before,
.ss-picnictable.right:after {
  content: '';
}
.ss-birdhouse:before,
.ss-birdhouse.right:after {
  content: '';
}
.ss-lamp:before,
.ss-lamp.right:after {
  content: '';
}
.ss-onedie:before,
.ss-onedie.right:after {
  content: '⚀';
}
.ss-twodie:before,
.ss-twodie.right:after {
  content: '⚁';
}
.ss-threedie:before,
.ss-threedie.right:after {
  content: '⚂';
}
.ss-fourdie:before,
.ss-fourdie.right:after {
  content: '⚃';
}
.ss-fivedie:before,
.ss-fivedie.right:after {
  content: '⚄';
}
.ss-sixdie:before,
.ss-sixdie.right:after {
  content: '⚅';
}
.ss-downloadcloud:before,
.ss-downloadcloud.right:after {
  content: '';
}
.ss-download:before,
.ss-download.right:after {
  content: '';
}
.ss-uploadcloud:before,
.ss-uploadcloud.right:after {
  content: '';
}
.ss-upload:before,
.ss-upload.right:after {
  content: '';
}
.ss-transfer:before,
.ss-transfer.right:after {
  content: '⇆';
}
.ss-replay:before,
.ss-replay.right:after {
  content: '↺';
}
.ss-refresh:before,
.ss-refresh.right:after {
  content: '↻';
}
.ss-sync:before,
.ss-sync.right:after {
  content: '';
}
.ss-loading:before,
.ss-loading.right:after {
  content: '';
}
.ss-wifi:before,
.ss-wifi.right:after {
  content: '';
}
.ss-file:before,
.ss-file.right:after {
  content: '📄';
}
.ss-files:before,
.ss-files.right:after {
  content: '';
}
.ss-searchfile:before,
.ss-searchfile.right:after {
  content: '';
}
.ss-folder:before,
.ss-folder.right:after {
  content: '📁';
}
.ss-downloadfolder:before,
.ss-downloadfolder.right:after {
  content: '';
}
.ss-uploadfolder:before,
.ss-uploadfolder.right:after {
  content: '';
}
.ss-quote:before,
.ss-quote.right:after {
  content: '“';
}
.ss-anchor:before,
.ss-anchor.right:after {
  content: '';
}
.ss-print:before,
.ss-print.right:after {
  content: '⎙';
}
.ss-fax:before,
.ss-fax.right:after {
  content: '📠';
}
.ss-shredder:before,
.ss-shredder.right:after {
  content: '';
}
.ss-typewriter:before,
.ss-typewriter.right:after {
  content: '';
}
.ss-list:before,
.ss-list.right:after {
  content: '';
}
.ss-action:before,
.ss-action.right:after {
  content: '';
}
.ss-redirect:before,
.ss-redirect.right:after {
  content: '↪';
}
.ss-additem:before,
.ss-additem.right:after {
  content: '';
}
.ss-checkitem:before,
.ss-checkitem.right:after {
  content: '';
}
.ss-expand:before,
.ss-expand.right:after {
  content: '⤢';
}
.ss-contract:before,
.ss-contract.right:after {
  content: '';
}
.ss-scaleup:before,
.ss-scaleup.right:after {
  content: '';
}
.ss-scaledown:before,
.ss-scaledown.right:after {
  content: '';
}
.ss-lifepreserver:before,
.ss-lifepreserver.right:after {
  content: '';
}
.ss-help:before,
.ss-help.right:after {
  content: '❓';
}
.ss-info:before,
.ss-info.right:after {
  content: 'ℹ';
}
.ss-alert:before,
.ss-alert.right:after {
  content: '⚠';
}
.ss-caution:before,
.ss-caution.right:after {
  content: '⛔';
}
.ss-plus:before,
.ss-plus.right:after {
  content: '+';
}
.ss-hyphen:before,
.ss-hyphen.right:after {
  content: '-';
}
.ss-check:before,
.ss-check.right:after {
  content: '✓';
}
.ss-delete:before,
.ss-delete.right:after {
  content: '␡';
}
.ss-fish:before,
.ss-fish.right:after {
  content: '🐟';
}
.ss-bird:before,
.ss-bird.right:after {
  content: '🐦';
}
.ss-bone:before,
.ss-bone.right:after {
  content: '';
}
.ss-tooth:before,
.ss-tooth.right:after {
  content: '';
}
.ss-poo:before,
.ss-poo.right:after {
  content: '💩';
}
.ss-tree:before,
.ss-tree.right:after {
  content: '🌲';
}
.ss-settings:before,
.ss-settings.right:after {
  content: '⚙';
}
.ss-dashboard:before,
.ss-dashboard.right:after {
  content: '';
}
.ss-dial:before,
.ss-dial.right:after {
  content: '';
}
.ss-notifications:before,
.ss-notifications.right:after {
  content: '🔔';
}
.ss-notificationsdisabled:before,
.ss-notificationsdisabled.right:after {
  content: '🔕';
}
.ss-toggles:before,
.ss-toggles.right:after {
  content: '';
}
.ss-flash:before,
.ss-flash.right:after {
  content: '⌁';
}
.ss-flashoff:before,
.ss-flashoff.right:after {
  content: '';
}
.ss-magnet:before,
.ss-magnet.right:after {
  content: '';
}
.ss-toolbox:before,
.ss-toolbox.right:after {
  content: '';
}
.ss-wrench:before,
.ss-wrench.right:after {
  content: '🔧';
}
.ss-clock:before,
.ss-clock.right:after {
  content: '⏲';
}
.ss-stopwatch:before,
.ss-stopwatch.right:after {
  content: '⏱';
}
.ss-alarmclock:before,
.ss-alarmclock.right:after {
  content: '⏰';
}
.ss-counterclockwise:before,
.ss-counterclockwise.right:after {
  content: '⥀';
}
.ss-calendar:before,
.ss-calendar.right:after {
  content: '📅';
}
.ss-keyboard:before,
.ss-keyboard.right:after {
  content: '';
}
.ss-keyboardup:before,
.ss-keyboardup.right:after {
  content: '';
}
.ss-keyboarddown:before,
.ss-keyboarddown.right:after {
  content: '';
}
.ss-chickenleg:before,
.ss-chickenleg.right:after {
  content: '🍗';
}
.ss-burger:before,
.ss-burger.right:after {
  content: '🍔';
}
.ss-mug:before,
.ss-mug.right:after {
  content: '☕';
}
.ss-coffee:before,
.ss-coffee.right:after {
  content: '';
}
.ss-tea:before,
.ss-tea.right:after {
  content: '🍵';
}
.ss-wineglass:before,
.ss-wineglass.right:after {
  content: '🍷';
}
.ss-paperbag:before,
.ss-paperbag.right:after {
  content: '';
}
.ss-utensils:before,
.ss-utensils.right:after {
  content: '🍴';
}
.ss-droplet:before,
.ss-droplet.right:after {
  content: '💧';
}
.ss-sun:before,
.ss-sun.right:after {
  content: '☀';
}
.ss-cloud:before,
.ss-cloud.right:after {
  content: '☁';
}
.ss-partlycloudy:before,
.ss-partlycloudy.right:after {
  content: '⛅';
}
.ss-umbrella:before,
.ss-umbrella.right:after {
  content: '☂';
}
.ss-crescentmoon:before,
.ss-crescentmoon.right:after {
  content: '🌙';
}
.ss-plug:before,
.ss-plug.right:after {
  content: '🔌';
}
.ss-outlet:before,
.ss-outlet.right:after {
  content: '';
}
.ss-car:before,
.ss-car.right:after {
  content: '🚘';
}
.ss-taxi:before,
.ss-taxi.right:after {
  content: '🚖';
}
.ss-train:before,
.ss-train.right:after {
  content: '🚆';
}
.ss-bus:before,
.ss-bus.right:after {
  content: '🚍';
}
.ss-truck:before,
.ss-truck.right:after {
  content: '🚚';
}
.ss-plane:before,
.ss-plane.right:after {
  content: '✈';
}
.ss-bike:before,
.ss-bike.right:after {
  content: '🚲';
}
.ss-rocket:before,
.ss-rocket.right:after {
  content: '🚀';
}
.ss-briefcase:before,
.ss-briefcase.right:after {
  content: '💼';
}
.ss-theatre:before,
.ss-theatre.right:after {
  content: '🎭';
}
.ss-flask:before,
.ss-flask.right:after {
  content: '';
}
.ss-up:before,
.ss-up.right:after {
  content: '⬆';
}
.ss-upright:before,
.ss-upright.right:after {
  content: '⬈';
}
.ss-right:before,
.ss-right.right:after {
  content: '➡';
}
.ss-downright:before,
.ss-downright.right:after {
  content: '⬊';
}
.ss-down:before,
.ss-down.right:after {
  content: '⬇';
}
.ss-downleft:before,
.ss-downleft.right:after {
  content: '⬋';
}
.ss-left:before,
.ss-left.right:after {
  content: '⬅';
}
.ss-upleft:before,
.ss-upleft.right:after {
  content: '⬉';
}
.ss-navigateup:before,
.ss-navigateup.right:after {
  content: '';
}
.ss-navigateright:before,
.ss-navigateright.right:after {
  content: '▻';
}
.ss-navigatedown:before,
.ss-navigatedown.right:after {
  content: '';
}
.ss-navigateleft:before,
.ss-navigateleft.right:after {
  content: '◅';
}
.ss-share:before,
.ss-share.right:after {
  content: '';
}
