.k-file-select__container,
.k-file-view__container {
  position: relative;
  height: 148px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--space-1);
}

.k-file-select__container.--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.k-file-select,
.k-file-view {
  height: 100%;
  display: grid;
  padding: var(--b-gap2);
  border: 2px dashed var(--color-neutral-30);
}

.k-file-view {
  border: 2px solid var(--color-neutral-30);
}

.k-file-select.--is-hovered {
  border: 2px solid var(--color-blue-60);
}

.k-file-select.--has-error {
  border: 2px solid var(--color-red-50);
}

.k-file-select__error-info {
  display: grid;
  grid-template-columns: min-content auto;
  align-items: start;
  gap: var(--space-1);
  width: 100%;
}

.k-file-select__error-info__message {
  line-height: 1.7em;
}

.k-file-select__error-info,
.k-file-select__error-info > span {
}

.k-file-select__detail {
  flex: 1;
  /* display: grid;
  grid-template-rows: 48px auto;
  grid-gap: 8px; */
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.k-file-view__img {
  width: 45px;
  height: 45px;
}
