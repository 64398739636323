.k-vpp2-config {
  background-color: var(--color-neutral-10);
  border-radius: 4px;
  margin-top: var(--b-gap2);
  padding: var(--b-gap2);
}
@media (min-width: 1441px) {
  .k-autoapp-config__helper.b-library__setting-helpers.b-library__setting-helpers {
    grid-area: unset;
  }
  .k-autoapp-config__controls {
    margin-top: 0;
  }
}
