@import '../../scss/mq.def';

.b-library__settings > *:not(:last-child) {
  margin-bottom: var(--b-gap3);
}

.b-library__setting-card {
  background-color: var(--color-neutral-0);
  border-radius: 4px;
}

/* TODO: refactor existing kandji setup, remove -- */
.b-library__setting-card--title.b-library__setting-card--title,
.b-library__setting-card__header.b-library__setting-card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--b-gap) var(--b-gap2);
  box-shadow: inset 0px -1px 0px var(--color-neutral-20);
  display: flex;
  align-items: center;
  min-height: 56px;
  line-height: 15px;
}

/* TODO: refactor existing kandji setup, remove -- */
.b-library__setting-card--subtitle.b-library__setting-card--subtitle,
.b-library__setting-card__subheader.b-library__setting-card__subheader {
  padding: var(--b-gap2);
  box-shadow: inset 0px -1px 0px var(--color-neutral-20);
}

.b-library__setting-rows {
  padding: var(--b-gap2);
}

.b-library__setting-row:first-child {
  padding-top: 0;
}

.b-library__setting-row:last-child {
  padding-bottom: 0;
}

.b-library__setting-rows > *:not(:last-child) {
  box-shadow: inset 0px -1px 0px var(--color-neutral-20);
}

.b-library__setting-rows.--no-border > *:not(:last-child) {
  box-shadow: none;
}

.b-library-setting-card--single-col {
  display: flex;
  grid: unset;
  grid-gap: unset;
}

.b-library__setting-row {
  position: relative;
  padding: var(--b-gap2) 0;
  @media (min-width: #{$--md-above}) {
    display: grid;
    grid: auto / 1fr 1fr;
    grid-column-gap: var(--b-gap3);
  }
}

.b-library__setting-row > .b-library__setting-controls:first-child {
  @media (min-width: #{$--md-above}) {
    margin-bottom: 0;
    grid-area: 1/1/2/2;
  }
  @media (max-width: #{$--md-below}) {
    margin-bottom: var(--b-gap-micro);
  }
}

.b-library__setting-row > *:first-child {
  @media (min-width: #{$--md-above}) {
    margin-bottom: var(--b-gap1);
  }
}

.b-library__setting-title {
  @media (min-width: #{$--md-above}) {
    grid-area: 1/1/2/2;
  }
}

.b-library__setting-helpers.b-library__setting-helpers {
  @media (min-width: #{$--md-above}) {
    grid-area: 1/2/4/3;
    margin-bottom: 0;
  }
  @media (max-width: #{$--md-below}) {
    margin-bottom: var(--b-gap2);
  }
  & > *:not(:last-child) {
    margin-bottom: var(--b-gap1);
  }
}

$secondary-form-left-padding: 36px;

.b-library__setting-controls + .b-library__setting-helpers {
  @media (max-width: #{$--md-below}) {
    padding-left: $secondary-form-left-padding;
  }
}

.b-library__setting-controls + .b-library__setting-helpers.--no-helper-pad {
  padding-left: 0;
}

.b-library__setting-row > .b-library__setting-helpers:first-child {
  @media (max-width: #{$--md-below}) {
    margin-bottom: var(--b-gap2);
  }
}

@media (max-width: #{$--md-below}) {
  .b-library__setting-row > *:last-child {
    margin-bottom: 0;
  }
  .b-library__setting-row > *:first-child {
    margin-bottom: var(--b-gap-micro);
  }
}

.b-library__setting-helpers.--md-below-pad-left {
  @media (max-width: #{$--md-below}) {
    padding-left: $secondary-form-left-padding;
  }
}

.b-library__setting-row
  > .b-library__setting-title
  ~ .b-library__setting-controls {
  @media (min-width: #{$--md-above}) {
    grid-area: 2/1/3/2;
  }
}

.b-library__setting-controls {
  @media (min-width: #{$--md-above}) {
    grid-area: 1/1/3/2;
  }
}

.b-library__setting-secondary-controls {
  position: relative;
  padding-left: $secondary-form-left-padding;
  margin-top: var(--b-gap2);
  @media (min-width: #{$--md-above}) {
    grid-area: 3/1/4/3;
  }
}

.b-library__setting-secondary-controls::after {
  content: '';
  position: absolute;
  width: 2px;
  left: 10px;
  top: 0;
  height: 100%;
  background-color: var(--color-neutral-20);
}

.b-library__setting-secondary-controls-row {
  position: relative;
  background: rgba(26, 29, 37, 0.02); /* not in DS */
  padding: var(--b-gap2);
  @media (min-width: #{$--md-above}) {
    display: grid;
    grid: auto / 1fr 1fr;
    grid-column-gap: var(--b-gap3);
  }
}

.b-library__setting-secondary-controls-row:first-child {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.b-library__setting-secondary-controls-row:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.b-library__setting-secondary-controls-row::after {
  content: '';
  display: block;
  height: 1px;
  background-color: var(--color-neutral-20);
  position: absolute;
  bottom: 0;
  margin: 0 var(--b-gap2);
  width: calc(100% - 48px); /* gap2 x 2 */
  @media (max-width: #{$--md-below}) {
    margin: 0 auto;
  }
}

.b-library__setting-secondary-controls
  .b-library__setting-secondary-controls-row:last-child::after {
  display: none;
}

.b-library-second-ctrl-row-title.--first {
  margin-bottom: var(--b-gap1);
  @media (max-width: #{$--md-below}) {
    margin-bottom: var(--b-gap-micro);
  }
}
.b-library-second-ctrl-row-helper {
}
.b-library-second-ctrl-row-helper.--last {
  @media (max-width: #{$--md-below}) {
    padding-left: $secondary-form-left-padding;
  }
}
.b-library-second-ctrl-row-option.--first {
  @media (max-width: #{$--md-below}) {
    margin-bottom: var(--b-gap-micro);
  }
}
.b-library-second-ctrl-row-option.--last {
  @media (max-width: #{$--md-below}) {
    margin-top: var(--b-gap2);
  }
}

/* single row variant */
.b-library__setting-row-single-col {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: var(--b-gap2) 0;
}

.b-library__setting-row-single-col.b-library__setting-row-single-col
  > *:not(:last-child) {
  margin-bottom: var(--b-gap-tiny);
}

.b-library__setting-row-single-col.b-library__setting-row-single-col
  > *:first-child {
  margin-bottom: var(--b-gap1);
  @media (max-width: #{$--md-below}) {
    margin-bottom: var(--b-gap-micro);
  }
}

.b-library__setting-row-single-col.b-library__setting-row-single-col
  > *:nth-last-child(2) {
  margin-bottom: 0;
}

.b-library__setting-row-single-col.b-library__setting-row-single-col
  > *:last-child {
  margin-top: var(--b-gap1);
}

.b-library-form {
  /* do we have the color in DS ? */
  background: rgba(26, 29, 37, 0.02);
  padding: var(--b-gap2);
  border-radius: 4px;
}

.b-library__setting-card > *:last-child {
  padding-bottom: var(--b-gap3);
}
@media (min-width: #{$--md-above}) {
  .b-library-second-ctrl-row-title.--no-bottom-gap {
    margin-bottom: 0;
  }
}

.b-library__setting-card > *:last-child {
  padding-bottom: var(--b-gap3);
}
.b-library-second-ctrl-row-title.--no-bottom-gap {
  @media (min-width: #{$--md-above}) {
    margin-bottom: 0;
  }
}
