.nav {
  padding-top: 0;
  padding-bottom: 0;
  align-items: center;
  font-size: 18px;
  .nav-link {
    font-weight: 400;
    height: 100%;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    color: var(--color-neutral-0);
    cursor: pointer;
    text-transform: capitalize;
    white-space: nowrap;
    &:not([href]):not([tabindex]) {
      color: $text-black;
    }
    margin-right: 40px;
    border-bottom: 2px solid transparent;
    img,
    svg {
      height: 30px;
      margin-right: 10px;
      filter: greyscale(60%);
    }
    svg {
      font-size: 30px;
    }
    &.user-form {
      margin-top: 1px;
    }
    &.active,
    &:hover {
      border-bottom: 2px solid #1d212a;
      margin-top: 1px;
      z-index: 29;
      img,
      svg {
        filter: none;
      }
    }
  }
}

.main-navbar-sections {
  //border-bottom: 1px solid $grey-400;
  background-color: white;
  display: block;
  width: 100%;
  z-index: 100;
  .nav-link {
    height: 60px;
  }
}

.nav-secondary {
  min-height: 46px;
  border-bottom: 1px solid $grey-400;
  margin-top: 20px;
  .nav-link {
    font-size: 1rem;
    font-weight: 400;
    text-transform: capitalize;
    height: 46px;
    margin-right: 50px;
    padding-left: 15px;
    padding-right: 15px;
    color: var(--color-neutral-100);
  }
}

.sub-nav {
  font-size: 14pt;
}

.main-navbar-header {
  display: flex;
  width: 100%;
  height: 65px;
  z-index: 100;
  background-color: $beige;
  span {
    font-weight: 400;
    color: white;
    font-size: 16px;
    img {
      margin-right: 10px;
    }
  }
  .navbar {
    justify-content: space-between;
    .container-fluid {
      padding-left: 0;
    }
  }
  .navbar-brand {
    padding-left: 25px;
    img {
      height: 33px;
    }
  }

  .nav {
    width: 15em;
  }
  .title {
    margin: 1em 0 1em 1em;
    color: #333;
    text-decoration: none;
    font-size: 1.5em;
  }
  .nav-link {
    margin-left: 0.5em;
    width: 100%;
  }
  .active {
    color: white;
  }
}

.navbar-profile-dropdown {
  -moz-user-select: none; /* Mozilla Firefox */
  -ms-user-select: none; /* Internet Explorer (не поддерживается) */
  -o-user-select: none; /* Opera Presto (не поддерживается) */
  -webkit-user-select: none; /* Google Chrome, Opera Next, Safari */
  user-select: none;

  #navbar-menu-btn {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .dropdown-menu {
    left: auto !important;
    right: -20px !important;
    margin: 4px 20px 0;
    lsvg {
      &:hover {
        background-color: $light-grey;
      }
    }
    .dropdown-button {
      .dropdown-toggle-button {
        border-radius: 15px;
        &::after {
          position: absolute;
          right: 20px;
          color: $orange;
        }
        &:hover {
          box-shadow: none !important;
        }
      }
      .dropdown-menu {
        margin: 0 20px;
        lsvg {
          &:hover {
            background-color: $light-grey;
          }
          &.selected {
            span {
              &.fa,
              &.fas,
              &.far,
              &.fab {
                color: $darkest-blue;
                font-family: 'Font Awesome 5 Pro', sans-serif;
                font-weight: 900;
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
  .dropdown-name {
    font-weight: 500;
    margin-bottom: 0px;
    color: $darkest-blue;
    padding: 4px 10px 0;
    //padding: 4px 10px;
    //padding-right: 20px;
  }
  .dropdown-header {
    color: $grey-600;
    margin-top: 0;
    font-weight: 400;
    padding: 0 10px 4px;
    //padding: 4px 10px;
    //padding-right: 20px;
  }
  > div > .fa,
  .far,
  .fab,
  .fas {
    font-size: 24px;
    color: $darkest-blue;
  }
  .fa-caret-down {
    margin-left: 5px;
  }
  lsvg {
    cursor: pointer;
    font-weight: 400;
    padding-left: 14px;
    padding-right: 14px;
    color: $grey-600;
    .fa,
    .fas,
    .far,
    .fab {
      color: $darkest-blue;
      font-size: 1.5em;
      margin-right: 10px;
      text-align: center;
      vertical-align: top;
    }
  }
}

.tooltip-arrow {
  margin-left: 40px;
}

.sidebar {
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  position: relative;
  font-family: var(--font-family-primary);
  background-color: var(--color-neutral-100);
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;

  .header {
    height: 75px;
    margin-bottom: 24px;
    min-height: 75px;
    .pin-icon {
      color: var(--color-neutral-0);
      font-size: 20px;
      margin-left: auto;
      margin-right: 22px;
      margin-top: 27px;
      cursor: pointer;
      &:active {
        /*transform: scale(1.1);*/
      }
      i.icon.icon-pin {
        width: auto;
      }
    }
  }

  .divider {
    height: 1px;
    margin: 15px;
    border-bottom: 1px solid $marengo-600;
  }

  .item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 49px;
    padding-left: 22px;
    padding-right: 8px;
    min-height: 45px;
    border-radius: 4px;
    svg {
      text-align: left;
    }
  }

  .profile-dropdown {
    margin-left: 20px;
    //margin-top: auto;
  }

  .navbar-menu-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 350px;
    margin: 0.5rem 0.5rem 20px 0.5rem;
    font-weight: 700;
    font-size: 12px;
    color: $marengo-200;
    padding: 0.5rem;
    span {
      margin-left: 20px;
    }
    &:hover {
      span {
        color: #fff !important;
      }
      div {
        color: #fff !important;
      }
      color: #fff !important;
      //background-color: #e3eaf2;
    }
  }

  .dropdown-menu {
    //margin-bottom: 10px;
    position: absolute;
    z-index: 2001;
    width: 300px;
    //box-shadow: rgba(16, 36, 94, 0.5) 0 20px 40px -15px;
    left: 120px;
    border: 1px solid #ececec;

    .dropdown-name {
      margin-bottom: 0;
    }

    .dropdown-header {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    .dropdown-item {
      cursor: pointer;
      color: $marengo-200;
      //&:hover {
      //  background-color: #e3eaf2;
      //}
      svg {
        margin-right: 20px;
        font-size: 20px;
      }
    }
  }

  .sidebar-nav {
    overflow-y: auto;
    overflow-x: hidden;
    & > div {
      height: calc(100vh - 192px);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    /* Width scrollbar */
    &::-webkit-scrollbar {
      width: 0.25rem;
    }
    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 0.25rem;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $marengo-500;
      border-radius: 0.25rem;
    }
    .divider {
      height: 1px;
      margin: 15px;
      border-bottom: 1px solid $light-grey-border;
    }

    a.nav-link {
      text-transform: uppercase;
      padding: 0 0.5rem 0 0.3rem;
      font-weight: 700;
      font-size: 12px;
      color: var(--color-neutral-50);
      svg {
        color: var(--color-neutral-60);
        font-size: 20px;
        width: 28px;
        height: auto;
      }
      &.active {
        svg {
          color: $white;
        }
        .item {
          color: $white;
        }
      }
      &:hover {
        svg {
          color: $white;
        }
        .item {
          color: $white;
        }
      }
      &.disable {
        pointer-events: none;
        color: $light-grey;
      }
    }
  }
}

.sidebar .sidebar-nav a.nav-link i.icon-notifications {
  width: 24px;
  position: relative;
}

.fas.fa-circle.side-bar-alert-circle.side-bar-alert-circle {
  color: #ffcf01;
  font-size: 6px;
  left: 22px;
  top: 0px;
  position: absolute;
}

.burger {
  display: inline-block;
  cursor: pointer;

  align-self: center;
  left: 15px;
  //position: absolute;
  //z-index: 2005;

  height: 35px;
  margin: 20px 0 0 50px;

  &:hover {
    .bar1,
    .bar2,
    .bar3 {
      background-color: #fff;
    }
  }

  .bar1,
  .bar2,
  .bar3 {
    width: 25px;
    height: 5px;
    background-color: $marengo-200;
    margin: 5px 0;
    transition: 0.2s;
    border-radius: 5px;
  }
  .change {
    &.bar1 {
      -webkit-transform: rotate(-45deg) translate(-6px, 6px);
      transform: rotate(-45deg) translate(-6px, 6px);
    }
    &.bar2 {
      opacity: 0;
    }
    &.bar3 {
      -webkit-transform: rotate(45deg) translate(-8px, -8px);
      transform: rotate(45deg) translate(-8px, -8px);
    }
  }
}

.company-chooser {
  @extend .noselect;
  width: auto;
  height: 3rem;
  margin: 2rem;
  border-radius: 2rem;
  &.is-open {
    .Select-control {
      border: 2px solid $marengo-500;
      background-color: $marengo-700;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-bottom: none;
      .Select-arrow {
        border-color: transparent transparent white !important;
      }
    }
  }
  &.is-focused:not(.is-open) > .Select-control {
    box-shadow: none;
    border: 2px solid $marengo-500;
  }
  &.is-focused > .Select-control {
    background: inherit;
  }
  .Select-control {
    width: 100%;
    height: 3rem !important;
    //margin: 2rem auto;
    border-radius: 2rem;
    border: 2px solid $marengo-500;
    background-color: $marengo-700;
    .Select-value {
      line-height: 2.75rem !important;
      height: 3rem !important;
      padding-left: 1.25rem;
      padding-right: 1.25rem;
      .Select-value-label {
        color: white !important;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 600;
      }
    }
    .Select-input {
      &:focus {
        background: inherit;
      }
    }
    .Select-arrow-zone {
      .Select-arrow {
        border-color: white transparent transparent;
        margin-right: 1rem;
      }
    }
  }
  .Select-menu-outer {
    width: 100%;
    margin: auto;
    background-color: $marengo-700;
    left: 0;
    right: 0;
    border: 2px solid $marengo-500;
    border-top: none;
    border-radius: 0 0 2rem 2rem;
    padding: 1rem 1.25rem;
    .Select-menu {
      overflow-y: auto;
      background: transparent;
      max-height: 158px;
      /* Width scrollbar */
      &::-webkit-scrollbar {
        width: 0.25rem;
      }
      /* Track */
      &::-webkit-scrollbar-track {
        border-radius: 0.25rem;
      }
      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: $marengo-500;
        border-radius: 0.25rem;
      }

      .Select-option {
        @extend .cut-long-text;
        background-color: inherit;
        color: $grey-400 !important;
        text-transform: uppercase;
        font-size: 0.7rem;
        font-weight: 600;
        padding: 0.6rem 0;
        &.is-focused {
          background-color: inherit;
          color: white !important;
        }
      }
    }
  }
}

.sidebar .icon-pin.pinned path,
.sidebar .icon-pin.pinned:hover path {
  fill: rgba(255, 255, 255, 1);
}

.sidebar .icon-pin:hover path {
  fill: rgba(225, 230, 244, 1);
}

.sidebar-footer-items {
  min-height: 205px;
}

.sidebar .sidebar-nav a.nav-link:hover .item {
  color: var(--color-neutral-0);
}

.sidebar .sidebar-nav a.nav-link.active .item {
  color: var(--color-neutral-0);
}

.sidebar .sidebar-nav a.nav-link:hover .item svg {
  color: currentColor;
}

.sidebar .sidebar-nav a.nav-link.active .item svg {
  color: currentColor;
}
