/* =========================== */
/* ==========!TOGGLE========== */
/* =========================== */

/* ----------!MAIN---------- */
.toggle {
  display: flex;
  align-items: center;
  justify-content: center;
}
.toggle > input {
  background-color: $color-brand-marengo-dark;
  border-radius: 35px;
  width: 43px;
  height: 24px;
  position: relative;
  appearance: none;
  outline: none;
  transition: 0.1s ease 0.1s;
  cursor: pointer;
}
.toggle > input:before {
  background-color: $color-white;
  border-radius: 50px;
  margin: 3px;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  transition: 0.3s cubic-bezier(1, 0, 0.2, 1);
}

/* ----------!CHECKED---------- */
.toggle > input:checked {
  background-color: $color-system-link;
}
.toggle > input:checked:before {
  left: 19px;
}

/* ----------!DISABLED---------- */
.toggle > input:disabled {
  background-color: $color-brand-marengo-light;
  cursor: not-allowed;
}
.toggle > input:disabled:checked {
  background-color: $color-system-link-light;
}

/* =========================== */
/* ==========!STATUS========== */
/* =========================== */

/* ----------!MAIN---------- */
.status > span {
  font-weight: $font-weight-3;
  text-transform: capitalize;
}

/* ----------!ON---------- */
.status-on > span {
  background-color: var(--color-green-20);
  color: var(--color-semantic-success-50);
}
.status-on + .toggle > input:checked {
  background-color: var(--color-green-50);
}

/* ----------!DISABLED---------- */
.status-on + .toggle > input:disabled:checked {
  background-color: var(--color-green-20);
}
