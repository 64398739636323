.b-library__setting-rows.k-library__setting-rows--collapsed {
  display: none;
}

.k-library-preview {
  width: 100%;
  position: relative;
  margin-top: var(--b-gap2);
}

.k-library-preview--collapsed {
  display: none;
}

.k-library-preview__bg {
  position: relative;
  text-align: center;
}

.k-library-preview__bg > img {
  height: 648px;
  border-radius: 5px;
  width: calc(100% + 2px);
}

.k-library-preview__bg > img,
.k-library-preview__bg {
  user-select: none;
}

.k-library-preview__item {
  color: inherit;
  position: absolute;
  user-select: none;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% + 0px), calc(-50% + 0px));
}

.k-library-preview__item.k-library-preview__item--success {
  transform: translate(calc(-50% + 0px), calc(-50% + 0px));
}

.k-library-preview__frame {
  width: 800px;
  user-select: none;
  border-radius: 12px;
  box-shadow:
    0px 20px 50px rgba(0, 0, 0, 0.15),
    5px 10px 60px rgba(0, 0, 0, 0.15),
    -5px 10px 60px rgba(0, 0, 0, 0.15),
    0px 30px 100px rgba(0, 0, 0, 0.3);
}

.k-library-preview__frame.--light-shadow {
  box-shadow:
    0px 20px 50px rgba(0, 0, 0, 0.1),
    5px 10px 60px rgba(0, 0, 0, 0.1),
    -5px 10px 60px rgba(0, 0, 0, 0.1),
    0px 30px 100px rgba(0, 0, 0, 0.2);
}

.k-library-preview__frame--help {
}

.k-library-preview__frame.k-library-preview__frame--success {
}

.k-library-preview__logo {
  width: var(--brandon-install-logo);
  height: var(--brandon-install-logo);
  object-fit: contain;
  position: absolute;
  left: 0;
  transform: translate(var(--ks-apple-margin), var(--ks-apple-margin));
  object-position: top;
}

.k-library-preview__logo.--round-corner-preview {
  border-radius: 9px;
}

.k-library-preview__logo.preview-logo--center {
  width: var(--brandon-help-logo);
  height: var(--brandon-help-logo);
  object-fit: contain;
  position: static;
  transform: none;
  margin-bottom: var(--b-gap2);
  object-position: bottom;
}

.k-library-preview__kandji-logo {
  position: absolute;
  width: 63px;
  right: 0;
  top: 0;
  transform: translate(
    calc(-1 * var(--ks-apple-margin)),
    var(--ks-apple-margin)
  );
}

.k-library-preview__content {
  font-family: 'SF Pro Text';
  position: absolute;
  top: 0;
  transform: translate(calc(-50% + 2px), 75px);
  left: 50%;
  width: 100%;
  text-align: center;
  padding: 0px 196px;
}

.k-library-preview__content--dark {
  color: var(--color-neutral-0);
}

.k-library-preview__title {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  margin-bottom: var(--b-gap-tiny);
}

.k-library-preview__title.--help {
  margin-bottom: var(--b-gap2);
}

.k-library-preview__title.--success {
  margin-bottom: var(--b-gap-tiny);
}

p.k-library-preview__copy {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  white-space: pre-wrap;
  position: relative;
  letter-spacing: -0.3px;
  margin: 0;
}

.k-library-preview__content.preview-content--install {
  transform: translate(calc(-50% + 0px), 54px);
  padding: 0px 164px;
}

.k-library-preview__content.preview-content--help {
  transform: translate(calc(-50% - 0px), 158px);
  padding: 0 165px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.k-library-preview__content.preview-content--success {
  transform: translate(calc(-50% + 0px), 64px);
  padding: 0;
}

.preview-content--success p.k-library-preview__copy {
  padding: 0 165px;
  max-width: 470px;
}
