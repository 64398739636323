.li-library-filter {
  display: grid;
  grid-template-columns: minmax(150px, 300px) minmax(150px, 1fr) auto;
  grid-gap: var(--b-gap3);
  padding: var(--b-gap1) var(--b-gap2);
  background-color: var(--color-neutral-0);
  border-radius: 4px;
}

.li-library-filter.--sticky {
  position: sticky;
  top: 72px;
  z-index: 5;
}

.li-library-filter__option {
  padding: 2px 0;
}

.li-library-filter__option-count.li-library-filter__option-count,
.li-library-filter__control {
  font-weight: 700;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-neutral-20);
  color: var(--color-neutral-60);
  border-radius: 35px;
  min-width: 42px;
  padding: 4px 8px;
}

.li-library-filter__option.--selected > .li-library-filter__option-count {
  color: var(--color-neutral-60);
}

.li-library-filter__option.--selected > .li-library-filter__label {
  color: var(--color-neutral-0);
}

.li-library-filter__search {
  flex: 0.3;
}

.li-library-filter__control {
  height: 20px;
  position: absolute;
  right: 38px;
  background-color: var(--color-neutral-100);
  color: var(--color-neutral-0);
}
