@media (min-width: 1712px) {
  .li-library-items {
    max-width: 1760px;
    margin: auto;
  }
}

@media (max-width: 1200px) {
  .li-library-items {
    margin: 0 -32px;
  }
}

.li-library-items {
  transition: all 0.2s ease-in-out;
  padding-top: var(--b-gap3);
  min-width: 800px;
}

.li-mb-40 {
  margin-bottom: 40px;
}
