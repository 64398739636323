.k-settings-card {
  margin-bottom: var(--b-gap3);
}

.k-user-profile .b-form-grid {
  box-shadow: none !important;
  grid-row-gap: 12px !important;
}

.profile-picture-grid {
  margin-top: 12px;
  box-shadow: none !important;
  grid-row-gap: 6px !important;
}

.b-txt-input--error-state {
  margin-bottom: var(--b-gap2);
}

.k-user-profile .k-library-org-logo {
  width: 50% !important;
}

.k-user-profile .k-library-org-logo__preview > img {
  width: 108px;
  height: 108px;
  border-radius: 12px;
}

.k-user-profile .password-field {
  width: 520px;
}

.k-user-profile .b-txt-input__icon {
  color: var(--color-blue-50);
}

.k-user-profile .b-select-multi__value-container > .b-txt {
  margin-left: calc(var(--b-gap) / 2) !important;
}

.k-user-profile .b-select-multi__value-container--disabled {
  border-color: var(--color-neutral-20) !important;
}

.k-user-profile .b-select-search__value--disabled {
  color: var(--color-neutral-60) !important;
}

.k-user-profile .b-select--is-disabled > .b-select__control--is-disabled {
  border-color: var(--color-neutral-20) !important;
}

.k-user-profile-timezone-select .b-select__icon {
  width: var(--sizes-4);
  height: var(--sizes-4);
}
