.tippy-box[data-theme~='tippy'] {
  background-color: #fff !important;
  filter: drop-shadow(0px 0px 10px rgba(26, 29, 37, 0.15));
  border-radius: 4px;
}

.tippy-box[data-theme~='tippy'] .tippy-content {
  padding: var(--b-gap);
}

.tippy-box[data-theme~='tippy'][data-placement^='top'] .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='tippy'][data-placement^='bottom'] .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='tippy'][data-placement^='left'] .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='tippy'][data-placement^='right'] .tippy-arrow {
  color: #fff;
}

.tippy-svg,
.tippy-svg > span {
  display: flex;
  align-items: center;
}

/* ! This will be added to Bumblebee, but is in use for dev purposes for now */
.b-chip--kind-preview {
  background-color: #f1ebff;

  border: 1px solid #f1ebff;
  font-weight: 500;
}

.b-chip--kind-preview span.b-chip__text {
  color: #271e55;
}
