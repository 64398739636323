.b-library-summary {
  border-radius: 4px;
  background-color: var(--color-neutral-0);
}

.b-library-summary__header {
  display: grid;
  grid: auto/64px 1fr 141px;
  align-items: center;
  padding: 12px 24px;
  box-shadow: inset 0px -1px 0px #dee1ea; /* couldnt find the color name */
}

.b-library-summary__header > img {
  width: 48px;
}

.b-library-summary__input {
  .static-header {
    align-items: center;
  }
}

.b-library-summary__status {
  display: grid;
  grid: auto/auto auto;
  justify-content: flex-end;
  grid-column-gap: 8px;
}

.b-library-summary__body {
  padding: var(--b-gap2) var(--b-gap2) var(--b-gap3);
}

.b-library-summary__info {
  display: grid;
  grid: auto/1fr 1fr;
  grid-column-gap: 32px;
}

.b-library-summary__publisher {
}

.b-library-summary__publisher > div {
  display: grid;
  grid: auto/120px 1fr;
  grid-column-gap: 24px;
  align-items: center;
}

.b-library-summary__publisher > div:not(:last-child) {
  margin-bottom: var(--b-gap-tiny);
}

div.b-library-summary__reqs {
  align-items: baseline;
}

.b-library-summary__assign {
  background-color: var(--color-neutral-20);
  border-radius: 4px;
  margin-top: var(--b-gap2);
}

.b-library-summary__assign > h3, /* in case some code is using this */
.b-library-summary__assign-title {
  padding: var(--b-gap) var(--b-gap2);
  box-shadow: inset 0px -1px 0px var(--color-neutral-20);
  min-height: 56px;
  display: flex;
  align-items: center;
  line-height: 15px;
}

.b-library-summary__assign-body {
  padding: var(--b-gap2) var(--b-gap2) var(--b-gap3);
  display: grid;
  grid-row-gap: var(--b-gap2);
  border-top: 1px solid var(--color-neutral-40);
}

.b-library-summary__assign-row {
  display: flex;
  align-items: center;
  --b-assign-label-width: 105px;
  --b-assign-label-margin: var(--b-gap);
}

.b-library-summary__assign-row > *:first-child {
  min-width: var(--b-assign-label-width);
  margin-right: var(--b-assign-label-margin);
}

.b-library-summary__assign-row > *:nth-child(2) {
  min-width: calc(
    50% - var(--b-assign-label-width) - var(--b-assign-label-margin)
  );
}

.k-exclude-blueprints {
  .b-txt {
    margin-bottom: 8px;
    padding-left: 2px;
  }

  .b-chip__text,
  .b-chip__icon {
    color: var(--color-red-50);
  }
}
