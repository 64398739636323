/* =========================== */
/* ==========!REMOTE========== */
/* =========================== */

/* ----------!MENU---------- */
.remote > .menu {
  border-bottom: 1px solid $color-brand-slate;
  margin-top: -15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.remote > .menu > .menu-item {
  margin-bottom: -1px;
}
.remote > .menu > .menu-item + .menu-item {
  margin-left: $space-remote;
}
.remote > .menu > .menu-item > .menu-link {
  padding: 0 $space-paper;
  height: $height-button;
  position: relative;
  font-family: $font-family-title;
  font-weight: $font-weight-3;
  font-size: $font-size-button;
  line-height: $line-height-button;
  text-transform: capitalize;
  text-decoration: none;
  color: $color-brand-marengo-ultradark;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remote > .menu > .menu-item > .menu-link > .icon > svg {
  fill: $color-brand-slate;
}
.remote > .menu > .menu-item > .menu-link.active {
  font-weight: var(--font-weight-semi-bold);
}
.remote > .menu > .menu-item > .menu-link.active > .icon > svg {
  fill: $color-brand-marengo-ultradark;
}
.remote > .menu > .menu-item > .menu-link.active::after {
  background-color: $color-brand-marengo-ultradark;
  width: 100%;
  height: $border-width-button-default;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
}

/* ----------!LIBRARY-ITEM---------- */
.remote-add > .menu > .menu-item:not([class*='menu-item-root']) {
  opacity: 0.3;
  cursor: not-allowed;
}
.remote-add > .menu > .menu-item:not([class*='menu-item-root']) > .menu-link {
  pointer-events: none;
}
