body {
  //background-color: red;
}

.h1 {
  font-size: 30px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
}
h4,
.h4 {
  font-weight: 700;
  font-size: 1.5625rem;
}
h5,
.h5 {
  font-size: 1.125rem;
}
.f-light {
  font-weight: 400;
}

.c-grey {
  color: $text-dark-grey;
}
.c-blue {
  color: $darkest-blue;
}

.c-orange {
  color: $orange;
}

.c-orange-selected {
  color: $orange-selected;
}

.c-dark-red {
  color: $dark-red;
}

.c-light-pink {
  color: $light-pink;
}

.text-grey {
  color: $light-grey-text;
}

.text-light-blue {
  color: $light-blue;
}

.text-light-grey {
  color: $text-light-grey;
}

.c-dark-grey-new {
  color: $dark-grey-new;
}

.b-color-grey {
  background-color: $light-grey-text;
}

.b-color-light-grey {
  background-color: $body-bgc;
}

label {
  font-size: 14px;
  font-weight: 400;
  font-family: var(--font-family-primary);
}
blockquote {
  border-left: 3px solid #acacac;
  padding-left: 10px;
}

strong {
  font-weight: bold;
}

.company-title {
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}

.break-long-words {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.bg-orange-500 {
  background-color: $orange-500;
}

.bg-green-500 {
  background-color: $green-500;
}

.bg-grey-500 {
  background-color: $grey-500;
}
.bg-grey-300 {
  background-color: $grey-300;
}
.bg-grey-200 {
  background-color: $grey-200;
}

.bg-blue-500 {
  background-color: $blue-500;
}

.bg-marengo-640 {
  background-color: $marengo-640;
}

.c-orange-500 {
  color: var(--color-red-50);
}

.c-green-500 {
  color: $green-500;
}

.c-yellow-500 {
  color: var(--color-yellow-50);
}

.c-grey-999 {
  color: $grey-999;
}
.c-grey-500 {
  color: $grey-500;
}
.c-grey-400 {
  color: $grey-400;
}
.c-grey-300 {
  color: $grey-300;
}
.c-grey-200 {
  color: $grey-200;
}

.c-blue-500 {
  color: $blue-500;
}
.c-blue-400 {
  color: $blue-400;
}

.c-grey-500 {
  color: $grey-500;
}
.c-grey-300 {
  color: $grey-300;
}

.c-marengo-700 {
  color: $marengo-700;
}
.c-marengo-699 {
  color: $marengo-699;
}
.c-marengo-640 {
  color: $marengo-640;
}
.c-marengo-550 {
  color: $marengo-550;
}
.c-marengo-500 {
  color: $marengo-500;
}
.c-marengo-400 {
  color: $marengo-400;
}
.c-marengo-300 {
  color: $marengo-300;
}
.c-marengo-200 {
  color: $marengo-200;
}
.left-space {
  margin-left: 0.25em;
}
.right-space {
  margin-right: 0.25em;
}
