@import "src/theme/styles/control";

@keyframes delay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.section-header {
  margin-top: 15px;
  margin-bottom: 20px;
  align-items: center;
  font-family: var(--font-family-primary);
  font-size: 2rem;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 600;
}

.table-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 25px;
  h1 {
    font-size: 1.8rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    margin-right: auto;
    margin-bottom: 0;
  }
  .actions {
    display: flex;
    flex-direction: row;
    margin-left: 2rem;
  }
  @media (max-width: 767px) {
    .actions {
      margin-top: 1rem;
      margin-left: 0;
    }
  }
  .children {
    &:first-child {
      margin-right: auto;
    }
  }
}

.custom-table-toolbar {
  width: 100%;
}

.white-container {
  background-color: #fff;
  //border: 1px solid $light-grey-border;
  border-radius: 5px;
  margin-bottom: 30px;
  padding: 1.625rem 2.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 270px;
  position: relative;
  &.loading {
    &:after {
      position: absolute;
      top: 0;

      left: 0;
      height: 100%;
      width: 100%;
      content: "";
      //background-color: red;
      background: rgba(0, 194, 255, 0.04) url(../../img/spinner.svg) no-repeat
        center;
      //background-color: #007bff1c;
    }
  }
}

.transparrent-container-paddness {
  @extend .white-container;
  background-color: transparent;
}

.borderless {
  border: none;
}

.white-container-padless {
  @extend .white-container;
  padding: 0;
}

.summary .divider {
  @media (min-width: $breakpoint-tablet) {
    border-right: 1px solid $light-grey-border;
  }
}

.computer-last-run {
  //height: 278px;
}

.computer-remediations {
  display: flex;
  flex-flow: column;
  justify-content: center;
  height: 278px;
  background-image: url("../../img/remediations_bg.png");
  background-position: 50%;
  background-repeat: no-repeat;
  span {
    text-align: center;
    font-weight: 400;
    &:nth-child(-n + 2) {
      color: $darkest-blue;
    }
  }
}

/// tabs
#accordion {
  > div:not(:first-child) {
    border-top: 1px solid $light-grey-border;
  }
}
.tab-header {
  cursor: pointer;
  padding: 14px;

  a {
    font-weight: 600;
    font-size: 18px;
    color: #000;
    text-decoration: none !important;
  }
  .timestamp {
    font-size: 14px;
  }
}

.tab-content {
  padding-left: 5rem;
  padding-right: 1rem;
}

.tab-content-inner {
  border-top: 1px solid $light-grey-border;
  //padding-top: 32px;
  //padding-bottom: 14px;
  p {
    font-size: 14px;
    font-weight: 400;
    margin: 14px 0;
  }
}

.w-s {
  width: 2rem;
  text-align: center;
  line-height: 25px;
}

.w-s-l {
  width: 2rem;
  text-align: left;
  line-height: 25px;
}

.w-4 {
  width: 4rem;
  text-align: center;
  line-height: 25px;
}

.p-right {
  margin-left: auto;
}

.computer-tabs {
  .nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0;
  }
}

[data-loading="true"] {
  position: relative;
  height: 100%;
  width: 100%;
  flex-grow: 10;
  &:after {
    z-index: 2052;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    //background: url(../img/loading_sm.svg) no-repeat center, radial-gradient(rgba(0,0,0,0.1), transparent);
    background: url(../../img/puff.svg) no-repeat center,
      radial-gradient(rgba(255, 255, 255, 0.8), transparent, transparent);
  }
}

.alert-banner {
  padding: 1rem 2.75rem;
  border-color: #fd6c30;
  background-color: #ffebd9;
}

.company-summary-card-container {
  margin-bottom: 55px;
  .section-header {
    margin-bottom: 20px;
    .col {
      padding: 0;
    }
  }
  .company-summary-left {
    font-weight: 400;
  }
  .company-summary-right {
    justify-content: flex-end;
  }
}

.loader-with-text {
  position: relative;
  z-index: 1;
  height: 64px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  width: 100%;
  flex-direction: row;
  align-items: center;

  &:before {
    border-top: 1px solid $light-grey-border;
    content: "";
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    width: 95%;
    z-index: 1009;
  }

  .loader-with-text-data {
    z-index: 1010;
    background: #fff;
    padding: 0 25px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    span {
      margin-right: 10px;
      margin-left: 10px;
    }

    i {
      padding-top: 0 !important;
      font-size: 2em;
    }
  }
}

// * These styles were put in src/app/components/interface/LineLoader.jsx
.loader-with-text2 {
  position: relative;
  height: 4rem;
  display: flex;
  flex-flow: nowrap row;
  align-content: center;
  justify-content: center;
  width: 100%;
  align-items: center;

  .loader-with-text2-line {
    height: 1px;
    background-color: $grey-200;
    width: 50%;
  }

  .loader-with-text-data {
    cursor: default;
    color: $grey-500;
    letter-spacing: 0.1rem;
    font-family: var(--font-family-primary);
    padding: 0 1.625rem;
    display: flex;
    align-items: center;

    span {
      margin: 0 1rem;
      font-weight: 600;
    }

    i {
      padding-top: 0 !important;
      font-size: 2em;
    }
  }
}

@media (min-width: 1200px) {
  .container:not(.sign-in) {
    max-width: 1400px;
  }
}

.not-full-width-sidebar-opened {
  padding-left: 302px;
}

body {
  --k-main-layout-side-padding: 48px;
}

.full-width-sidebar-closed {
  padding-left: calc(78px + var(--k-main-layout-side-padding));
  padding-right: var(--k-main-layout-side-padding);
}

/*main {
  > * {
    max-width: 1366px;
    margin-right: auto;
    margin-left: auto;
  }

  > .library-items-grid,
  > .add-single-library-item,
  > .shroud,
  > .blueprint-switcher,
  > .blueprint-page-tab-container,
  > .devices-page {
    max-width: unset;
  }
}*/

.full-width-sidebar-opened {
  padding-left: calc(257px + var(--k-main-layout-side-padding));
  padding-right: var(--k-main-layout-side-padding);
}

.not-full-width-sidebar-closed {
  padding-left: 102px;
}

@media (max-width: 1200px) {
  .not-full-width-sidebar-closed {
    margin-left: 0;
    margin-right: 50px;
    max-width: 100%;
  }
}

@media (max-width: 1200px) {
  .not-full-width-sidebar-opened {
    margin-left: 0;
    margin-right: 50px;
    max-width: 100%;
  }
}

@media (max-width: 990px) {
  .not-full-width-sidebar-closed {
  }
}

[draggable="true"] {
  -khtml-user-drag: element !important;
}

.kandji-position-sticky {
  position: sticky;
  position: -webkit-sticky;
}

.pointer-events-none {
  pointer-events: none;
}

.page-header-container {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
  &.bordered {
    border-bottom: 1px solid $grey-200;
  }
  .page-header {
    font-family: var(--font-family-primary);
    padding: 2rem 0;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
  }
}

body {
  background-color: var(--color-neutral-10);
  margin-bottom: 48px;
  overscroll-behavior: none;
}

body.white-bg {
  background-color: var(--colors-neutral-0);
}

body.no-margin {
  margin: 0;
}

body.no-scroll {
  overflow: hidden;
}

.ss-icon {
  height: 1.22em;
}

.link {
  color: $grey-999;
  border-bottom: 3px solid #dee0e4;
  padding-bottom: 3px;
  margin-bottom: -3px;
  line-height: 1.8em;
  cursor: pointer;
}

//* {
//  -webkit-backface-visibility: hidden;
//}

// override bumblebee styles
.decorate-off,
.decorate-off:hover {
  text-decoration: none;
}
