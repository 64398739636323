.abl-rows__no-pad.abl-rows__no-pad {
  padding: 0;
}

.abl-row__no-grid {
  grid: unset;
}

.abl-row__no-grid.b-library__setting-row {
  padding-top: 0;
  padding-bottom: 0;
}

.abl-table.abl-table {
  margin-bottom: 0;
}

.abl-table__filters.abl-table__filters {
  display: flex;
  align-items: center;
  padding: 16px 24px;
  grid-column-gap: var(--b-gap2);
  justify-content: space-between;
}

.abl-table__filters.abl-table__filters > *,
.abl-table__list.abl-table__list > * {
  margin-bottom: 0;
}

/* prevents the allow block list table col headers going over the actions */
.abl-container ~ .b-library__actions {
  z-index: 10;
}
