#fc_frame,
#hubspot-messages-iframe-container,
#mobile-chat-container {
  transition: all 0.3s ease-in-out;
}
.hubspot-handler div#fc_frame,
.hubspot-handler div#hubspot-messages-iframe-container,
.hubspot-handler div#mobile-chat-container {
  margin-bottom: 80px;
}
.hubspot-handler--overlaid div#fc_frame,
.hubspot-handler--overlaid div#hubspot-messages-iframe-container,
.hubspot-handler--overlaid div#mobile-chat-container {
  z-index: 2147483647;
}
.hubspot-hide #fc_frame,
.hubspot-hide #hubspot-messages-iframe-container,
.hubspot-hide #mobile-chat-container {
  display: none !important;
}

/** Applying hubspot handler functionality to freshchat */
