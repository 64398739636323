.k-vpn-config-row {
  display: grid;
  grid-template-areas:
    'controls helper'
    'secondary secondary';
}

.k-vpn-config-row__helper {
  grid-area: helper;
}

.k-vpn-config-row__controls {
  grid-area: controls;
}

.k-vpn-config-row__secondary {
  grid-area: secondary;
}

@media (max-width: 1440px) {
  .k-vpn-config-row {
    display: grid;
    grid-template-areas:
      'title'
      'helper'
      'controls';
  }

  .k-vpn-config-row__helper.k-vpn-config-row__helper {
    margin-bottom: var(--b-gap2);
  }
}
