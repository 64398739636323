.new-status-tab__loader {
  justify-content: center;
}

.new-status-tab__search-input {
  min-width: 304px;
  position: relative;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  height: 40px;
  outline: none;
  font-family: var(--font-family-primary);
  font-size: 14px;
  line-height: 17px;
  padding: 12px 40px 10px 15px;
}

.new-status-tab__link,
.new-status-tab__link:hover {
  text-decoration: none;
}
