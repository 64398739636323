@import '../util/upd-bumblebee';

.b-modal__body_with-backdrop {
  overflow: visible;
}

.k-device-actions {
  margin-left: auto;
  position: relative;
}

.k-device-actions .b-menu {
  right: 0;
  position: absolute;
  top: 50px;
  z-index: 999;

  /* roll-open effect */
  opacity: 1;
  max-height: 800px;
  display: block;
  overflow: hidden;
  transition:
    max-height 0.3s ease-out,
    opacity 0.3s ease-out;
}

.k-device-actions .hide {
  /* initial state for roll-open effect*/
  opacity: 0;
  max-height: 0;
}

.k-device-actions hr {
  border-top: solid 1px var(--color-neutral-20);
  margin-top: 12px;
  padding: 6px 12px;
}

.k-device-actions .icon {
  margin-right: 10px;
}

.k-device-actions button:is(:disabled) * {
  fill: var(--color-neutral-50);
}

/* hides existing icon when "sync" icon appears */
.k-device-actions button:is(.is-progress) > .icon {
  display: none;
}

.k-device-actions .trigger {
  display: flex;
  padding: 14px 10px !important;
  cursor: pointer;
  background-color: var(--color-neutral-20);
  border-radius: 4px;
}

.k-device-actions .option {
  &.hidden {
    pointer-events: none;
  }
}

.b-btn-dark-link.b-txt.option.red-text {
  @include upd-red;
  color: var(--color-red-50);
  i {
    svg {
      path {
        fill: var(--color-red-50);
      }
    }
  }
}

.option {
  display: flex;
  align-items: flex-start;
}

/* this should be reimplemented when the background color of ComputerSummaryCard is updated*/
/* .k-device-actions .trigger:hover {
  background-color: var(--color-neutral-20);
  border-radius: 4px;
} */
