.b-up-type-icon > img.k-mobile-config-icon {
  width: 36px;
  height: 36px;
}

.k-mobile-config-info {
  position: relative;
  display: flex;
  flex-direction: column;
}
.k-mobile-config-info__download {
  position: absolute;
  top: 100%;
  left: -16px;
}

.k-mobile-config-info__preview {
  opacity: 0.5;
}
