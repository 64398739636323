.li-item-card {
  position: relative;
  background-color: var(--color-neutral-0);
  border-radius: 4px;
}

.li-item-card__inactive > * {
  opacity: 0.5;
}

.li-item-card:hover {
  box-shadow: 0px 4px 10px #cccfd7;
}

.li-item-card__content {
  /* min-width: 0;  */
  padding: 16px;
}

.li-item-card__header {
  display: flex;
  align-items: center;
}

.li-item-card__header-img {
  height: 36px;
  width: 36px;
  border-radius: 4px;
  margin-right: var(--b-gap-tiny);
}

.li-item-card__header-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0.85;
  width: 0;
  height: 38px;
  overflow: hidden;
}

.li-item-card__header-title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-bottom: 2px;
}

.li-item-card__subtitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.li-item-card__body {
  position: relative;
  overflow: hidden;
  padding-left: 43px;
  padding-top: var(--b-gap1);
}

.li-item-card__blueprints {
  display: flex;
  width: fit-content;
  min-height: var(--b-gap2);
  white-space: nowrap;
  overflow: hidden;
}

.li-item-card__blueprints > * {
  margin-right: var(--b-gap-tiny);
}

.li-item-card__blueprints:last-child {
  margin-right: 0;
}

.li-item-card__blueprints-text {
  color: var(--color-neutral-50);
}

.li-item-card__counts {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: var(--b-gap2);
  box-shadow: 0px -1px 0px 0px #ececec;
}

.li-item-card__counts.--hide-counts {
  box-shadow: unset;
}

.li-item-card__counts > div {
  border-right: 1px solid #ececec;
  padding: 5px;
}

.li-item-card__counts > div:last-child {
  border-right: none;
}

.li-item-card__count {
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.li-item-card__count-number.li-item-card__count-number,
.li-item-card__footer-inactive.li-item-card__footer-inactive {
  font-size: 10px;
}

.li-item-card__footer-inactive {
  letter-spacing: 0.15em;
}

.li-item-card__count.--success {
  background-color: var(--color-green-50);
}

.li-item-card__count.--pending {
  background-color: var(--color-neutral-40);
}

.li-item-card__count.--failed {
  background-color: var(--color-yellow-50);
}

.li-item-card__icons {
  position: absolute;
  top: var(--b-gap);
  right: var(--b-gap);
}

.li-item-card__icons-img {
}

/* All Blueprints chip */
.li-item-card__all-blueprints .b-chip__icon {
  color: var(--colors-neutral90);
}

.li-item-card__all-blueprints .b-chip__text {
  color: var(--colors-neutral90);
  font-weight: var(--font-weight-regular);
}

.li-item-card__all-blueprints .b-chip--kind-info {
  background-color: var(--colors-blue10);
}

.li-item-card__blueprint-hidden {
  position: absolute;
  /* display: none; */
  visibility: hidden;
}

.tippy-box[data-theme~='li-self-service'] {
  background-color: #fff;
  filter: drop-shadow(0px 0px 10px rgba(26, 29, 37, 0.15));
  border-radius: 4px;
}

.tippy-box[data-theme~='li-self-service'] .tippy-content {
  padding: var(--b-gap);
}

.tippy-box[data-theme~='li-self-service'][data-placement^='top'] .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='li-self-service'][data-placement^='bottom']
  .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='li-self-service'][data-placement^='left'] .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='li-self-service'][data-placement^='right']
  .tippy-arrow {
  color: #fff;
}

.li-item-card__hidden-blueprints {
  display: grid;
  grid-gap: var(--b-gap-tiny);
  max-height: 200px;
  overflow-y: auto;
}

.li-item-card__self-service-svg,
.li-item-card__self-service-svg > span {
  display: flex;
  align-items: center;
}

/* */
.li-hidden {
  visibility: hidden;
  position: absolute;
}

.li-mr-tiny {
  margin-right: var(--b-gap-tiny);
}

.li-mb-micro {
  margin-bottom: var(--b-gap-micro);
}

.li-mt-micro {
  margin-top: var(--b-gap-micro);
}

.li-mb-tiny {
  margin-bottom: var(--b-gap-tiny);
}

.li-mr-micro {
  margin-right: var(--b-gap-micro);
}

.li-primary-marengo-dark {
  color: var(--color-neutral-60);
}

.li-system-red-dark {
  color: var(--color-red-50);
}

.li-system-orange-dark {
  color: var(--color-yellow-50);
}
