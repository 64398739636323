.summary-right {
  .status-report {
    cursor: pointer;
    display: flex;
    align-items: center;
    img {
      height: 45px;
      margin-right: 15px;
    }
    &:nth-child(2) {
      div {
        justify-content: space-between;
      }
    }
    div {
      display: flex;
      flex-flow: column;
      height: 45px;
      justify-content: center;
      span {
        text-transform: capitalize;
        font-weight: 400;
        font-size: 30px;
        line-height: 0.8;
        &:nth-child(2) {
          font-size: 18px;
          text-transform: lowercase;
          font-weight: 400;
        }
      }
    }
  }
}

.blueprint-param-categories {
  display: flex;
  flex-flow: row wrap;
  span {
    display: flex;
    padding: 5px 15px;
    align-items: center;
    height: 30px;
    margin-right: 10px;
    color: #2e3338;
    text-transform: capitalize;
    cursor: pointer;
    font-weight: 400;
    font-size: 14pt;
    margin-bottom: 6px;
    &.active {
      border: 1px solid $darkest-blue;
      border-radius: 15px;
      background-color: $darkest-blue;
      color: $white;
    }
  }
}
.summary-right,
.summary-left {
  min-height: 220px;
}
.blueprint-param-controls {
  background-color: $body-bgc;
  border-top: 1px solid $light-grey-border;
  border-bottom: 1px solid $light-grey-border;
  i {
    width: 15px;
  }
}

.blueprint-parameter {
  label {
    margin-bottom: 0;
  }
}

.parameter-name {
  margin-bottom: 0.5rem;
  font-size: 18px;
}

.parameter-description {
  //font-weight: 400;
  padding: 15px 60px 15px 20px;
  margin-right: 20px;
  border-left: 2px solid #c5d2e0;
  background-color: #f6f9fc;
  font-size: 12pt;
  color: #7290af;
  div:not(:last-child) {
    margin-bottom: 15px;
  }
}

.button-switch {
  margin-right: 18px;
  width: 77px;
  &.btn-null {
    &:active,
    &:hover,
    &:focus {
      color: $darkest-blue !important;
      background-color: #fff !important;
    }
  }
  &.btn-primary {
    &:active,
    &:hover,
    &:focus {
      color: white !important;
      background-color: $darkest-blue !important;
    }
  }
}

.params-subcategory {
  display: flex;
  padding: 15px 0;
  flex-direction: column;
  justify-content: flex-start;
  span {
    padding-top: 20px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-size: 1rem;
    font-family: var(--font-family-primary);
    border-top: 1px solid $light-grey;
  }
  div {
    padding: 15px 60px 15px 20px;
    margin-right: 20px;
    border-left: 2px solid #c5d2e0;
    background-color: #f6f9fc;
    font-size: 12pt;
    color: #7290af;
  }
}

.params-category {
  display: flex;
  padding: 15px 0;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 2rem;
  font-family: var(--font-family-primary);
  span {
    padding: 15px 0;
    border-top: 1px solid $light-grey-border;
  }
  div {
    padding: 15px 60px 15px 20px;
    margin-right: 20px;
    border-left: 2px solid #c5d2e0;
    background-color: #f6f9fc;
    font-size: 12pt;
    color: #7290af;
  }
}

.params-error {
  color: #f05b7e;
  input {
    border-color: #f05b7e;
    color: #f05b7e;
    &:focus {
      border-color: #f05b7e;
      color: #f05b7e;
    }
  }
  select {
    border-color: $danger;
    color: $danger;
    &:focus {
      border-color: $danger;
      color: $danger;
    }
  }
}

.params-error-message {
  font-size: 15px;
  padding: 0px;
  margin: 0px 5px;
  color: $danger;
}

.light-grey-icon {
  color: $light-grey;
}

.dark-grey-icon {
  color: $light-grey-text;
}

.dark-grey-new {
  color: $dark-grey-new;
}

.light-grey-new {
  color: $light-grey-new;
}

.description-icon {
  font-size: 20px;
  padding-left: 20px;
  cursor: pointer;
}

.history-status-icon {
  margin-right: 8px;
}

.history-details-p {
  display: flex;
  align-items: center;
}

.history-details-icon {
  color: $darkest-blue;
  margin-right: 8px;
  margin-bottom: 2px;
}

.history-upgrade-icon {
  color: $darkest-blue;
  margin: 0 8px;
}

.history-param-value {
  margin-top: 0 !important;
  margin-left: 26px !important;
  font-size: 12px !important;
  color: $dark-grey-text;
}

.history-parameters-details {
  width: 50%;
  color: $dark-grey-text;
}

.history-parameters-details li {
  list-style: initial;
}

.history-description {
  //font-weight: 200!important;
}

.duplicate-warning {
  padding: 1rem 2.75rem;
  border-color: #ff5b24;
  background-color: #ffcdd2;
  color: #000000;
}

.upload-file-message {
  font-weight: 400;
  color: #212529;
}
.multi-array-select-width {
  //width: 220px;
  display: block;
}

.default-cursor {
  cursor: default !important;
}

//***** NEW BLUEPRINT RECORD ******//

.blueprint-page-tab-container {
  margin-left: 222px;
}

@media (max-width: 768px) {
  .blueprint-page-tab-container {
    margin-left: calc(15rem + 3px - 3rem);
    //margin-right: -2rem;
  }
}

.full-width-sidebar-opened {
  .blueprint-menu,
  .blueprint-switcher {
    margin-left: 256px;
  }
  .blueprint-page-tab-container.bp-page-tab-container--adjust-for-sidebar {
    margin-left: 223px;
  }
}

.blueprint-page-tab-container.bp-page-tab-container--adjust-for-sidebar {
  margin-left: 223px;
}

.sub-bl-menu {
  overflow-y: auto;
  /* Width scrollbar */
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
  /* Track */
  &::-webkit-scrollbar-track {
    border-radius: 0.25rem;
  }
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $marengo-100;
    border-radius: 0.25rem;
  }
}

.blueprint-menu {
  //position: sticky;
  //position: -webkit-sticky;
  position: fixed;
  //height: calc(100% - 80px - 32px);
  margin-left: 77px;
  left: 0;
  right: 0;

  background-color: $marengo-50;
  //background-color: $blue-50;
  color: $marengo-350;
  width: 272px;
  min-width: 15rem;
  //margin-left: calc(-5px - 3rem);

  .blueprint-chooser {
    @extend .noselect;
    width: 220px;
    height: 3rem;
    margin: 2rem;
    border-radius: 25px;
    @media (max-width: 768px) {
      width: auto;
    }
    &.is-open {
      .Select-control {
        background-color: $marengo-650;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none;
        .Select-arrow {
          border-color: transparent transparent white !important;
        }
      }
    }
    &.is-focused:not(.is-open) > .Select-control {
      background-color: $marengo-699;
      box-shadow: none;
      border: 2px solid $marengo-500;
    }
    &.is-focused > .Select-control {
      //background: inherit;
      //background-color: $marengo-650;
    }
    .Select-control {
      width: 100%;
      height: 50px !important;
      margin: 2rem auto;
      border-radius: 25px;
      border: 2px solid $marengo-500;
      background-color: $marengo-699;
      .Select-placeholder {
        display: none;
      }
      .Select-value {
        line-height: 2.75rem !important;
        height: 3rem !important;
        padding-left: 30px;
        padding-right: 30px;
        @media (max-width: 768px) {
          padding-left: 20px;
          padding-right: 20px;
        }
        .Select-value-label {
          color: white !important;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.2rem;
        }
      }
      .Select-input {
        &:focus {
          background: inherit;
        }
      }
      .Select-arrow-zone {
        .Select-arrow {
          border-color: white transparent transparent;
          margin-right: 21px;
          @media (max-width: 768px) {
            margin-right: 11px;
          }
        }
      }
    }
    .Select-menu-outer {
      width: 100%;
      margin: auto;
      background-color: $marengo-650;
      left: 0;
      right: 0;
      border: 2px solid $marengo-500;
      border-top: none;
      border-radius: 0 0 2rem 2rem;
      padding: 0 30px 10px 30px;
      @media (max-width: 768px) {
        padding: 0 20px 10px 20px;
      }
      .Select-menu {
        overflow-y: auto;
        background: transparent;
        max-height: 158px;
        /* Width scrollbar */
        &::-webkit-scrollbar {
          width: 0.25rem;
        }
        /* Track */
        &::-webkit-scrollbar-track {
          border-radius: 0.25rem;
        }
        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: $grey-300;
          border-radius: 0.25rem;
        }

        .Select-option {
          @extend .cut-long-text;
          background-color: inherit;
          color: $grey-300 !important;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          letter-spacing: 0.2rem;
          padding: 15px 0;
          @media (max-width: 768px) {
            padding: 10px 0;
          }
          &.is-focused {
            background-color: inherit;
            color: white !important;
          }
        }
      }
    }
  }

  .blueprint-menu-tab {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    padding: 12px 18px 12px 30px;
    cursor: pointer;
    .blueprint-menu-tab-devices-icon {
      margin-top: 1px;
    }
    .blueprint-menu-tab-activity-stream-icon {
      margin-top: -4px;
    }
    .blueprint-menu-tab-notes-icon {
      margin-top: 1px;
      margin-left: 1px;
      margin-right: 6px;
    }
    .blueprint-menu-tab-title {
      display: flex;
      flex: 1;
      align-items: center;
    }
    .blueprint-menu-tab-counter {
      font-family: var(--font-family-primary);
      font-size: 11px;
      font-weight: 700;
      padding: 1px 8px;
      background-color: $marengo-630;
      color: white;
      border-radius: 1rem;
    }
    &:hover,
    &.active {
      color: $marengo-680;
    }
  }

  .blueprint-menu-tab-category-list {
    font-family: var(--font-family-primary);
    display: flex;
    flex-direction: column;
    margin: 0 30px;
    color: $marengo-350;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;

    .blueprint-menu-tab-library-items-header {
      font-family: var(--font-family-primary);
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-weight: 700;
      padding: 6px 2px;
      color: $marengo-250;
    }
    .blueprint-menu-tab-parameters-header {
      font-family: var(--font-family-primary);
      font-size: 11px;
      text-transform: uppercase;
      letter-spacing: 0.2em;
      font-weight: 700;
      padding: 18px 2px 6px;
      color: $marengo-250;
    }
    .blueprint-menu-tab-category {
      padding: 8px 16px 8px 0;
      &:hover,
      &.active {
        font-weight: 500;
        color: $marengo-680;
      }
    }

    .blueprint-menu-tab-subcategory {
      border-left: 2px solid $marengo-100;
      padding: 8px 16px;
      margin-left: 5px;
      &:hover,
      &.active {
        font-weight: 500;
        color: $marengo-680;
        border-left: 2px solid $marengo-680;
      }
    }
  }

  .blueprint-menu-settings {
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid $marengo-100;
    border-top: 1px solid $marengo-100;
    padding: 16px 10px;
    margin: 30px 20px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    .blueprint-menu-settings-title {
      margin-left: 5px;
      font-size: 11px;
      white-space: nowrap;
    }
    &:hover,
    &.active {
      color: $marengo-700;
    }
  }
}

@media (max-width: 768px) {
  .blueprint-menu {
    width: 15rem;
  }
}

.blueprint-parameters-form-controls {
  @extend .kandji-position-sticky;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 50px;
  margin-left: 0px;
  background-color: $grey-80;
  border-top: 1px solid $grey-250;
  border-bottom: 1px solid $grey-250;
  z-index: 1000;
  @media (max-width: 768px) {
    padding: 0 25px;
  }
  .blueprint-parameters-form-controls-buttons {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      width: auto;
      justify-content: flex-start;
      margin-left: 0;
    }
    button:nth-last-child(2n + 1) {
      margin-left: 15px;
    }
  }
  .blueprint-parameters-form-controls-error {
    //@extend .noselect; TODO
    text-align: center;
    width: 100%;
    color: $danger;
    font-weight: 500;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    .blueprint-parameters-form-controls-buttons {
      width: 100%;
    }
    .btn {
      padding: 0 5px !important;
      width: 9rem !important;
    }
  }
}

.blueprint-library-form-controls {
  @extend .kandji-position-sticky;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 50px;
  margin-left: 0px;
  background-color: $grey-80;
  border-bottom: 1px solid $grey-250;
  z-index: 999;
  @media (max-width: 768px) {
    padding: 0 25px;
  }
  .blueprint-parameters-form-controls-buttons {
    display: flex;
    justify-content: flex-end;
    @media (max-width: 768px) {
      width: auto;
      justify-content: flex-start;
      margin-left: 0;
    }
    button:nth-last-child(2n + 1) {
      margin-left: 15px;
    }
  }
  .blueprint-parameters-form-controls-error {
    //@extend .noselect; TODO
    text-align: center;
    width: 100%;
    color: $danger;
    font-weight: 500;
    letter-spacing: 0.1rem;
    cursor: pointer;
  }
  @media (max-width: 768px) {
    .blueprint-parameters-form-controls-buttons {
      width: 100%;
    }
    .btn {
      padding: 0 5px !important;
      width: 9rem !important;
    }
  }
}

.left-right-padding {
  padding: 0 50px;
}

.blueprint-parameters-form {
  display: flex;
  flex-direction: column;
  margin-right: -3rem;
  padding-bottom: 40vh; // 40vh for scroll to the bottommost categories
  @media (max-width: 768px) {
    padding: 0 25px;
  }
  &.no-padding-end {
    padding-bottom: 20px;
  }
  .blueprint-parameters-category {
    //@extend .noselect; TODO
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 0;
    .blueprint-parameters-category-title {
      display: flex;
      align-items: center;
      span {
        text-transform: uppercase;
        color: $marengo-700;
        font-size: 20px;
        font-weight: 700;
        letter-spacing: 0.2em;
      }
      i {
        color: $grey-300;
        margin-left: 0.5rem;
        cursor: pointer;
        font-size: 1.125rem;
        &.active {
          color: $grey-500;
        }
      }
    }
  }
  .blueprint-parameters-subcategory {
    //@extend .noselect; TODO
    display: flex;
    flex-direction: column;
    padding: 24px 0 0 0;
    .blueprint-parameters-subcategory-title {
      display: flex;
      align-items: center;
      span {
        text-transform: uppercase;
        color: $marengo-700;
        font-size: 20px;
        font-weight: 500;
        letter-spacing: 0.2em;
      }
      i {
        color: $grey-300;
        margin-left: 0.5rem;
        cursor: pointer;
        font-size: 1.125rem;
        &.active {
          color: $grey-500;
        }
      }
    }
  }
  .blueprint-parameters-parameter {
    //@extend .noselect; TODO
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    max-width: 950px;
    padding: 25px 30px 14px 30px;
    margin: 19px 0;
    //border-bottom: 1px solid $grey-300;
    border: 1px solid $grey-230;
    border-radius: 5px;
    -webkit-box-shadow:
      0 7px 14px 0 rgba(60, 66, 87, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    -moz-box-shadow:
      0 7px 14px 0 rgba(60, 66, 87, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    box-shadow:
      0 7px 14px 0 rgba(60, 66, 87, 0.1),
      0 3px 6px 0 rgba(0, 0, 0, 0.07);
    background-color: white;
    .blueprint-parameters-parameter-header {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      width: 100%;
      line-height: 32px;
      align-items: center;
      .blueprint-parameters-parameter-name {
        display: flex;
        align-items: center;
        span {
          //color: $marengo-700;
          color: $grey-999;
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.025em;
          margin-right: 7px;
        }
        i {
          color: $grey-300;
          margin-left: 0.5rem;
          cursor: pointer;
          font-size: 1.125rem;
          &.active {
            color: $grey-500;
          }
        }
      }
    }
    .blueprint-parameters-parameter-body {
      display: flex;
      flex-direction: column;
      width: 100%;
      //label {
      //  font-family: "Sharp Sans", sans-serif;
      //}
      .form-group {
        margin-top: 15px;
      }
      .blueprint-parameters-parameter-description {
        display: flex;
        flex-direction: column;
        margin: 5px 0;
        color: $grey-500;
        font-size: 14px;
        line-height: 20px;
        > * {
          margin-bottom: 5px;
        }
        padding-right: 90px;
        @media (min-width: 1200px) {
          padding-right: 140px;
        }
        .blueprint-parameters-parameter-description-title {
          font-family: var(--font-family-primary);
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 0.05rem;
          font-size: 8pt;
          color: $grey-400;
          padding: 11px 0 10px 0;
        }
        .blueprint-parameters-parameter-dependency-title {
          font-size: 10px;
        }
        .blueprint-parameters-parameter-dependency-text {
          font-size: 12px;
          color: #999;
        }
      }
      .parameter-text-input-container {
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        //margin: 1px;
        //border: 1px solid $grey-250;
        box-shadow: 0 0 0 1px $grey-250;
        padding: 10px 15px;
        background-color: $grey-80;
        transition: all 0.2s;
        label,
        input {
          margin-bottom: 0;
          margin-top: 0;
        }
        label {
          color: $grey-400;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: 700;
          letter-spacing: 0.2em;
        }
        input[type='number'],
        input[type='text'],
        input[type='password'] {
          overflow: hidden;
          white-space: nowrap;
          outline: none;
          box-shadow: none;
          border: none;
          //border-bottom: 2px solid $grey-250;
          background-color: inherit;
          border-radius: 0;
          font-size: 14px;
          font-weight: 400;
          font-family: var(--font-family-primary);
          color: $grey-999;
          &:disabled {
            color: $grey-500;
          }
        }
        input[type='password'] {
          font-size: 20px;
          line-height: 21px;
          height: 23px;
        }
        &.param-error {
          border-color: $danger;
        }
        &:focus-within {
          box-shadow: 0 0 0 2px $marengo-200;
          //border: 2px solid $marengo-200;
          //margin: 0;
          label {
            color: $marengo-700;
          }
        }
      }
    }
    .blueprint-parameters-parameter-mute-icon {
      margin-left: 13px;
      cursor: pointer;
      color: $grey-500;
      font-size: 1.3rem;
      .fa-bell-slash {
        color: $grey-270;
      }
    }
    .bp-param-del-field-btn {
      cursor: pointer;
      padding: 6px 10px;
      color: $grey-400;
      &:hover {
        color: $orange-500;
      }
    }
  }
  .blueprint-parameters-custom-app {
    padding: 14px 30px;
  }
}
