.firewall__checkbox-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  justify-items: start;
}

.firewall__manage-pf-buttons {
  display: inline-flex;
  align-items: center;
}
