.k-grid-library-settings--kandji-setup {
  margin-bottom: 21px;
}

.k-grid-library-settings--kandji-setup label {
  margin: 0;
}

.k-library-settings-logo-upload {
  background: var(--color-neutral-20);
}

.b-library-form {
  position: relative;
}
