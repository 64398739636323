/* ========================= */
/* ==========!MENU========== */
/* ========================= */

/* ----------!MAIN---------- */
.menu {
  margin: 0;
  padding: 0;
  list-style: none;
  user-select: none;
}
