/* ========================== */
/* ==========!MODAL========== */
/* ========================== */

/* ----------!MAIN---------- */
.spring-modal {
  margin-top: 80px;
  width: 100%;
  position: fixed;
  top: 0;
  left: 50%;
  // overflow: hidden;
  z-index: 999999;
  transform: translate(-50%);
  white-space: normal;
}

/* ----------!DEFAULT---------- */
@include media-breakpoint-up(md) {
  .spring-modal-default {
    max-width: 550px;
  }
}

/* ----------!PAPER---------- */
.spring-modal .paper {
  background-color: $color-white !important;
}

/* ----------!HEADER---------- */
.spring-modal .with-cross {
  display: flex;

  > .node:first-child {
    flex-grow: 1;
  }
}

.spring-modal .paper .division .cross-container {
  display: flex;
  flex-direction: row-reverse;
  padding: 15px 20px 0 20px;

  + .node {
    padding-top: 10px;
  }
}

/* ----------!TEXT---------- */
.spring-modal .modal-title {
  margin-top: -4px;
  margin-bottom: -6px;
  font-family: $font-family-title;
  font-weight: $font-weight-title;
  font-size: $font-size-title;
  line-height: $line-height-title;
  color: $color-brand-marengo-ultradark;
}
.spring-modal .modal-title + .modal-regular {
  margin-top: 13px;
  margin-bottom: 5px;
}
.spring-modal .modal-regular {
  color: $color-brand-marengo-ultradark;
}
.spring-modal .theme-form-text-label,
.spring-modal .theme-form-textarea-label {
  margin-bottom: var(--b-gap1);
}
.spring-modal .theme-form-block-valid {
  padding-top: var(--b-gap2);
}
.spring-modal .modal-strong {
  font-weight: $font-weight-headline;
  color: $color-brand-marengo-ultradark;
}

body.kb-modal-open::after {
  background: rgba(0, 0, 0, 0.7);
}

/* ----------!SPACE---------- */
.spring-modal section {
  padding-bottom: $space-paper;
}

/* ----------!TEMPORARY---------- */
.modal-temp {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 9999;
}
.modal-temp-content {
  position: fixed;
  height: 100%;
  width: 50%;
  right: 0;
  top: 0;
  background-color: $color-white;
  z-index: 9999;
  overflow-y: scroll;

  @media (max-width: 768px) {
    width: 100%;
  }
}
