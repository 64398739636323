.custom-helper {
  background-color: $dark-grey-text;
}

.role-helper {
  max-width: 850px;
  p {
    padding: 5px 0;
    margin: 0;
  }
}

.cover-all-helper {
  z-index: 9999;
}

.tooltip-inner {
  z-index: 1000;
  padding: 6px 6px 6px 6px;
  background-color: #ffffff;
  border-radius: 4px;
  bottom: 100px;
  box-shadow: 0 4px 10px #cccfd7;
  color: #1a1d25;
}

.bs-tooltip-auto[x-placement^='bottom'] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: #ffffff;
}

.bs-tooltip-auto[x-placement^='top'] .arrow::before,
.bs-tooltip-top .arrow::before {
  border-top-color: #ffffff;
}

.bs-tooltip-auto[x-placement^='left'] .arrow::before,
.bs-tooltip-left .arrow::before {
  border-left-color: #ffffff;
}

.bs-tooltip-auto[x-placement^='right'] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: #ffffff;
}

.tooltip-right {
  margin-right: -20%;
}
