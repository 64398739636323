.ruletag {
  margin-bottom: 8px;
  margin-right: 16px;
  padding: 8px;
  height: 32px;
  max-height: 32px;

  &.empty {
    background-color: transparent;
  }

  &.b-chip--disabled {
    background-color: var(--b-primary-marengo-ultralight);
    border-color: var(--b-primary-marengo-light);
    opacity: 1;

    .ruletag-text {
      color: var(--b-primary-marengo-medium);
      cursor: default;
    }
  }
}

.ruletag-text {
  color: var(--color-neutral-60);
  font-weight: 375;
  font-size: 14px;
}

.ruletag-text > .operator {
  font-weight: 525;
}

.ruletag-column {
  max-width: 100%;
}
