.modal-backdrop {
  &.show {
    opacity: 0.7;
  }
}

.modal-dialog {
  margin: 5.75rem auto;
  width: 438px;
  .modal-content {
    padding: 30px 53px 30px 51px;
    border: none;
    .modal-header {
      padding: 0;
      border: none;
      .modal-title {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 600;
        font-family: var(--font-family-primary);
        letter-spacing: 0.15rem;
      }
    }

    .modal-body {
      padding: 3rem 0 1rem 0;
      a {
        text-decoration: none;
      }
    }

    .modal-footer {
      border-top: none;
      padding: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      //button {
      //  margin-left: 30px;
      //}

      .btn {
        width: 45%;
      }
    }
  }
}

.modal-dialog-wide {
  width: 800px !important;
  max-width: 50% !important;
}

.dropdown-toggle {
  display: flex;
  justify-content: center;
  &::after {
    color: inherit;
    font-family: 'Font Awesome 5 Pro', sans-serif;
    font-weight: 400;
    width: 0;
    height: 0;
    margin-top: 0.07em;
    margin-left: 11px !important;
    vertical-align: initial;
    content: '\f107';
    border: none;
    font-size: inherit;
  }
}

.new-modal.width-constraint {
  max-width: 560px;
  max-height: 100%;
  overflow-y: auto;

  &.isOverflowYVisible {
    overflow-y: visible;
  }
}

.modal-dialog {
  .centerHeader {
    margin: auto;
  }
  .modal-close-button {
    position: absolute;
    right: 20px;
    top: 20px;

    .far.fa-times {
      cursor: pointer;
      font-size: 1.2em;
      opacity: 0.5;
    }
  }

  &.loading {
    .modal-content {
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        height: 100%;
        width: 100%;
        content: '';
        background: rgba(62, 64, 68, 0.2) url(../../img/spinner.svg) no-repeat
          center;
      }
    }
  }
}

.modal-key-value {
  width: auto;
  border: none;
  outline: none;
  font-weight: 500;
}

.modal-div-previous-keys {
  margin-top: 40px;
  span {
    font-weight: 500;
    text-transform: uppercase;
  }
  i {
    margin-left: 5px;
  }
}

.modal-p-previous-keys-dt {
  margin-bottom: 5px !important;
  &:first-of-type {
    margin-top: 20px;
  }
}

.modal-p-previous-keys-values {
  font-weight: 500 !important;
  &:first-of-type {
    margin-top: 20px;
  }
}
