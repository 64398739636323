/* ================================ */
/* ==========!INFORMATION========== */
/* ================================ */

/* ----------!HEADER---------- */
.information header .field {
  height: 44px;
  display: flex;
  align-items: center;
}

/* ----------!FOOTER---------- */
.information footer .field {
  padding-bottom: $space-paper;
}
.information footer .field .field-label {
  margin: 0;
  line-height: 40px;
  color: $color-brand-marengo-ultradark;
}

/* ----------!INDEX---------- */
.information header,
.information footer {
  z-index: 1;
}

/* ----------!OFFSET---------- */
.information .offset {
  padding-left: 60px;
}

/* ----------!PAPER---------- */
.information .paper {
  flex-direction: column;
}

/* ----------!TITLE---------- */
.information .title {
  min-width: 0;
  max-width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: 0;
  flex-grow: 1;
}
.information .title > input {
  @include truncate();
  background-color: $color-transparent;
  border: 0 none $color-transparent;
  border-bottom: 2px solid $color-transparent;
  border-top-left-radius: $border-radius-dull;
  border-top-right-radius: $border-radius-dull;
  /*padding: $space-input;*/
  padding: 4px ($space-paper - 2px) 0;
  width: 100%;
  height: 100%;
  color: $color-brand-marengo-ultradark;
  -webkit-text-fill-color: $color-brand-marengo-ultradark; // required for Safari (https://stackoverflow.com/questions/262158/disabled-input-text-color)
  opacity: 1; // required for Safari (https://stackoverflow.com/questions/262158/disabled-input-text-color)
  outline: none;
}
.information .title > input:disabled {
  padding: 2px 0 0;
  font-family: $font-family-title;
  font-weight: var(--font-weight-semi-bold);
  font-size: $font-size-title;
}
.information .title > input::placeholder {
  color: $color-brand-marengo-dark;
  -webkit-text-fill-color: $color-brand-marengo-dark;
}
.information .title > .icon {
  margin-top: 1px;
  margin-right: -1px;
}
.information .title > .icon > svg {
  stroke: $color-brand-marengo-ultradark;
}
.information .title-edit > input:enabled {
  background-color: $color-brand-marengo-ultralight;
  border-color: $color-brand-marengo-light;
}
.information .title-edit > input:enabled:hover {
  border-color: $color-brand-marengo-medium;
}
.information .title-edit > input:enabled:focus {
  border-color: $color-system-link;
}
.information .title-error > input {
  border-color: $color-system-error !important;
}
.information .title-error > input::placeholder {
  color: $color-system-error-light !important;
  -webkit-text-fill-color: $color-system-error-light !important;
}
.information .title-error > .icon > svg {
  stroke: $color-system-error;
}

/* ----------!SUMMARY---------- */
.information .detail {
  margin-top: -5px;
  margin-bottom: -9px;
}
.information .detail .detail-list-wrap {
  margin-top: -3px;
}
.information .detail .detail-value,
.information .detail .detail-list {
  color: $color-brand-marengo-ultradark;
}
.information .detail .detail-list > .chip > span {
  padding-top: 2px;
}
.information .detail > .detail-row + .detail-row,
.information .description {
  margin-top: 7px;
}
.information .description {
  margin-bottom: 0;
}
.information .detail .banner:last-of-type {
  margin-bottom: 9px;
}

/* ----------!ASSIGNMENT DROPDOWN FOOTER---------- */
%dropdown-footer {
  font-size: $font-size-button;
  font-weight: $font-weight-3;
  line-height: 10px;
  color: $color-brand-marengo-ultradark;
}
.information .dropdown .dropdown-toggle:disabled .field-list-wrap {
  border-color: $color-brand-marengo-light;
}
.information .dropdown .dropdown-menu .dropdown-menu-search {
  padding-top: 3px;
}
.information .dropdown .dropdown-menu .dropdown-menu-footer > p {
  @extend %dropdown-footer;
  border-top: 1px solid $color-divider;
  padding-top: ($space-paper - 3px);
}

.information .dropdown .dropdown-menu .dropdown-menu-fallback > p {
  @extend %dropdown-footer;
}

.information .dropdown .dropdown-menu .dropdown-menu-footer > p > a,
.information .dropdown .dropdown-menu .dropdown-menu-fallback > p > a {
  font-weight: $font-weight-4;
  text-decoration: none;
  color: $color-link;
}

.information .dropdown .dropdown-menu .dropdown-menu-search-reset {
  margin-right: -5px;
  padding-top: 1px;
  width: $space-paper;
  height: $space-paper;
  font-size: 24px;
  font-weight: $font-weight-1;
  font-style: normal;
  line-height: $space-paper;
  color: $color-system-information;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.information .dropdown .dropdown-menu .dropdown-menu-search-reset.hide {
  display: none;
}

/* ----------!ASSIGNMENT DROPDOWN ERROR---------- */
.information .ms-dropdown .error {
  border: 2px solid $color-system-error;
}
