.pagination-block {
  align-items: center;
  width: 100%;
  font-family: var(--font-family-primary);
  .results-label-left {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 1em;
    font-size: 12px;
    color: #6c757d;
    //font-family: 'FontAwesome', sans-serif;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    font-weight: 700;
    width: 100%;
  }
}

.pagination {
  margin-bottom: 0;
  justify-content: flex-end;
  .page-item {
    font-size: 12px;
    background: none;
    .fa,
    .far,
    .fab,
    .fas {
      //font-size: 22px;
      line-height: 30px;
    }
    &:last-child a.page-link-icon {
      font-size: 1.8rem;
    }
    &:first-child a.page-link-icon {
      font-size: 1.8rem;
    }
    .page-link {
      font-weight: 400;
      background-color: transparent;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .active {
      font-weight: bolder;
    }
    a {
      font-weight: 500;
      height: 36px;
      line-height: 36px;
      text-align: center;
      //font-size: 1rem;
      color: $grey-600;
      //border-color: $light-grey;
      padding: 0 5px;
      min-width: 30px;
      width: auto;
      border: none;
      &:hover {
        color: black;
      }
      &:focus {
        outline: none;
      }
      &.active {
        color: $darkest-blue;
        background: none;
        pointer-events: none;
      }
      &[disabled] {
        cursor: no-drop;
        &:not(.active) {
          pointer-events: none;
          color: rgba(108, 117, 125, 0.3);
        }
      }
      &.ellipsis {
        background: none;
        border-color: transparent;
      }
      &.page-link-icon {
        padding: 0;
        width: 30px;
      }
    }
    &[title='next page'],
    &[title='last page'],
    &[title='previous page'],
    &[title='first page'] {
      a {
        font: normal normal normal 14px/1 'Font Awesome 5 Pro';
        font-size: 22px;
        padding: 0;
        line-height: 30px;
        font-weight: 400;
      }
    }
  }
}

.pagination-extended {
  margin-right: 0;
  margin-left: 0;
}

.pagination-base {
  padding-left: 15px;
  padding-right: 15px;
}

.pagination {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.size-per-page-dropdown-and-counts {
  display: flex;
  flex-direction: row;
  margin-right: auto;
  width: 100%;
}

.react-bs-table-pagination {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
}
