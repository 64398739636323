.users-table-tr {
  cursor: pointer;
  &:hover {
    background-color: $light-grey;
  }
}

.last-tr-class {
  td:first-child {
    border-bottom-left-radius: 8px;
  }
  td:last-child {
    border-bottom-right-radius: 8px;
  }
}

.users-checkbox-list {
  margin-top: 25px;
  .checkbox {
    border-bottom: 1px solid $light-grey-border;
    &:first-child {
      border-top: 1px solid $light-grey-border;
    }
    label {
      margin: 0;
      padding: 10px 0;
      input[type='checkbox'] {
        vertical-align: middle;
        margin-right: 15px;
      }
    }
  }
}
