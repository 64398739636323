@mixin upd-orange {
  --b-system-orange-light: #fffae6;
  --b-system-orange-medium: #ffc806;
}

@mixin upd-h4 {
  .b-h4 {
    font-size: 16px;
  }
}

@mixin upd-red {
  --b-system-red-dark: #c30000;
  --b-system-red-light: #fcf2f2;
}
