/* =========================== */
/* ==========!BANNER========== */
/* =========================== */

/* ----------!MAIN---------- */
.banner {
  background-color: $color-white;
  border-radius: $border-radius-dull;
  display: flex;
}
.banner p {
  margin-top: -6px;
  margin-bottom: -9px;
  padding: 0;
  text-align: left;
}
.banner > .node {
  padding: $space-paper;
}
.banner > .node + .node {
  margin-left: -35px;
}

/* ----------!SPACE---------- */
p + .banner,
.banner + .banner,
.banner + .identity {
  margin-top: $space-paper;
}
.banner .icon {
  margin: -5px;
  margin-right: 10px;
  min-width: $space-paper;
  min-height: $space-paper;
}

/* ----------!TYPE---------- */
.banner-mini-info {
  background-color: $color-grey-1;
  width: fit-content;

  .icon {
    margin: 1px 8px 0 0;

    > svg {
      fill: $color-system-information;
    }
  }

  .node.node {
    padding: 10px 0;
    margin: 0;
  }
  .node:first-child {
    margin-left: 16px;
  }
  .node:last-child {
    margin-right: 16px;
  }
  a {
    color: $color-system-link;
  }
}
.banner-information {
  background-color: $color-grey-1;
}
.banner-information .icon > svg {
  fill: $color-system-information;
}
.banner-status {
  background-color: $color-system-information;
  color: $color-grey-1;
}
.banner-status .icon > svg {
  fill: $color-white;
}

.banner-narrow-status {
  background-color: $color-system-information;
  color: $color-grey-1;

  .icon > svg {
    fill: $color-white;
  }
  .node {
    $verticalPadding: 11px;
    $horizontalPadding: 15px;

    padding-top: $verticalPadding;
    padding-bottom: $verticalPadding;

    &:first-child {
      padding-left: $horizontalPadding;
    }
    &:last-child {
      padding-right: $horizontalPadding;
    }
  }
}
.banner-warning {
  background-color: $color-system-warning-light;
  color: $color-system-warning;
}
.banner-warning .icon > svg {
  fill: $color-system-warning;
}
