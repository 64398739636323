.tooltip-fade {
  opacity: 1;
  animation-name: tooltipFade;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 2s;
}

@keyframes tooltipFade {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
