/* =========================== */
/* ==========!AVATAR========== */
/* =========================== */

/* ----------!MAIN---------- */
.avatar {
  width: $space-paper * 2;
  height: $space-paper * 2;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.avatar:not([class*='avatar-logo']):not([class*='avatar-firewall']):not(
    [class*='avatar-software-updates']
  )
  > .icon
  > svg {
  fill: $color-white;
}

/* ----------!IMAGE---------- */
// .avatar-image {
//   border-radius: 25%;
// }

/* ----------!ICON---------- */
.avatar-icon:not([class*='avatar-logo']) {
  // background-color: $color-brand-marengo-ultradark;
  border-radius: 50%;
}

/* ----------!ICON---------- */
.avatar-logo > .icon {
  width: 100%;
  height: 100%;
}

/* ----------!TYPE---------- */
.avatar-icon.avatar-kandji-setup {
  background-color: orange !important;
}
.avatar-energy-saver {
  background-color: $color-brand-yellow-dark !important;
}
.avatar-software-updates {
  background-color: $color-brand-green-dark !important;
}
.avatar-software-updates > .icon-software-updates {
  width: 34px;
  height: 34px;
}
.avatar-screen-saver {
  background-color: $color-brand-yellow-dark !important;
}
.avatar-firewall {
  background-color: #ff8a00 !important; // icon using an offbrand orange
}
.avatar-filevault {
  background-color: #5457f6 !important; // icon using an offbrand blue
}
.avatar-gatekeeper {
  background-color: $color-brand-green-dark !important;
}
.avatar-system-preferences {
  background-color: #e54476 !important; // icon using an offbrand red
}
.avatar-media-access {
  background-color: #5457f6 !important;
}
.avatar-custom-scripts {
  background-color: #5457f6 !important;
}
.avatar-custom-printers {
  background-color: #a5d167 !important;
}
// .avatar-custom-app > img,
.avatar-custom-script > img,
.avatar-custom-printer > img {
  width: 100%;
  height: 100%;
}

/* ----------!OVERRIDE---------- */
.avatar > .icon.icon-firewall,
.avatar > .icon.icon-system-preferences,
.avatar > .icon.icon-media-access {
  width: 100%;
  height: 100%;

  svg {
    fill: none;
  }
}
.avatar > .icon-filevault {
  margin-top: -2px;
}

/* ----------!RESET---------- */
.avatar-app-store {
  background-color: $color-transparent !important;
  border-radius: unset !important;
}
.avatar-app-store > .icon-app-store {
  width: 100% !important;
  height: 100% !important;
}

/* ----------!SPACE---------- */
.avatar + .title {
  margin-left: 16px;
}

/* ============================= */
/* ==========!STRATEGY========== */
/* ============================= */

/* ----------!MAIN---------- */
.strategy {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.strategy > .icon {
  margin-top: -6px;
  margin-bottom: -4px;
  width: 30px;
  height: 30px;
}
.strategy > .icon + .label,
.strategy > .label + .icon {
  margin-left: 10px !important;
}

/* ============================= */
/* ==========!IDENTITY========== */
/* ============================= */

/* ----------!MAIN---------- */
p + .identity {
  margin-top: $space-paper;
}
.identity {
  @include styleInput();
  padding: $space-paper;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.identity > .strategy {
  margin-bottom: -3px;
}
.identity > .strategy + .toggle-on > input:checked {
  background-color: $color-system-success;
}
.identity > .strategy + .toggle-on > input:disabled:checked {
  background-color: $color-system-success-light;
}

/* ============================ */
/* ==========!PREVIEW========== */
/* ============================ */

/* ----------!MAIN---------- */
.preview {
  @include styleInput();
  padding: ($space-paper - 2px);
  min-width: 120px;
  min-height: 120px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.preview:hover,
.preview:hover {
  border-color: $color-brand-marengo-medium;
}
.preview:disabled {
  @include styleInputDisabled();
}
.preview .preview-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.preview .preview-avatar > .avatar {
  margin: -3px;
  width: 86px;
  height: 86px;
}
