@import '../../../template/scss/mq.def';

.k-dc-config-row__secondary {
  @media (min-width: #{$--md-above}) {
    grid-area: 4/1/5/3;
  }
}

.b-library-second-ctrl-row-helper.--last {
  @media (max-width: #{$--md-below}) {
    padding-left: 20px;
  }
}

.k-dc-config-row__secondary .b-library__setting-secondary-controls-row {
  grid-column-gap: 0;
}
