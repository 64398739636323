.k-klogin-preview {
  padding: var(--b-gap2);
}

.k-klogin-preview-row {
  margin-top: var(--b-gap2);
}

.k-klogin-datetime {
  position: absolute;
  top: 18px;
  left: 49%;
  height: 225px;
  width: 600px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  transform: translate(-50%, 0) scale(65%);
  opacity: 0.8;
}

.k-klogin-datetime .k-klogin-date, .k-klogin-datetime .k-klogin-time {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: 400;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, .85);
  text-shadow: 0px 1.025px 2.5625px rgba(0, 0, 0, 0.4);
  text-align: center;
}

.k-klogin-datetime .k-klogin-date {
  font-size: 42px;
  line-height: 60px;
}

.k-klogin-datetime .k-klogin-time {
  font-size: 170px;
  line-height: 160px;
  font-weight: 500;
}

.k-klogin-preview-image {
  width: 100%;
  height: 820px;
  /* background-size: 100%; */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.k-klogin-login-preview {
  position: relative;
}

.k-klogin-help-preview {
  position: relative;
}

.k-klogin-login-preview-inner {
  user-select: none;
  position: absolute;
  width: 100%;
  height: 100%;
  /*background: rgba(0, 0, 0, 0.5);*/
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.k-klog-pheader {
  width: 100%;
}
.k-klog-apple {
  position: absolute;
  left: 21px;
  top: 4px;
  width: 10px;
}
.k-klog-pheader p {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 9px;
  letter-spacing: -0.179375px;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 1.025px 2.05px rgb(0 0 0 / 15%);
  margin: 0;
}

.k-klog-gg {
  position: relative;
  top: -1px;
}

.k-klog-pheader img {
  width: 18px;
  height: 14px;
  object-fit: contain;
  position: relative;
  top: 1px;
}

.k-klog-pheader-inner {
  display: flex;
  grid-gap: 10px;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
  padding: 0 16px 0 0;
  user-select: none;
  min-height: 21px;
}

.k-klog-pform {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, calc(-50% + 7px));
  padding: 0;
}

.k-klog-pform-logo {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}

.k-klog-pform-logo.pform-logo-hide {
  visibility: hidden;
}

.k-klog-pform-logo-inner {
  height: 96px;
  width: 96px;
  border-radius: 24px;
  background: rgba(246, 246, 246, 0.72);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  text-align: center;
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
}

.k-klog-pform-logo-inner img {
  width: 80px;
  height: 80px;
  object-fit: contain;
}

.k-klog-pform-inputs {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.k-klog-pform-inputs p,
.k-klog-pform-inputs input {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 8px;
  letter-spacing: -0.0363672px;
  color: rgba(245, 245, 245, 0.85);
  margin: 0;
  position: relative;
  top: -1px;
}

.k-klog-pform-input input {
  background: transparent;
  border: none;
  color: white;
  width: 100%;
  pointer-events: none;
}

.k-klog-pform-input input:focus,
.k-klog-pform-input input:active {
  outline: 0;
}

.k-klog-pform-input {
  background: rgb(255 255 255 / 18%);
  box-shadow: 0px -2px 20px rgb(0 0 0 / 1%), 0px 4px 50px rgb(0 0 0 / 7%);
  backdrop-filter: blur(15px);
  border-radius: 29px;
  padding: 0 9px;
  user-select: none;
  width: 123px;
  height: 23px;
  position: relative;
  display: flex;
  align-items: center;
}

.k-klog-pform-input:not(:last-child) {
  margin-bottom: 12px;
}

.k-klog-pform-input:first-child {
  margin-bottom: 12px;
}

.k-klog-pform-forgot-pwd,
.k-klog-pform-forgot-pwd.--show {
  transition: all 150ms ease-in-out;
  transition-property: opacity;
}

.k-klog-pform-forgot-pwd {
  --pform-pwd-bg: rgb(255 255 255 / 38%);
  opacity: 0;
  position: absolute;
  bottom: -49px;
  left: 50%;
  width: 200px;
  height: 52px;
  background: var(--pform-pwd-bg);
  transform: translate(calc(-50% - 7px));
  border-radius: 8px;
  box-shadow: 0px 5px 5px 0px hsl(0deg 0% 0% / 8%);
  padding: 12px 17px;
  backdrop-filter: blur(7px);
}

.k-klog-pform-forgot-pwd.--show {
  opacity: 1;
}

.k-klog-pform-forgot-pwd::before {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 9px solid var(--pform-pwd-bg);
  position: absolute;
  top: -9px;
  left: 50%;
  transform: translateX(-50%);
}

.k-klog-pform-forgot-pwd p {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 500;
  font-size: 9px;
  line-height: 11px;
  letter-spacing: -0.236367px;
  color: black;
}

.k-klog-pform-forgot-pwd p:first-child {
  margin-bottom: 0;
  position: relative;
  top: 1px;
  left: 1px;
}

.k-klog-pform-forgot-pwd i {
  font-style: normal;
  text-decoration: underline;
  color: #2e68cc;
}

.k-klog-pform-forgot-pwd p:nth-child(2) {
  display: flex;
  align-items: center;
}

.k-klog-pform-forgot-pwd span {
  display: flex;
  width: 16px;
  height: 14px;
  align-items: center;
  justify-content: center;
}

.k-klog-forgot-pwd-img {
  height: 12px;
  position: relative;
  left: -1px;
  top: 0px;
}

.k-klog-forgot-pwd-tip {
  display: none;
  position: absolute;
  z-index: 1;
  top: -13px;
  left: 50%;
  transform: translateX(calc(-50% - -1px));
}

.k-klog-forgot-pwd-tip img {
  height: 15px;
}

.k-klog-pmsg {
  width: 440px;
  position: absolute;
  bottom: 100px;
  text-align: center;
  color: white;
  left: 50%;
  transform: translate(-50%);
}

.k-klog-pmsg p {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  max-height: 42px; /* Allows 3 lines of display text only. */
  overflow: hidden;
  text-align: center;
  letter-spacing: -0.04px;
  color: #ffffff;
  text-shadow: 0px 1.025px 2.5625px rgba(0, 0, 0, 0.4);
  margin: 0;
  white-space: pre-wrap;
}

.k-klog-pfooter {
  position: absolute;
  bottom: 43px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 39px 0 39px;
  min-height: 44px;
}

.k-klog-pfooter svg {
  height: 17px;
  z-index: 1;
  position: relative;
  top: -1px;
  left: 0;
  width: 100%;
  opacity: 0.6;
}

.k-klog-footer-button.--power svg {
  top: 0px;
  height: 16px;
}

.k-klog-footer-button {
  position: relative;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 41px;
}

.k-klog-footer-button:not(:last-child) {
  margin-right: 65px;
}

.k-klog-footer-button.--help {
  width: auto;
  position: relative;
  top: 16px;
  left: -1px;
}

.k-klog-footer-button.--help svg {
  height: 10px;
}

.k-klog-footer-button.--help path {
  fill: #fff;
}

.k-klog-footer-button.--help p {
  display: none;
}

.k-klog-footer-button.--help .k-klog-footer-svg {
  background: #5e7091;
  width: 20px;
  height: 20px;
  box-shadow: 1px 1px 1px 0px hsl(0deg 0% 0% / 11%);
}

.k-klog-footer-svg {
  z-index: 1;
  border-radius: 50%;
  width: 27px;
  height: 27px;
  backdrop-filter: blur(10px);
  background: rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-klog-pfooter p {
  font-family: "SF Pro Text";
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 10px;
  letter-spacing: -0.04px;
  text-align: center;
  margin-top: 7px;
  margin-bottom: 0;
  color: #fff;
  width: max-content;
  text-shadow: 0px 1.025px 2.5625px rgb(0 0 0 / 40%);
}

.k-klog-phelp-button {
}
.k-klog-power-buttons {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 3px);
}

.k-klog-footer-logo {
  position: relative;
  top: 8px;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(49.4917px);
  border-radius: 3rem;
  width: 100px;
  height: 37px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-klog-footer-svg {
  backdrop-filter: blur(10px);
}

.k-klog-footer-logo img {
  width: 74px;
  height: auto;
  object-fit: contain;
  position: relative;
  top: -1px;
}

/* help screen */
.k-klog-device-info {
  position: absolute;
  width: 460px;
  border-radius: 10px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, calc(-50% - 50px));
  box-shadow: 0px 1.54167px 7.70833px rgb(0 0 0 / 19%);
}

.k-klog-device-header {
  position: relative;
  height: 40px;
  background: #ffffff;
  border-radius: 10px 10px 0 0;
  display: flex;
  gap: 8px;
  padding: 0 8px;
  align-items: center;
}

.k-klog-device-btn {
  width: 12px;
  height: 12px;
  background: #e3e3e3;
  box-sizing: border-box;
  border-radius: 50%;
  border: 0.5px solid rgba(0, 0, 0, 0.12);
}

.k-klog-device-btn.--close {
  background: #ff5f57;
}

.k-klog-device-tabs,
.k-klog-device-tab {
  border-radius: 6px;
}

.k-klog-device-tab {
  position: relative;
}

.k-klog-device-tab.--with-border::after {
  content: "";
  position: absolute;
  right: 0;
  height: 13.33px;
  width: 0.65px;
  background: rgba(142, 142, 147, 0.7);
  border-radius: 0.5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.3;
}

.k-klog-device-tabs {
  background: #f5f5f5;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 12px;
  font-family: "SF Pro Text";
}

.k-klog-device-tab {
  height: 20px;
  line-height: 20px;
  min-width: 80px;
  text-align: center;
  cursor: pointer;
}

.k-klog-device-tab.--active {
  background: rgba(232, 232, 232, 0.7);
}

.k-klog-device-tab.--inactive {
  cursor: unset;
}

.k-klog-device-separator {
  height: 1px;
  background: linear-gradient(180deg, #b6b6b6, #e8e8e8);
  mix-blend-mode: normal;
}

.k-klog-device-inner {
  background: #f6f6f6;
  border-radius: 0 0 10px 10px;
  min-height: 187px;
  max-height: 450px;
}

.k-klog-device-inner > div.--hidden {
  visibility: hidden;
  position: fixed;
  top: 0;
}

.k-klog-device-inner-support {
  padding: 45px 56px 45px 48px;
  display: flex;
}

.k-klog-device-icon {
  width: 80px;
  height: 80px;
  object-fit: contain;
  margin-right: 24px;
}

.k-klog-device-inner-info {
  padding: 24px 35px 0 48px;
  display: flex;
}

.k-klog-device-title h2 {
  font-family: "SF Pro Display", sans-serif;
  font-size: 22px;
  line-height: 26px;
  color: #4d4d4d;
}

.k-klog-device-helper {
  margin-top: 8px;
}

.k-klog-device-helper p {
  font-family: "SF Pro Text", sans-serif;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.3px;
}

.k-klog-device-info-grid-wrapper {
  margin-top: 12px;
}

.k-klog-device-info-grid {
  display: grid;
  grid: auto/90px auto;
  grid-column-gap: 12px;
  margin-bottom: 4px;
}

.k-klog-device-info-grid p {
  margin: 0;
}

.k-klog-device-info-grid p:first-child {
  font-family: "SF Pro Text";
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.3px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
}

.k-klog-device-info-grid p:nth-child(2) {
  font-family: "SF Pro Text";
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.3px;
  text-align: left;
}

.k-klog-device-inner-about {
  display: flex;
  padding: 50px 70px 0 48px;
}

.k-klog-device-about__logo {
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.k-klog-device-about__title {
  font-family: "SF Pro Display", sans-serif;
  font-weight: 400;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.35px;
}

.k-klog-device-about__description {
  margin-top: 12px;
  font-family: "SF Pro Text", sans-serif;
}

.k-klog-device-about__description > p {
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 4px;
  letter-spacing: -0.3px;
}

.k-klog-device-inner-support__main {
  flex: 1;
  max-height: 360px;
  overflow: auto;
}
