/* =========================== */
/* ==========!GUTTER========== */
/* =========================== */

/* ----------!BUILD-[create a dynamic function for this]--------- */
.gutter-0 {
  margin: 0;
}
.gutter-0 > * {
  padding: 0;
}
.gutter-10 {
  margin: -5px;
}
.gutter-10 > * {
  padding: 5px;
}
.gutter-20 {
  margin: -10px;
}
.gutter-20 > * {
  padding: 10px;
}
.gutter-30 {
  margin: -15px;
}
.gutter-30 > * {
  padding: 15px;
}
.gutter-40 {
  margin: -20px;
}
.gutter-40 > * {
  padding: 20px;
}
.gutter-50 {
  margin: -25px;
}
.gutter-50 > * {
  padding: 25px;
}
.gutter-80 {
  margin: -40px;
}
.gutter-80 > * {
  padding: 40px;
}
.gutter-130 {
  margin: -65px;
}
.gutter-130 > * {
  padding: 65px;
}
@include media-breakpoint-up(sm) {
  .gutter-sm-0 {
    margin: 0;
  }
  .gutter-sm-0 > * {
    padding: 0;
  }
  .gutter-sm-10 {
    margin: -5px;
  }
  .gutter-sm-10 > * {
    padding: 5px;
  }
  .gutter-sm-20 {
    margin: -10px;
  }
  .gutter-sm-20 > * {
    padding: 10px;
  }
  .gutter-sm-30 {
    margin: -15px;
  }
  .gutter-sm-30 > * {
    padding: 15px;
  }
  .gutter-sm-50 {
    margin: -25px;
  }
  .gutter-sm-50 > * {
    padding: 25px;
  }
  .gutter-sm-80 {
    margin: -40px;
  }
  .gutter-sm-80 > * {
    padding: 40px;
  }
  .gutter-sm-130 {
    margin: -65px;
  }
  .gutter-sm-130 > * {
    padding: 65px;
  }
}
@include media-breakpoint-up(md) {
  .gutter-md-0 {
    margin: 0;
  }
  .gutter-md-0 > * {
    padding: 0;
  }
  .gutter-md-10 {
    margin: -5px;
  }
  .gutter-md-10 > * {
    padding: 5px;
  }
  .gutter-md-20 {
    margin: -10px;
  }
  .gutter-md-20 > * {
    padding: 10px;
  }
  .gutter-md-30 {
    margin: -15px;
  }
  .gutter-md-30 > * {
    padding: 15px;
  }
  .gutter-md-50 {
    margin: -25px;
  }
  .gutter-md-50 > * {
    padding: 25px;
  }
  .gutter-md-80 {
    margin: -40px;
  }
  .gutter-md-80 > * {
    padding: 40px;
  }
  .gutter-md-130 {
    margin: -65px;
  }
  .gutter-md-130 > * {
    padding: 65px;
  }
}
@include media-breakpoint-up(lg) {
  .gutter-lg-0 {
    margin: 0;
  }
  .gutter-lg-0 > * {
    padding: 0;
  }
  .gutter-lg-10 {
    margin: -5px;
  }
  .gutter-lg-10 > * {
    padding: 5px;
  }
  .gutter-lg-20 {
    margin: -10px;
  }
  .gutter-lg-20 > * {
    padding: 10px;
  }
  .gutter-lg-30 {
    margin: -15px;
  }
  .gutter-lg-30 > * {
    padding: 15px;
  }
  .gutter-lg-50 {
    margin: -25px;
  }
  .gutter-lg-50 > * {
    padding: 25px;
  }
  .gutter-lg-80 {
    margin: -40px;
  }
  .gutter-lg-80 > * {
    padding: 40px;
  }
  .gutter-lg-130 {
    margin: -65px;
  }
  .gutter-lg-130 > * {
    padding: 65px;
  }
}

/* ----------!HORIZONTAL--------- */
.gutter-60-horizontal {
  margin-left: -30px;
  margin-right: -30px;
}
.gutter-60-horizontal > * {
  padding-left: 30px;
  padding-right: 30px;
}

/*.library .gutter-60-horizontal > .col-auto {
  padding-left: 6px;
}*/
.gutter-130-horizontal {
  margin-left: -65px;
  margin-right: -65px;
}
.gutter-130-horizontal > * {
  padding-left: 65px;
  padding-right: 65px;
}
