@mixin elementState($color: 'primary') {
  &:focus:enabled,
  &:hover:enabled,
  &:active:enabled {
    @if $color == 'primary' {
      background-color: #fff !important;
      border-color: $darkest-blue !important;
      outline: 0 !important;
    } @else {
      background-color: #fff !important;
      border-color: $darkest-blue !important;
      outline: 0 !important;
    }
  }
}
