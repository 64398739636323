/* =============================== */
/* ==========!ASSIGNMENT========== */
/* =============================== */

$assignment-blueprint-height: 260px;

/* ----------!TOGGLE---------- */
.assignment .dropdown .dropdown-toggle:disabled {
  cursor: not-allowed;
}

/* ----------!LIST---------- */
.assignment .dropdown .dropdown-toggle .field-list-wrap {
  background-color: $color-white;
  border: $border-width-button-solid solid $color-brand-marengo-light;
  border-radius: $border-radius-dull;
  padding: 8px;
  min-height: 40px;
}
.assignment .dropdown .dropdown-toggle .field-list-wrap:hover,
.assignment .dropdown .dropdown-toggle .field-list-wrap:focus {
  border-color: $color-brand-marengo-medium;
}
.assignment .dropdown.show .dropdown-toggle .field-list-wrap {
  border-color: $color-system-link;
}

/* ----------!BLUEPRINT---------- */
@include media-breakpoint-up(xl) {
  .assignment-blueprint .dropdown {
    min-width: 415px;
    width: fit-content;
  }
}
.assignment-blueprint .dropdown .dropdown-menu .dropdown-menu-body {
  max-height: $assignment-blueprint-height;
}
.assignment-blueprint .dropdown .dropdown-menu .dropdown-menu-fallback > p {
  width: 100%;
  height: $assignment-blueprint-height;
}
