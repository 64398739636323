.b-select-search.b-select__icon.b-select__icon--search {
  color: var(--color-neutral-60);
}

.b-select-search__value--disabled {
  color: var(--color-neutral-60);
}

.b-select-search--error .b-select__icon {
  color: var(--color-red-50);
}

.b-select-multi__value-container > .b-select-search__value:first-child {
  margin-left: var(--b-gap-tiny);
}

.custom-select-search .b-select-multi-menu {
  box-shadow: none;
}
