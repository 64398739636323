.parameter-info {
  margin-bottom: 30px;
  div:last-child {
    font-weight: 100;
  }
}
.parameters-wrapper {
  margin: 25px;
}

.parameter-wrapper {
  margin: 20px 45px 35px 0;
}
