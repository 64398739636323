/* .typing-indicator {
    background-color: #262628;
    width: auto;
    border-radius: 8px;
    padding: 4px 12px 6px 12px;
    display: flex;
    align-items: center;
    margin: 0 auto;
    position: relative;
    gap: 4px;
} */

.typing-indicator {
    background-color: #262628;
    display: flex;
    padding: 10px 16px 10px 16px;
    align-items: flex-start;
    gap: 4px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(15, 19, 23, 0.16);
    position: relative;
}

/* 
.typing-indicator::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 15px solid transparent;
    border-top-color: #262628;
    border-bottom: 0;
    margin-left: -15px;
    margin-bottom: -15px;
} */

/* .typing-indicator::before,
.typing-indicator::after {
    content: "";
    position: absolute;
    bottom: -8px;
    left: -8px;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background-color: #262628;
}

.typing-indicator::after {
    height: 10px;
    width: 10px;
    left: -10px;
    bottom: -10px;
} */

.typing-indicator span {
    height: 6px;
    width: 6px;
    background-color: #FFFFFF;
    border-radius: 50%;
    opacity: 0.4;
}

.typing-indicator span:nth-of-type(1) {
    -webkit-animation: 1s blink infinite 0.3333s;
    animation: 1s blink infinite 0.3333s;
}

.typing-indicator span:nth-of-type(2) {
    -webkit-animation: 1s blink infinite 0.6666s;
    animation: 1s blink infinite 0.6666s;
}

.typing-indicator span:nth-of-type(3) {
    -webkit-animation: 1s blink infinite 0.9999s;
    animation: 1s blink infinite 0.9999s;
}

@-webkit-keyframes blink {
    50% {
        opacity: 1;
    }
}

@keyframes blink {
    50% {
        opacity: 1;
    }
}