.outerBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.innerBox {
  min-height: 650px;
  width: 100%;
  display: flex;
  flex: 1;
  padding: 24px;
  border-radius: 4px;
  border: 0.5px solid #d7e1ed;
  background: white;
  gap: 24px;
  align-items: center;
  flex-direction: column;
}

.bx-txt {
  size: 4px;
}

.switcher-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.device-container {
  width: 100%;
  justify-content: center;
  display: flex;
  height: 100%;
  flex: 1;
}

.outerBox .top-description {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.outerBox .the-recommended {
  font-family: 'Untitled Sans', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.helper-text-container {
  color: var(--color-red-50);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.helperText {
  flex: 1;
  text-align: center;
  color: #7b8ba3;
  font-size: 12px;
  font-family: Untitled Sans;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
  margin: 0;

  &.disabled {
    color: var(--color-red-50);
  }
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.device-frames {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 50px;

  .frame {
  }

}

.wide-text-input {
  width: 308px;
}

.apply-link-container {
  padding-top: 9px;
  height: 30px;
  overflow: visible;
}

