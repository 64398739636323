.wifi-adcs-drawer-content .b-library__setting-row {
  display: grid;
  grid-template-areas:
    'title helper'
    'controls helper'
    'secondary-controls secondary-controls';
}

.wifi-adcs-drawer-content .b-library__setting-title {
  grid-area: title;
}

.wifi-adcs-drawer-content .b-library__setting-helpers {
  grid-area: helper !important;
}

.wifi-adcs-drawer-content .b-library__setting-controls {
  grid-area: controls;
}

@media (max-width: 1440px) {
  .wifi-adcs-drawer-content .b-library__setting-row {
    display: grid;
    grid-template-areas:
      'title'
      'helper'
      'controls'
      'secondary-controls';
    grid-template-columns: 1fr;
  }

  .wifi-adcs-drawer-content .b-library__setting-secondary-controls {
    margin-top: 0 !important;
  }

  .wifi-adcs-drawer-content .b-library__setting-helpers {
    margin-bottom: var(--b-gap2);
  }

  .b-library-second-ctrl-row-helper:last-child {
    margin-bottom: 0 !important;
  }
}

.k-wifi-li__san-row > button {
  margin-top: var(--b-gap3);
}

.k-wifi-li__san-inputs {
  width: 760px;
  margin-bottom: var(--b-gap1);
}
