.kandji-sidebar {
  --sidebar-closed-width: 77px;
  --sidebar-opened-width: 256px;

  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  overflow: visible !important;
  background: #1b1f29;
  padding-top: 0 !important;
  height: 100vh !important;
  z-index: 2000 !important;
  width: min-content !important;
  min-width: var(--sidebar-closed-width) !important;
}

.kandji-sidebar.kandji-sidebar--over {
  z-index: 2010 !important;
}

.kandji-sidebar.kandji-sidebar--opened {
  min-width: var(--sidebar-opened-width) !important;
}

.kandji-sidebar.kandji-sidebar--opened .kandji-sidebar__sidebar {
  min-width: var(--sidebar-opened-width) !important;
  width: min-content;
}

kandji-sidebar--opened .kandji-sidebar.kandji-sidebar--below-dialog {
  z-index: 998 !important;
}

.kandji-sidebar .kandji-sidebar__alert-badge {
  padding: 3px 9px;
  background-color: var(--color-blue-50);
  color: var(--color-neutral-0);
  border-radius: 35px;
  text-align: center;
  letter-spacing: normal;
  height: 22px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.kandji-sidebar .kandji-sidebar__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 170px;
  width: 100%;
}

.kandji-sidebar .kandji-sidebar__item-label {
  margin-left: var(--space-3);
  padding-right: 6px;
  font-weight: 500;
}

.kandji-sidebar .logo {
  cursor: pointer;
}

.kandji-sidebar .logo {
  cursor: pointer;
}
.kandji-sidebar .logo-text svg {
  height: 30px;
  width: 120px;
  margin-top: 40px;
  margin-left: 26px;
}

.kandji-sidebar .logo-icon svg {
  height: 24px;
  width: 35px;
  margin-top: 40px;
  margin-left: 22px;
}

.kandji-sidebar__sidebar {
  z-index: 2000 !important;
  position: absolute !important;
  width: 100%;
  height: 100%;
  bottom: 0 !important;
  transition: transform 0.3s ease-out, width 0.15s, box-shadow 0.5s !important;
  -webkit-transition: visibility 1s, box-shadow 0.5s !important;
  will-change: transform !important;
  overflow-y: visible !important;
  padding-top: 0 !important;
}

.kandji-sidebar__sidebar.kandji-sidebar__sidebar--top-blocked {
  padding-top: 40px !important;
}
.kandji-sidebar__sidebar.kandji-sidebar__sidebar--over {
  box-shadow: 15px 0px 25px rgba(26, 29, 37, 0.2);
}

.kandji-sidebar__content {
  z-index: 2002;
  position: absolute !important;
  top: 15px !important;
  height: 0 !important;
  left: 20px !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 25px !important;
  overflow-y: auto !important;
  -webkit-overflow-scrolling: touch !important;
  transition: left 0.3s ease-out, right 0.3s ease-out !important;
}

.kandji-sidebar__overlay {
  z-index: -1 !important;
  position: absolute !important;
  top: 65px !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  opacity: 0 !important;
  visibility: hidden !important;
  transition: opacity 0.3s ease-out, visibility 0.3s ease-out !important;
  background-color: transparent !important;
}

.kandji-sidebar__dropdown {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
}

.kandji-sidebar__tooltip.show {
  opacity: 1;
  padding-left: 14px;
}

.kandji-sidebar__tooltip.--with-scrollbar {
  padding-left: 18px;
}

.kandji-sidebar__tooltip > .tooltip-inner {
  font-family: var(--font-family-primary);
  box-shadow: none;
  background-color: #1b1f29;
  color: #ffffff;
  font-size: 13px;
  padding: 5px 12px;
}

.kandji-sidebar__tooltip > .arrow {
  left: 10px;
}

.kandji-sidebar__tooltip.--with-scrollbar > .arrow {
  left: 14px;
}

.kandji-sidebar__tooltip > .arrow::before {
  border-right-color: #1b1f29;
}
