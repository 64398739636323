@import 'anchor';

.kandji-select-container {
  min-width: 360px;
  width: fit-content;

  &.kandji-select--fit {
    min-width: 0;
    width: 100%;
    max-width: 360px;
  }

  &.kandji-select__error {
    .kandji-select__control {
      border-color: $color-system-error;
    }
    .kandji-select__dropdown-indicator {
      fill: $color-system-error;
    }
  }
  .kandji-select__input {
    padding-left: $space-input;
  }

  /* control */
  .kandji-select__control {
    height: 44px;
    border-radius: 4px;
    border-width: 2px;
    border-color: rgba(26, 29, 37, 0.15);
    cursor: pointer;

    &.kandji-select__control--is-focused,
    &.kandji-select__control--is-focused:hover {
      border-color: $color-system-link-active;
    }
    &.kandji-select__control--is-disabled {
      border-color: rgba(26, 29, 37, 0.05);
      background: unset;
      cursor: not-allowed;

      .kandji-select__placeholder {
        color: rgba(26, 29, 37, 0.15);
      }
    }
    &:hover {
      border-color: rgba(26, 29, 37, 0.4);
    }
    &.kandji-select__control--menu-is-open {
      .kandji-select__dropdown-indicator {
        transform: rotate(180deg);
      }
    }

    .kandji-select__placeholder {
      color: rgba(26, 29, 37, 0.4);
      margin: 0 12px;
    }
  }

  /* value */
  .kandji-select__value-container {
    padding: 0;
  }
  .kandji-select__single-value {
    margin: 0 12px;
  }

  /* indicator */
  .kandji-select__dropdown-indicator {
    transition: all 0.2s ease-in-out;
    margin-right: 17px;
    fill: #1a1d25;

    &.kandji-select__dropdown-indicator--is-disabled {
      fill: rgba(26, 29, 37, 0.15);
    }
    &.kandji-select__dropdown-indicator--is-focused {
      fill: $color-system-link-active;
    }
  }
}

/* menu */
$itemsInMenu: 5;
$menuPadding: 12px;
$optionHeight: 40px;

.kandji-select__menu.kandji-select__menu {
  z-index: $anchor-z-index + 1;
  font-family: var(--font-family-primary);
  font-size: 13px;
}

.kandji-select__menu-list.kandji-select__menu-list {
  @include prettyScroll();
  padding-bottom: $menuPadding;
  padding-top: $menuPadding;
  max-height: $itemsInMenu * $optionHeight + 2 * $menuPadding;
  color: $color-brand-marengo-ultradark;
}

.kandji-select__option.kandji-select__option {
  min-height: $optionHeight;
  cursor: pointer;
  padding: 10px 12px;

  &:hover,
  &.kandji-select__option--is-focused {
    background-color: $color-grey-1;
  }
  &.kandji-select__option--is-selected {
    background-color: $color-system-link-active;
  }
}

.kandji-select__error-label.kandji-select__error-label {
  display: flex;
  margin-top: 8px;
  padding-left: 3px;
  align-items: center;
  color: $color-system-error;
}
