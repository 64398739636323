body.side-panel-body {
  --sidePanelTransition: transform 400ms cubic-bezier(0.1, 0.96, 0.41, 0.96);
}

body .side-panel .side-panel-backdrop {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

body.side-panel-body.side-panel-body-open .side-panel .side-panel-backdrop {
  opacity: .5;
}

body .side-panel .side-panel-content {
  transform: translateX(calc(max(60vw, 814px)));
  transition: var(--sidePanelTransition);
}

body.side-panel-body.side-panel-body-open .side-panel-content {
  transform: translateX(0);
}

body.side-panel-body #fc_frame {
  transition: var(--sidePanelTransition);
}

body.side-panel-body.side-panel-body-open #fc_frame {
  transform: translateX(calc(max(60vw, 814px) * -1));
}
