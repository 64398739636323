.blueprint-element {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  width: 100%;
  //height: 100%;
  min-width: 265px;
  min-height: 240px;
  background-color: white;
  border-radius: 5px;
  padding: 1rem;
  margin-bottom: 0;
  //border: 0.05rem solid $grey-200;
  cursor: pointer;
  box-shadow: 0 4px 3px 0 $grey-300-transparent-300;
  //box-shadow: 0 15px 5px -10px $grey-200-transparent-300;
  transition:
    0.3s cubic-bezier(0, 1, 0.5, 1),
    box-shadow 0.5s ease;
  &:hover {
    transform: translateY(-1px);
    box-shadow:
      0 18px 35px rgba(50, 50, 93, 0.1),
      0 8px 15px rgba(0, 0, 0, 0.07);
    //box-shadow: 0 10px 20px 0 $grey-300-transparent-300;
    //transform: scale(1.05, 1.05);
  }
  &:active {
    background: $grey-50;
    transform: translateY(2px);
    box-shadow:
      0 11px 20px rgba(50, 50, 93, 0.1),
      0 6px 11px rgba(0, 0, 0, 0.08);
  }
  .be-top-part {
    display: flex;
    justify-content: space-between;
    //height: 55%;
    width: 100%;
    .blueprint-actions-button {
      margin-top: -16px;
      margin-right: -16px;
      height: 40px;
      width: 60px;
    }
  }
  .be-bottom-part {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    //height: 45%;
    width: 100%;
    p {
      font-size: 0.9rem;
      color: $grey-400;
      //margin-bottom: 0.2rem;
      margin-bottom: 0;
      i {
        margin-right: 0.2rem;
      }
    }
    .blueprint-title {
      @extend .cut-long-text;
      font-size: 16px;
      color: $grey-999;
      font-weight: 500;
    }
    .blueprint-description {
      display: -moz-box;
      display: -webkit-box;
      font-family: var(--font-family-primary);
      font-size: 14px;
      line-height: 21px;
      max-height: 45px;
      color: $grey-400;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      @extend .cut-long-text;
      white-space: normal;
    }
    .blueprint-counts {
      max-height: 35px;
      height: 35px;
      border-top: 1px solid $grey-150;
      font-family: var(--font-family-primary);
      font-size: 14px;
      color: $grey-400;
    }
    div:first-child {
      width: 100%;
      height: 60%;
      padding-bottom: 0.6rem;
    }
    div:nth-child(2) {
      height: 40%;
      padding-top: 0.6rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}

@media (max-width: 1000px) {
  .blueprint-element {
    //width: 350px;
  }
}
