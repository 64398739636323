.li-passport__login-help {
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: #fff;
  box-shadow:
    0px 0px 1.45455px rgb(0 0 0 / 25%),
    inset 0px 0px 0px 0.145455px rgb(0 0 0 / 25%);
}

.li-passport__login-help > img {
  height: 11px;
  width: 11px;
}

.li-passport__login-help__txt {
  text-align: center;
}

.li-passport__web-login-tab,
.li-passport__web-login-tab > img {
  position: relative;
  height: 352px;
  width: 296px;
}

.li-passport__web-login-tab-helper {
  position: absolute;
  width: 256px;
  left: 20px;
  bottom: 20px;
  padding: 8px;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: center;
}

.li-passport__login-bg-uploader img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  padding: 4px;
  border-radius: 8px;
}
