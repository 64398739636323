/* =========================== */
/* ==========!SF-PRO========== */
/* =========================== */

/* ----------!DISPLAY---------- */
@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/sf-pro/SF-Pro-Display-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/sf-pro/SF-Pro-Display-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/sf-pro/SF-Pro-Display-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'SF Pro Display';
  src: url('../fonts/sf-pro/SF-Pro-Display-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

/* ----------!TEXT---------- */
@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/sf-pro/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/sf-pro/SF-Pro-Text-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/sf-pro/SF-Pro-Text-Semibold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}
@font-face {
  font-family: 'SF Pro Text';
  src: url('../fonts/sf-pro/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}
