/* ========================= */
/* ==========!FORM========== */
/* ========================= */

/* ----------!INPUT---------- */
@mixin styleInput() {
  background-color: $color-white;
  border: 2px solid $color-brand-marengo-light;
  border-radius: 4px;
  color: $color-brand-marengo-ultradark;
  opacity: 1;
  outline: none;
}
@mixin styleInputSize() {
  padding: 0 $space-input;
  width: 100%;
  height: 44px;
  line-height: 25px;
}
@mixin styleInputDisabled() {
  border-color: $color-brand-marengo-ultralight;
  color: $color-brand-marengo-dark;
  opacity: 1;
  cursor: not-allowed;
}

/* =========================== */
/* ==========!CUSTOM========== */
/* =========================== */

/* ----------!SCROLL---------- */
@mixin prettyScroll($scrollWidth: 8, $scrollColor: $color-grey-5) {
  &::-webkit-scrollbar {
    width: #{$scrollWidth}px;
  }
  &::-webkit-scrollbar-track {
    background: $color-brand-marengo-ultralight;
    border-radius: #{$scrollWidth}px;
  }
  &::-webkit-scrollbar-thumb {
    background: $scrollColor;
    border-radius: #{$scrollWidth}px;
  }
}

/* ----------!TRUNCATE---------- */
@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
