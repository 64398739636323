.k-ssh-gc-helper {
  padding-left: var(--b-gap3);
}

.k-ssh-gc-group-btn {
  border: 2px solid var(--color-neutral-10);
  background-color: var(--color-neutral-10);
  font-family: var(--font-family-primary);
  font-size: 14px;
  box-sizing: border-box;
  width: 112px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  color: var(--color-neutral-60);
}
.k-ssh-gc-group-btn.--active {
  background: white;
  color: var(--color-neutral-100);
  cursor: default;
}
.k-ssh-gc-group-btn:first-child {
  border-radius: 4px 0 0 4px;
}
.k-ssh-gc-group-btn:last-child {
  border-radius: 0 4px 4px 0;
}
.k-ssh-gc-group-btn:focus {
  outline: none;
}

.k-ssh-gc-secondary-block-row {
  display: block;
  margin-top: var(--b-gap2);
}
.k-ssh-gc-compact-input {
  width: 100%;
}

.k-ssh-value-row .b-library__setting-helpers.b-library__setting-helpers {
  grid-area: unset;
}
.k-ssh-value-row .b-txt--error {
  width: max-content;
}

.k-ssh-access-col {
  width: 360px;
}
.k-ssh-access-items-container {
  max-height: 350px;
  overflow: auto;
  padding-bottom: 8px;
}
.k-ssh-access-item-row + .k-ssh-access-item-row {
  margin-top: var(--b-gap2);
}

.k-ssh-access-item-row .b-txt-input__err-msg {
  position: static;
  margin-top: var(--b-gap-tiny);
}

.k-ssh-access-item-row .b-select__err-msg {
  margin-bottom: 0;
}
