// Global
@import 'control';
@import 'break';
@import 'mixins';

// Main
@import 'gutter';
@import 'grid';

// Setup
@import 'basic';
@import 'font';

// Unit
@import 'area';
@import 'banner';
@import 'bar';
@import 'chip';
@import 'dayPicker';
@import 'dropdown';
@import 'icon';
@import 'loader';
@import 'menu';
@import 'modal';
@import 'multiRow';
@import 'offcanvas';
@import 'paper';
@import 'screensaver';
@import 'screensaverModal';
@import 'select';
@import 'shroud';
@import 'slider';
@import 'tab';
@import 'table';
@import 'view';

// Widget
@import 'assignment';
@import 'avatar';
@import 'breadcrumb';
@import 'environment';
// @import 'scrollbar';
@import 'sticky';
@import 'toggle';

// Section
@import 'anchor';
@import 'information';

// Style
@import 'action';
@import 'form';
@import 'navigation';
@import 'script';
@import 'space';
@import 'text';

// Widgets with style dependancies
@import 'selfServiceTable';

// Rework
.block > .zone > .container-fluid {
  padding: 0 !important;
}

.anchor > .dock > .case > .container-fluid {
  padding-left: 40px !important;
  padding-right: 24px !important;
}

// Delete
.theme-base {
  font-family: $font-family-text;
  font-weight: $font-weight-text;
  font-size: $font-size-text;
  line-height: $line-height-text;
  color: $color-brand-marengo-dark;

  .regular-text {
    color: $color-brand-marengo-ultradark;
  }
}

// Disables Microsoft Edge's password reveal eye from password fields
::-ms-reveal {
  display: none;
}
