/* ================================ */
/* ==========!ENVIRONMENT========== */
/* ================================ */

/* ----------!MAIN---------- */
.environment,
.environment strong {
  position: fixed;
  top: 0;
  right: 0;
}
.environment {
  border-top: 40px solid transparent;
  border-left: 40px solid transparent;
  width: 0;
  height: 0;

  z-index: 9999;
  pointer-events: none;
}
.environment strong {
  padding: 5px;
  font-size: 10px;
  line-height: 10px;
  color: #fff;
}
.environment-dev {
  border-top-color: orange;
}
.environment-qa {
  border-top-color: mediumseagreen;
}
.environment-stage {
  border-top-color: steelblue;
}

.environment__fast-refresh-icon-container {
  position: relative;
}

.environment__fast-refresh {
  display: none;
  position: fixed;
  top: 3px;
  right: 1px;
  height: 20px;
  width: 20px;
  z-index: 5;

  &.environment__fast-refresh--loading {
    display: block;
  }
}

.environment__fast-refresh-env-char {
  position: absolute;
  top: 7px;
  right: 14px;
  z-index: 5;
}

.environment__fast-refresh-icon {
  position: absolute;
  top: 9px;
  right: -4px;
  font-size: 22px;
  z-index: -1;
}

.environment__fast-refresh-loading-icon {
  position: absolute;

  &#{&}--animate-spin {
    animation: fast-refresh-loading-spin 300ms linear infinite;
    position: absolute;
  }

  #{&}-circle-bg {
    stroke: white;
    opacity: 0.25;
  }

  #{&}-circle-fill {
    fill: white;
  }

  @keyframes fast-refresh-loading-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
