/* ========================= */
/* ==========!GRID========== */
/* ========================= */

/* ----------!COLUMN---------- */
@media (min-width: 1440px) {
  .col-hg {
    flex-basis: 0;
    flex-grow: 1;
    min-width: 0;
    max-width: 100%;
  }
  .col-hg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
  }
  .col-hg-7 {
    max-width: 58.33333%;
    flex: 0 0 58.33333%;
  }
}
