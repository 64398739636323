@import '../../../../../../assets/styles/base/variables';

.awesome-table-row-row-wrapper {
  display: flex;
  flex-direction: column;
  .awesome-table-row-row {
    display: flex;
    &:hover {
      background-color: $grey-80;
    }
    .awesome-table-row-cell {
      display: flex;
      align-items: center;
      justify-content: left;
      padding-left: 20px;
      min-height: 59px;
      border-top: 1px solid $grey-130;
      //border-right: 1px solid $grey-130;
      font-size: 14px;
      font-weight: 400;
      color: $grey-500;
      font-family: var(--font-family-primary);
      //width: ${width};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &:last-child {
        border-right: none;
      }
    }
    .right-bordered {
      border-right: 1px solid $grey-130;
    }
    .expanded {
      background-color: $grey-80;
      border-right: none;
    }
    .cursor-pointer {
      cursor: pointer;
    }
  }
  .awesome-table-row-row-expand {
    width: 100%;
    height: 100%;
    padding: 10px 0 10px 79px;
    font-size: 13px;
    font-family: var(--font-family-primary);
    background-color: $grey-80;
  }
}
