.search-test {
  padding-top: 40px;

  h1 {
    margin-bottom: 20px;
  }

  input {
    margin-bottom: 20px;
  }

  .search-results {
    background-color: white;
    padding: 20px;
    display: flex;
    gap: 10px;

    h2 {
      font-size: 20px;
      font-weight: bold;
    }

    .search-result {
      //border: 1px solid black;
      //border-bottom: none;
      min-height: 50px;
      min-width: 200px;

      a {
        display: flex;
        flex-direction: row;
        text-decoration: none;
        width: 100%;
        height: 100%;

        &:hover {
          background-color: #eee;
        }

        .search-result-icon {
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .search-result-content {
          padding: 2px 10px 2px 0;
          display: flex;
          flex-direction: column;

          h2 {
            font-weight: bold;
            color: #333;
            font-size: 14px;
          }
        }
      }

      &.library_item {
        background-color: #eef;
      }

      &.device {
        background-color: #eff;

        .device-image {
          width: 30px;
          height: 30px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      &.blueprint {
        background-color: #ffe;
      }

      &.device_user {
        background-color: #efe;

        .device-user-photo {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .device-user-no-photo {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          border: 1px solid gray;
          overflow: hidden;
          background-color: white;
          display: flex;
          justify-content: center;
          align-items: center;

          i {
            font-size: 20px;
          }
        }
      }
    }
  }
}
