.enforcement-delay-wrapper {
  display: flex;
  align-items: center;
  max-width: 360px;
  width: 100%;

  & > div {
    &:last-child {
      padding-left: var(--b-gap);
    }

    &:first-child {
      max-width: 165px;
      width: 165px;
    }
  }
}
