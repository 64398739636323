@import '../../../../../../assets/styles/base/variables';

.awesome-header-wrapper {
  height: 47px;
  display: flex;
  .awesome-draggable-header-wrapper {
    display: flex;
    flex: 1;
  }
  .awesome-table-header {
    display: flex;
    white-space: nowrap;
    justify-content: left;
    align-items: center;
    padding-left: 20px;
    position: relative;
    text-align: center;
    background-color: $white;
    //border-bottom: 1px solid $grey-130;
    overflow: hidden;
    //border-right: 1px solid $grey-130;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    color: $grey-500;
    //font-family: "Sharp Sans";
    font-weight: bold;
    &:first-child {
      border-top-left-radius: 5px;
    }
    &:last-child {
      border-right: none;
      border-top-right-radius: 5px;
    }
    &.undraggable {
      border-right: 1px solid $grey-130;
    }
    &.is-dragging {
      //background-color: $grey-280;
    }
    .dnd-icon {
      color: #afb2b8;
      margin-right: 6px;
      white-space: nowrap;
    }
    .sort-icon-wrapper {
      margin-left: 5px;
    }
  }
}
