//input {
//  @include elementState();
//}

.checkbox-container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: white;
  margin: 0;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
}

/* Create a custom checkbox */
.checkmark {
  //position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 0.75rem;
  background-color: #fff;
  border: 1px solid $grey-300;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input ~ .checkmark {
  //background-color: rgba(48, 135, 224, 0.63);
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked ~ .checkmark {
  //background-color: $darkest-blue;
  //border-color: $darkest-blue;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '\f00c';
  position: absolute;
  display: none;
  color: #fff;
  background-color: $marengo-700;
  width: 20px;
  height: 20px;
  border-radius: 0.75rem;
  font-family: 'Font Awesome 5 Pro', sans-serif;
  font-weight: 700;
  padding-top: 10%;
  font-size: 80%;
  letter-spacing: 0;
}

/* Show the checkmark when checked */
.checkbox-container input:checked ~ .checkmark:after {
  display: block;
}

.icon-table {
  display: block;
  font-size: 20px;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.timezone-picker {
  display: inline-block;
  position: relative;
  &.open {
    .picker-list {
      display: block;
    }
  }
  .picker-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 20px;
    max-height: 200px;
    margin: 2px 0 0;
    padding: 0;
    border: 1px solid #e6ebec;
    border-radius: 0 0 3px 3px;
    display: none;
    overflow-y: scroll;
    .timezone-picker-list-item {
      background-color: #fff;
      padding: 5px 12px;
      cursor: pointer;
      outline: none;
      display: block;
      border: 0;
      width: 100%;
      text-align: left;
      border-radius: 0;
      font-size: 14px;
      font-weight: 400;
      &.active {
        background: $light-grey;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: $light-grey;
    }
    &::-webkit-scrollbar {
      width: 6px;
      background-color: $light-grey;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $darkest-blue;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: $darkest-blue;
    }
  }
}

.disk-media-title-row:first-child {
  .disk-media-title {
    margin-top: 0.5rem;
  }
}

.custom-group {
  width: 33%;
}

.new-email-field {
  box-shadow: none;
  border: none;
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;

  &:focus,
  &:hover,
  &:active {
    box-shadow: none;
  }
}

.icon-in-input {
  position: absolute;
  right: 15px;
  margin-left: 5px;
  height: 38px;
  display: flex;
  align-items: center;
  z-index: 1999;
}

.label-above-bottom-bordered-form-group {
  label {
    font-size: 1rem;
    font-weight: 500;
  }
}

.bottom-border-input {
  width: auto;
  outline: none;
  box-shadow: none;
  border: none;
  border-bottom: 2px solid #ccc;
  background-color: inherit;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 500;
  &:hover,
  &:focus {
    box-shadow: none;
  }
}

.form-group-disabled {
  color: $btn-link-disabled-color;
  input {
    color: $btn-link-disabled-color;
    background: transparent !important;
  }
}
