.screensaver-modal-content {
  display: flex;
  min-height: 100vh;
  height: 100vh;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  padding: 40px 32px;

  color: $color-brand-marengo-ultradark;
}

.screensaver-modal-content {
  .close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    cursor: pointer;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before,
  .close:after {
    position: absolute;
    left: 16px;
    content: ' ';
    height: 24px;
    width: 2px;
    background-color: $color-brand-marengo-ultradark;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
}

.screensaver-modal-content .header {
  .title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    font-family: var(--font-family-primary);
    margin-bottom: 25px;
  }
}

.screensaver-modal-content .content {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 0;

  max-width: 384px;
}

.screensaver-modal-content .footer {
  display: flex;
  justify-content: space-between;
}

.screensaver-modal-content .restore-defaults {
  cursor: pointer;
  font-weight: 700;
  font-family: var(--font-family-primary);
  color: $color-brand-marengo-medium;
}

.screensaver-modal-content .screensaver-option {
  text-align: center;
  padding: 7px;

  &.selected {
    background-color: #eef0f6;
    border-radius: 4px;
  }
}

.screensaver-modal-content .screensaver-option img {
  width: 160px;
  height: 100px;
  cursor: pointer;
}

.screensaver-modal-content .custom {
  border-top: solid 1px $color-grey-2;
  width: 100%;
  padding: 10px 0 50px 0;

  input {
    width: 100%;
    outline: none;
    background: $color-white;
    border: 2px solid $color-grey-2;
    border-radius: 4px;
    padding: 12px 15px 10px;
    font-size: 1rem;
    color: $color-brand-marengo-ultradark;

    &::placeholder {
      color: $color-brand-marengo-medium;
    }
  }

  p {
    padding: 20px 0;
  }
}
