.filter-input {
  border-radius: 4px;
  border: 1px solid #ccc;
  height: 36px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  outline: none;
  width: 200px;
  ::-webkit-input-placeholder {
    color: #aaa;
  }
  :-moz-placeholder {
    color: #aaa;
  }
}

.DayPicker.filter-date-custom-range {
  font-size: 0.9em;
}
.DayPicker-Day,
.DayPicker-Weekday {
  padding: 0.25em;
}
