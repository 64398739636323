.expanded-activity-row {
  background-color: rgba(26, 29, 37, 0.02);
}
.expanded-activity-row__item {
  display: flex;
  align-items: center;
  border-top: var(--border);
}
.expanded-activity-row__label {
  padding: var(--b-gap) var(--b-gap2) var(--b-gap) 80px;
  width: 360px;
  min-width: 360px;
  color: var(--color-neutral-60);
}
.expanded-activity-row__values {
  padding: var(--b-gap) var(--b-gap2);
}
