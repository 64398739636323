@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-BoldItalic.woff2') format('woff2');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-BlackItalic.woff2') format('woff2');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-Black.woff2') format('woff2');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-LightItalic.woff2') format('woff2');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-RegularItalic.woff2') format('woff2');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-MediumItalic.woff2') format('woff2');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Untitled Sans';
    src: url('./untitled-sans/UntitledSans-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
}