/* ----- BASE ----- */
.theme-base {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 25px;
  color: #1a1d2599;
}

/* ----- BLOCK ----- */
.block > .zone {
  padding-top: 40px;
}
.block > .zone > .container-fluid {
  padding: 0 !important;
}

/* ----- PAPER ----- */
.paper,
.paper > .sticky .sticky-background {
  background-color: #fff;
  border-radius: 4px;
}
.paper > .sticky .sticky-background,
.paper > .sticky .sticky-background > .node {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.paper > .sticky .sticky-border {
  border-bottom: 1px solid #1a1d250d;
}
.paper .node {
  padding: 20px;
}
.paper .paper-block {
  padding-bottom: 20px;
}
.paper .paper-title {
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #1a1d25ff;
  display: flex;
}
.paper .paper-copy-center {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.paper .paper-copy-center > p {
  margin: auto;
  max-width: 500px;
}
.paper .paper-copy-center > .icon {
  margin: auto;
}
.paper .paper-copy-center > .icon + p {
  padding-top: 25px;
}
.paper .paper-aside + .paper-body {
  margin-top: -20px;
  border-top: 1px solid #1a1d250d;
}
.paper .paper-aside .paper-copy {
  padding: 5px 0;
  color: #1a1d25ff;
}
.paper .paper-copy > p {
  color: #1a1d25ff;
}
.paper p {
  margin-top: -6px;
  margin-bottom: -9px;
  padding: 0;
}
.paper p > a {
  color: #0057da;
}
.paper > .divide {
  border-top: 1px solid #1a1d250d;
}

/* ----- ICON ----- */
.icon-kandji-hive {
  width: auto;
  height: auto;
  display: flex;
}
.icon-ellipsis,
.icon-trash {
  width: 20px;
  height: 20px;
  display: flex;
}

/* ----- BUTTON ----- */
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-default + .btn-default {
  margin-left: 20px;
}
.btn-two {
  background-color: #fff;
  border-color: #0057da;
  color: #0057da;
}
.btn-three {
  color: #ee3434;
}
.btn-three > .icon > svg {
  fill: #ee3434;
}
.btn-four,
.btn-four:hover {
  color: #fff;
}
.btn-four {
  background-color: #ee3434;
  border: 0 none transparent;
}

/* ----- LOADER ----- */
.loader-simple {
  display: flex;
  z-index: 9999;
}
.loader-with-text2 {
  width: 100%;
  height: 4rem;
  position: relative;
  display: flex;
  flex-flow: nowrap row;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.loader-with-text2 .loader-with-text-data {
  padding: 0 1.625rem;
  font-family: var(--font-family-primary);
  letter-spacing: 0.1rem;
  color: #666;
  cursor: default;
  display: flex;
  align-items: center;
}
.loader-with-text2 .loader-with-text-data i {
  padding-top: 0 !important;
  font-size: 2em;
}
.loader-with-text2 .loader-with-text-data span {
  margin: 0 1rem;
  font-weight: 600;
}

/* ----- TABLE ----- */
.theme-table {
  margin: 0;
  width: 100%;
  color: #212529;
  table-layout: fixed;
}
.theme-table th {
  border-top: 0 none transparent;
  border-bottom: 1px solid #1a1d250d;
}
.theme-table td {
  padding: 20px 20px 15px;
  outline: none;
}
.theme-table td {
  border-top: 1px solid #1a1d250d;
}
.theme-table td:first-of-type {
  font-weight: 500;
}

/* ----- DROPDOWN ----- */
.dropdown-action.show {
  z-index: 9999;
}
.dropdown-action .dropdown-menu {
  margin: 0;
}
.dropdown-action .dropdown-menu-top {
  margin-top: 10px;
}
.dropdown-action .dropdown-menu-bottom {
  margin-bottom: 10px;
  top: unset;
  bottom: 100%;
}
.dropdown-action .dropdown-menu {
  border-color: #1a1d250d;
  border-radius: 5px;
  padding: 15px 0 16px;
  width: 100%;
  color: #1a1d25ff;
  box-shadow: 0 0 3px 0 rgb(150 150 150 / 30%);
}
.dropdown-action .dropdown-menu .dropdown-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown-action
  .dropdown-menu
  .dropdown-menu-list
  > .dropdown-menu-list-item
  > .btn {
  padding: 0 20px;
  width: 100%;
  justify-content: flex-start;
}
.dropdown-action {
  margin-right: -15px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.dropdown-action .dropdown-toggle {
  background-color: transparent;
  border: 0 none transparent;
  margin: -20px 0;
  padding: 20px 0;
  width: 100%;
  user-select: none;
}
.dropdown-action .dropdown-toggle:focus {
  outline: none;
}
.dropdown-action .dropdown-toggle.no-caret::after {
  content: none;
}
.dropdown-action .dropdown-toggle > .icon > svg {
  fill: #0057da;
}
.dropdown-action .dropdown-menu {
  margin-right: -25px;
}

/* ----- SHROUD ----- */
.shroud {
  background-color: #1a1d2566;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  user-select: none;
}

/* ----- MODAL ----- */
.spring-modal {
  width: 100%;
  position: fixed;
  top: 30%;
  left: 50%;
  z-index: 999999;
  transform: translate(-50%, -50%);
}
.spring-modal .paper {
  background-color: #fff !important;
}
.spring-modal .modal-title {
  margin-top: -4px;
  margin-bottom: -6px;
  font-family: var(--font-family-primary);
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  color: #1a1d25ff;
}
.spring-modal section {
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .spring-modal-default {
    max-width: 550px;
  }
}

/* ----- VENDOR ----- */
.vendor {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.vendor > .avatar,
.vendor > .icon {
  margin-top: -6px;
  margin-bottom: -4px;
  width: 30px;
  height: 30px;
}
.vendor > .avatar + .label,
.vendor > .icon + .label,
.vendor > .label + .avatar,
.vendor > .label + .icon {
  margin-left: 10px !important;
}
