/* ========================== */
/* ==========!AREA=========== */
/* ========================== */

/* ----------!MAIN---------- */
.area {
  margin: -$space-paper;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* ----------!NODE---------- */
.area > .node {
  padding: $space-paper;
  display: flex;
}

/* ============================= */
/* ==========!SECTION=========== */
/* ============================= */

/* ----------!MASTHEAD---------- */
.masthead .area {
  height: 80px;
}
