.b-up-container .b-txt-bold {
  color: var(--color-neutral-100);
}

.b-up-container .b-txt-light {
  color: var(--color-neutral-60);
}

.b-up-container .b-txt {
  color: var(--color-neutral-60);
}

.b-up-lg {
  width: 100%;
  height: 9.2rem;
  padding: 24px 34px;
}

.b-up-lg-uploader {
  padding: 21px 34px 40px 21px;
}

.b-up-lg-uploaded {
  padding: 40px 34px 40px 21px;
}

.b-up-container {
  display: flex;
  position: relative;
  cursor: pointer;
}

.b-up-container {
  border-radius: 4px;
  border: 2px dashed var(--color-neutral-30);
}

.b-up-container__focus {
  border: 2px solid var(--color-blue-60);
}

.b-up-container__disabled {
  border: 2px dashed var(--color-neutral-20);
  cursor: not-allowed;
}

.b-up-container__loaded {
  border: 2px solid var(--color-neutral-30);
}

.b-up-container__error {
  border: 2px solid var(--color-red-50);
}

.b-up-container__selected {
  cursor: auto;
}

.b-up-icon,
.b-up-icon-disabled {
  color: var(--color-neutral-30);
  font-size: 2rem;
}

.b-up-icon-disabled {
  color: var(--color-neutral-30);
}

.b-up-validate-text {
  margin-bottom: var(--b-gap-tiny);
  margin-left: 3.5rem;
}

.b-up-red {
  color: var(--color-red-50);
}

.b-up-type-icon > img {
  width: 48px;
  height: 48px;
}
