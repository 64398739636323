/* ======================== */
/* ==========!TAB========== */
/* ======================== */

// TODO: phase out

/* ----------!TITLE---------- */
.tab-title {
  border: 0 none transparent !important;
  margin-bottom: 0 !important;
  padding: 20px 30px !important;
}

/* ----------!BAR---------- */
.tab-bar {
  background: #f6f7f9;
  padding: 0 30px;
}
.tab-bar > .tab-group > .tab {
  background: transparent;
  border: 0 none transparent;
  border-radius: 0;
  padding: 0 20px;
  width: max-content;
  height: 40px;
  position: relative;
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  text-transform: none;
  letter-spacing: -0.084px;
  color: rgba(26, 29, 37, 0.5);
}
.tab-bar > .tab-group > .tab.active {
  color: rgba(26, 29, 37, 1);
}
.tab-bar > .tab-group > .tab.active::after {
  background-color: #1d212a;
  width: 100%;
  height: 4px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
}
.tab-bar > .tab-group > .tab:focus,
.tab-bar > .tab-group > .tab.focus {
  outline: 0;
  box-shadow: none;
}
