.b-multi-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  min-width: 0;
  margin: 0;
  border-radius: 4px;
  padding: 8px;
  border: 2px solid var(--color-neutral-30);

  &:focus-within {
    transition: border var(--b-animation1b);
    outline: none;
    border-color: var(--color-blue-70);
  }

  &.disabled {
    border: 2px solid var(--color-neutral-20);
    cursor: not-allowed;
  }
}

.b-multi-text__label.b-multi-text__label--disabled {
  user-select: none;
}

.b-multi-text__tag-container {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  min-width: 0;
  gap: 8px;
}

.b-multi-text__input {
  flex-grow: 1;
  border: none;
  width: 40%;
  height: 24px;

  font-family: 'atlas', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;

  &.hidden {
    display: none;
  }

  &:focus {
    outline: transparent;
  }

  &:disabled {
    cursor: not-allowed;
    background: transparent;
    color: var(--color-neutral-60);
    &::placeholder {
      color: var(--color-neutral-30);
    }
  }

  &::placeholder {
    color: var(--color-neutral-50);
  }
}

.b-multi-text__icon {
  color: var(--color-neutral-60);
  cursor: pointer;
  display: flex;
}

.b-multi-text:focus-within .b-multi-text__icon {
  color: var(--color-blue-70);
}

.b-multi-text:disabled .b-multi-text__icon {
  cursor: not-allowed;
}
