/* =========================== */
/* ==========!BUTTON========== */
/* =========================== */

/* ----------!MAIN---------- */
.btn {
  background: $color-transparent;
  border: 0 none $color-transparent;
  border-radius: $border-radius-dull;
  padding: 0 16px;
  height: $height-button;
  position: relative;
  font-family: var(--font-family-primary);
  text-transform: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: none;
}
.btn-sync-icon {
  margin: auto;
}
.btn,
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}
.btn:disabled,
.btn.disabled {
  color: $color-brand-marengo-medium !important;
  opacity: 1;
  pointer-events: initial;
  cursor: not-allowed;
}
.btn:disabled > .icon-sync,
.btn.disabled > .icon-sync {
  width: 16px;
  height: 16px;
  animation: fa-spin 2s linear infinite;
}
.btn:disabled > .icon > svg,
.btn.disabled > .icon > svg {
  fill: $color-brand-marengo-medium !important;
}

/* ----------!DEFAULT---------- */
.btn-default {
  border: $border-width-button-default solid $color-transparent;
  text-transform: capitalize;
  letter-spacing: 1.5px;
}

/* ----------!LINK---------- */
.btn-link {
  cursor: pointer;
}
.btn-link:hover {
  text-decoration: underline !important;
}

/* ----------!HOLLOW---------- */
.btn-hollow {
  padding: 0;
  color: $color-grey-2;
}
.btn-hollow > .icon > svg {
  fill: $color-grey-2;
}
.btn-hollow.active {
  color: $color-system-link;
}
.btn-hollow.active > .icon > svg {
  fill: $color-system-link;
}
.btn-hollow.active > .icon-pencil > svg {
  fill: none;
  stroke: $color-system-link;
}

/* ----------!IDENTITY---------- */
.btn-identity {
  @include styleInput();
  padding: $space-paper;
  width: 100%;
  height: 70px;
  font-family: var(--font-family-primary);
  font-weight: $font-weight-2;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.btn-identity:hover {
  border-color: $color-brand-marengo-medium;
}
.btn-identity:focus,
.btn-identity.active {
  border-color: $color-system-link;
}
.btn-identity:disabled {
  @include styleInputDisabled();
}
.btn-identity > .strategy {
  margin-bottom: -3px;
}
.btn-identity > .strategy + .toggle-on > input:checked {
  background-color: $color-system-success;
}
.btn-identity > .strategy + .toggle-on > input:disabled:checked {
  background-color: $color-system-success-light;
}
.btn-identity + .btn-identity {
  margin-top: $space-paper;
}

/* ----------!PREVIEW---------- */
.btn-preview {
  @include styleInput();
  padding: ($space-paper - 2px);
  min-width: 360px;
  min-height: 120px;
  font-family: var(--font-family-primary);
  font-weight: $font-weight-2;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.btn-preview:hover {
  border-color: $color-brand-marengo-medium;
}
.btn-preview:disabled {
  @include styleInputDisabled();
}
.btn-preview .btn-preview-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-preview .btn-preview-avatar > .avatar {
  width: 86px;
  height: 86px;
}
.btn-preview .btn-preview-copy {
  height: 80px;
  display: flex;
  flex-direction: column;
}
.btn-preview .btn-preview-copy > .btn-preview-title {
  color: $color-brand-marengo-ultradark;
  display: flex;
}
.btn-preview .btn-preview-copy > .btn-preview-title > .icon {
  margin-left: 8px;
  width: 14px;
  height: 14px;
}
.btn-preview .btn-preview-copy > .btn-preview-title > .icon > svg {
  fill: $color-brand-marengo-dark;
}
.btn-preview .btn-preview-copy > .btn-preview-description {
  @include truncate();
  width: 175px;
  font-size: 12px;
  color: $color-brand-marengo-medium;
}
.btn-preview .btn-preview-copy > .btn-preview-button {
  background-color: $color-brand-marengo-ultralight;
  border-radius: 9px;
  margin-top: auto;
  padding: 0 $space-paper;
  width: fit-content;
  height: 20px;
  font-size: 12px;
  font-family: var(--font-family-primary);
  font-weight: $font-weight-5;
  color: $color-system-link;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-preview .btn-preview-copy > .btn-preview-title + .btn-preview-description {
  padding-top: 7px;
}
.btn-preview .btn-preview-action {
  margin-left: auto;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-preview .btn-preview-action > .icon > svg {
  stroke: $color-system-link;
}
.btn-preview:disabled .btn-preview-action > .icon > svg {
  stroke: $color-brand-marengo-light;
}
.btn-preview .btn-preview-avatar + .btn-preview-copy,
.btn-preview .btn-preview-copy + .btn-preview-action {
  padding-left: $space-paper;
}

/* ----------!CLOSE---------- */
.btn-close {
  padding: 0;
  width: $space-paper;
  height: $space-paper;
  color: $color-grey-2;
}

/* ========================= */
/* ==========!TIER========== */
/* ========================= */

/* ----------!ONE---------- */
.btn-one {
  background-color: $color-system-link;
  border: 0 none $color-transparent;
}
.btn-one:active {
  background-color: $color-system-link-active;
}
.btn-one,
.btn-one:hover {
  color: $color-white;
}
.btn-one:hover:not(:disabled) {
  background-color: $color-system-link-hover;
}
.btn-one > .icon > svg,
.btn-one:hover > .icon > svg {
  fill: $color-white;
}
.btn-one > .icon-pencil > svg,
.btn-one:hover > .icon-pencil > svg {
  fill: none;
  stroke: $color-white;
}
.btn-one:disabled,
.btn-one.disabled {
  background-color: $color-brand-marengo-ultralight;
}

/* ----------!TWO---------- */
.btn-two {
  background-color: $color-white;
  border-color: $color-system-link;
  color: $color-system-link;
}
.btn-two > .icon > svg {
  fill: $color-system-link;
}
.btn-two > .icon-pencil > svg {
  fill: $color-transparent;
  stroke: $color-system-link;
}
.btn-two:active {
  border-color: $color-system-link-active;
  color: $color-system-link-active;
}
.btn-two:active > .icon > svg {
  fill: $color-system-link-active;
}
.btn-two:active > .icon-pencil > svg {
  fill: none;
  stroke: $color-system-link-active;
}
.btn-two:hover {
  color: $color-system-link-hover;
}
.btn-two:hover > .icon > svg {
  fill: $color-system-link-hover;
}
.btn-two:hover > .icon-pencil > svg {
  fill: none;
  stroke: $color-system-link-hover;
}
.btn-two:hover:not(:disabled) {
  border-color: $color-system-link-hover;
}
.btn-two:disabled,
.btn-two.disabled {
  border-color: $color-brand-marengo-ultralight;
}

/* ----------!THREE---------- */
.btn-three {
  color: $color-system-error;
}
.btn-three > .icon > svg {
  fill: $color-system-error;
}
.btn-three:active {
  color: $color-system-error-active;
}
.btn-three:active > .icon > svg {
  fill: $color-system-error-active;
}
.btn-three:hover,
.btn-three:focus {
  color: $color-system-error-hover;
}
.btn-three:hover > .icon > svg,
.btn-three:focus > .icon > svg {
  fill: $color-system-error-hover;
}

/* ----------!FOUR---------- */
.btn-four {
  background-color: $color-system-error;
  border: 0 none $color-transparent;
}
.btn-four:active {
  background-color: $color-system-error-active;
}
.btn-four,
.btn-four:hover {
  color: $color-white;
}
.btn-four:hover:not(:disabled) {
  background-color: $color-system-error-hover;
}
.btn-four > .icon > svg,
.btn-four:hover > .icon > svg {
  fill: $color-white;
}
.btn-four:disabled,
.btn-four.disabled {
  background-color: $color-brand-marengo-ultralight;
}

/* ----------!FIVE---------- */
.btn-five {
  background-color: $color-white;
  border-color: $color-system-link;
  color: $color-system-link;
}
.btn-five:hover {
  color: $color-system-link-hover;
}
.btn-five.active {
  background-color: $color-system-link !important;
  border: 0 none $color-transparent !important;
  color: $color-white !important;
}

/* ========================== */
/* ==========!GROUP========== */
/* ========================== */

/* ----------!MAIN---------- */
.group {
  display: flex;
}
.group > .btn.active {
  cursor: default;
}

/* ----------!DEFAULT---------- */
.group-default > .btn:not(:first-child),
.group-default > .btn-group:not(:first-child) {
  margin-left: -$border-width-button-default;
}

/* ----------!SOLID---------- */
.group-solid {
  background-color: $color-grey-1;
  border-radius: $border-radius-dull;
}
.group-solid > .btn {
  border: $border-width-button-solid solid $color-transparent;
  padding: 0 ($padding-width-button - $border-width-button-solid);
  font-weight: $font-weight-3;
  color: $color-brand-marengo-ultradark;
}
.group-solid > .btn > .icon > svg {
  fill: $color-brand-marengo-ultradark;
}
.group-solid > .btn.active {
  background: $color-white;
  border-color: $color-grey-2;
}
.group-solid > .btn:not(:first-child),
.group-solid > .btn-group:not(:first-child) {
  margin-left: -$border-width-button-solid;
}

/* ========================= */
/* ==========!LINK========== */
/* ========================= */

/* ----------!MAIN---------- */
.link-reset {
  text-decoration: none !important;
  color: $color-brand-marengo-dark !important;
  cursor: auto;
}
