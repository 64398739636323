.network-priority-wrapper,
.small-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  & > div {
    &:last-child {
      padding-left: var(--b-gap);
    }

    &:first-child {
      flex: 0 1 84px;
      input {
        width: 100%;
      }
    }
  }
}
