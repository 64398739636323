.outerBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}

.innerBox {
  min-height: 650px;
  width: 100%;
  display: flex;
  flex: 1;
  padding: 24px;
  border-radius: 4px;
  border: 0.5px solid #d7e1ed;
  background: white;
  gap: 24px;
  align-items: center;
  flex-direction: column;
}

.ipad-orientation-switch {
  display: flex;
  justify-content: center;
  align-items: center;

  > .switch {
    padding: 8px 12px;
    border-style: solid;
    border-color: var(--color-neutral-20);
    cursor: pointer;

    &.active {
      background-color: var(--color-blue-10);
      border-color: var(--color-blue-50);
      border-width: 1px;
      * {
        fill: var(--color-blue-50);
      }
    }

    &.disabled {
      opacity: 0.7;
      cursor: default;
      border-color: var(--color-neutral-80);
      background-color: var(--color-neutral-20);
      * {
        fill: var(--color-neutral-80);
      }
    }
  }

  .portrait {
    border-radius: 4px 0px 0px 4px;
    border-width: 1px 0 1px 1px;
  }

  .landscape {
    border-radius: 0px 4px 4px 0px;
    border-width: 1px 1px 1px 0;
  }
}

.switcher-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.device-container {
  width: 100%;
  justify-content: center;
  display: flex;
  height: 100%;
  flex: 1;
}

.outerBox .top-description {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.outerBox .the-recommended {
  font-family: 'Untitled Sans', Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
}

.helper-text-container {
  color: var(--color-red-50);
  display: flex;
  justify-content: flex-start;
  align-items: center;

  svg {
    margin-right: 5px;
  }
}

.helperText {
  flex: 1;
  text-align: center;
  color: #7b8ba3;
  font-size: 12px;
  font-family: Untitled Sans;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
  margin: 0;

  &.disabled {
    color: var(--color-red-50);
  }
}

.homeScreen {
  color: #0f1317;
  font-size: 12px;
  font-family: Untitled Sans;
  font-weight: 400;
  line-height: 16px;
  word-wrap: break-word;
}

.ipad-container {
  display: flex;
  gap: 20px;
}

.ipad-wrapper {
  flex: 1;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.ipad-wrapper img {
  margin: 0;
  width: 100%;
  height: auto;
}

.ipad-text {
  margin-top: 64px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}

.device-frames {
  position: relative;
  max-width: 100%;
  width: 100%;
  height: auto;
  border-radius: 50px;

  .frame {
  }

  .wallpaper {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50px;
  }
}

.apply-link-container {
  padding-top: 9px;
  height: 30px;
  overflow: visible;
}

.wallpaper-device-container {
  position: relative;
}

.wallpaper-device-inner,
.wallpaper-image-hover-overlay,
.wallpaper-inner-content {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.wallpaper-image-hover-overlay-screen {
  background: rgb(15 19 23 / 80%);
  border-radius: 30px;
  width: 100%;
  height: 100%;
}
