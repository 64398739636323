.record-summary-icons {
  display: flex;
  flex-direction: column;
  padding: 0;
  .record-summary-icons-left {
    padding-left: 0;
  }
  .record-summary-icons-right {
    padding-right: 0;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      //margin-bottom: 8px !important;
      display: flex;
      align-items: center;
      //font-weight: 700;
      &:not(:last-child) {
        margin-bottom: 13px;
      }
    }
  }
  .fa,
  .fas,
  .fab,
  .far {
    color: $light-grey-text;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 20px;
    text-align: center;
    &.bp-icon {
      margin-right: 10px;
      margin-left: 0;
      font-size: 0.9rem;
    }
  }
  .blueprint-name {
    font-size: 14px;
    font-weight: 400;
    color: inherit;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    height: 19px;
    line-height: 19px;
    &.blueprint-counts {
      color: $light-grey-text;
      margin-left: 27.5px;
    }
  }
  .details-alignment {
    width: 100%;
    //word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .bp-icon {
    height: 13px;
    margin-right: 10px;
    margin-bottom: 0.25em;
  }
}

.summary-left {
  padding-right: 3rem;
  @media (max-width: $breakpoint-tablet) {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
}

.summary-right {
  padding-left: 3rem;
  @media (max-width: $breakpoint-tablet) {
    padding-top: 2.75rem;
    border-top: 1px solid $light-grey-border;
  }
}

.computer-remediations {
  cursor: pointer;
  span {
    &:first-child {
      line-height: 1;
      color: $beige;
      font-size: 80px;
    }
    &:nth-child(2) {
      line-height: 1;
      color: $beige;
      font-size: 36px;
    }
    &:nth-child(3) {
      font-size: 25px;
    }
  }
}

.last-run-icon {
  height: 48px;
}

.last-run-icon-inline {
  height: 1em;
}

.computer-summary-2 {
  display: flex;
  flex-direction: column;
  background-color: var(--b-neutrals-white);
  border-radius: 4px;
  margin: 24px 0px;

  .image-and-status {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 12px;

    .status-icon {
      position: absolute;
      font-size: 32px;
      border: 3px solid $grey-150;
      border-radius: 50%;

      top: -2px;
      right: -2px;
    }

    .status {
      font-size: 1rem;
      font-weight: 700;
      letter-spacing: 0.1em;
      text-align: center;
      padding-top: 20px;
    }
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 18px;
    border-bottom: 1px solid var(--b-neutrals-grey-ultralight);

    .name {
      display: flex;
      flex-direction: column;
      margin-left: 24px;
    }

    .actions {
      display: flex;
      flex-grow: 10;
      padding-right: 12px;
      align-self: flex-start;
    }
  }

  .details {
    display: flex;
    flex-direction: row;
    padding: 18px;

    .detail-group {
      display: flex;
    }

    .field-names {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-right: 48px;
    }

    .field-values {
      display: flex;
      flex-direction: column;
      gap: 8px;
      min-width: 400px;
    }

    .blueprint-link {
      color: var(--b-link-blue-normal);
      cursor: pointer;
    }

    .edit-icon {
      height: 20px;
      margin-left: 8px;
      max-width: 20px;
      color: var(--b-neutrals-grey-dark);
      cursor: pointer;
    }
  }
}

.summary {
  .summary__header {
    border-bottom: solid 1px $light-grey-border;
    padding-bottom: 10px;
    height: 70px;
  }
  .summary__body {
    padding-top: 20px;
  }
  .summary-el {
    display: flex;
    flex-direction: row;
    .summary-el-card {
      display: flex;
      flex-direction: column;
      .summary-el-card__title {
        display: flex;
        flex-direction: row;
        .title__content {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
}

.blocks-row {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.computer-last-run-icons {
  padding: 20px 0;
  .col {
    display: flex;
    min-height: 48px;
    cursor: pointer;
    .icon-caption {
      display: flex;
      flex-flow: column;
      justify-content: center;
      span {
        margin: 0;
        font-weight: 400;
        line-height: 1;
        padding-left: 10px;
        &:nth-child(1) {
          font-size: 30px;
        }
        &:nth-child(2) {
          font-size: 14px;
        }
      }
    }
    &:nth-child(1) {
      color: #ff6f26;
    }
    &:nth-child(2) {
      color: $beige;
    }
    &:nth-child(3) {
      color: #00b177;
    }
  }
}

.tabular-info {
  display: flex;
  flex-flow: column;
  .row {
    margin-bottom: 15px;
    .col {
      font-size: 1rem;
      &:nth-child(1) {
        color: $text-black;
        font-weight: 400;
      }
      &:nth-child(2) {
        color: $text-black;
        font-weight: 500;
      }
    }
  }
}

.link-pointer {
  cursor: pointer;
}

.long-icon {
  margin: 0 11px 0 -2px !important;
}

.none-margin-bottom {
  margin-bottom: 0 !important;
}

.computer-model-icon {
  flex-basis: auto;
  width: auto;
  padding-right: 15px !important;
  img {
    //max-width:100px;
    max-height: 100px;
  }
}

.computer-name-wrapper {
  p {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1em;

    &.computer-model {
      font-weight: 400;
      font-size: 14px;
      color: $light-grey-text;
      margin-bottom: 15px;
      margin-top: 3px;
    }
    &.computer-name {
      font-size: 24px;
      color: inherit;
    }
  }
}

.cut-long-text {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.bp-history-icon {
  height: 1.125em;
}

.computer-details {
  .computer-details-section {
    padding: 60px 20px;
    .section-name {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
    }
    .section-second-value {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      margin: 10px;
      border-left: 3px solid lightgrey;
    }
    .section-second-value-list {
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      margin: 10px;
      border-left: 3px solid lightgrey;
    }
    .section-payload {
      display: flex;
      flex-direction: column;
      margin-bottom: 15px;
    }
    .section-payload-value {
      display: flex;
      flex-direction: column;
      margin-left: 25px;
    }
    .section-payload-content {
      margin-left: 25px;
    }
    .section-value-key,
    .section-value-data {
      font-size: 16px;
      line-height: 24px;
    }
    .section-value-key {
      font-weight: 500;
    }
    .list-block {
      padding-bottom: 5px;
    }
    .sub-section-name {
      font-weight: 700;
      padding-bottom: 15px;
      font-size: 14px;
      letter-spacing: 0.1em;
    }
    .section-with-data {
      padding-bottom: 15px;
    }
    .section-value-data {
      font-weight: 400;
      margin-left: 5px;
    }
    .volumes-container {
      margin-bottom: -40px;
      .volumes-section {
        width: 375px;
        margin-bottom: 40px;
      }
    }
  }
  .computer-details-section:not(:last-child) {
    border-bottom: 1px solid #e3eaf2;
  }
}
