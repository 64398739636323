.counter {
  height: 1.8em;
  font-size: 0.8em;
  background: $danger;
  border-radius: 1em;
  min-width: 1.8em;
  line-height: 1em;
  padding: 0.5em;
  color: white;
  text-decoration: none;
  font-weight: bold;
  display: inline-block;
  text-align: center;
}
