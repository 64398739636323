.b-library-summary__all-blueprints {
  .b-chip__icon {
    color: var(--colors-neutral90);
  }

  .b-chip__text {
    color: var(--colors-neutral90);
    font-weight: var(--font-weight-regular);
  }

  .b-chip--kind-info {
    background-color: var(--colors-blue10);
  }
}
