/* istanbul ignore file - legacy code, moved */
@import 'src/theme/styles/control';

.library .paper header.division .node {
  padding-bottom: 12px;
  padding-top: 12px;
}

.library .paper header.division + section > .node:first-child {
  padding-top: $space-edge-item;
}
.library .paper footer.division > .node:first-child {
  padding-top: 0;
}
.library .paper footer.division > .node:last-child {
  padding-bottom: $space-edge-item;
}
.library .detail.library-details {
  display: flex;
  margin-top: -6px;
  margin-left: -15px;

  .detail-column {
    + .detail-column {
      margin-left: $space-paper;
    }

    > * {
      height: 33px;
      margin: 0;

      &.chip-list {
        transform: translate(-6px, -2px);
      }
    }
  }
}

header.library + .navigation {
  margin-top: 0;
}

.icon.icon-screen-saver {
  width: auto;
}

@media (max-width: 1199px) {
  .library .detail.library-details {
    padding-left: 17px;
  }
}

/* reset spacing and let the breadcrum set the space. */
.library-top-section > .zone.zone.zone {
  padding-top: 0;
}

.library.library-below-breadcrumb > .zone.zone.zone {
  padding-top: 0;
}
