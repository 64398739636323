.k-skip-title {
  border-bottom: 1px solid var(--color-neutral-20);
  padding: var(--b-gap) var(--b-gap3);
  margin-bottom: var(--b-gap2);
}

.k-skip-rows {
}

.k-skip-row {
  padding: var(--b-gap2) 0;
  border-bottom: 1px solid var(--color-neutral-20);
}

.k-skip-row__description > .b-mb.b-mb {
  margin-bottom: var(--b-gap1);
}

.k-skip-rows > .k-skip-row:last-child {
  border-bottom: none;
}

.k-skip-row__option {
  margin-bottom: var(--b-gap1);
}

.k-skip-row__description {
  padding-left: 36px;
}

.b-side-panel-layout__header.--skip-screen-header {
  padding: 0;
}

.k-skip-screen-search {
  padding: 0 var(--b-gap3) var(--b-gap2);
  align-items: flex-start;
}

.k-skip-screen-tags {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 360px;
  min-height: 72px;
  padding: var(--b-gap) var(--b-gap2) var(--b-gap) var(--b-gap);
  background: var(--color-neutral-0);
  border-radius: 4px;
  border: 2px solid var(--color-neutral-30);
}

.k-skip-screen-tags--error {
  border-color: var(--color-red-50);
}

.k-skip-screen-tags__icon {
  padding-left: var(--b-gap2);
}

.k-skip-screen-tags__icon-button .bi {
  line-height: inherit;
}

.k-skip-row__description .b-flex-g.b-flex-g > :not(:last-child) {
  margin-right: var(--b-gap-tiny);
}

.k-skip-screens-panel .b-side-panel-layout__body {
  overflow: unset;
  overflow-y: auto;
}
