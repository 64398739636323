.dd-wrapper {
  cursor: pointer;
  user-select: none;
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 10px;
  background: white;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: var(--font-family-primary);
  font-size: 14px;
  color: #1a1d25;

  .dd-header {
    cursor: pointer;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    position: relative;
    background-color: white;

    .dd-icon {
      margin-right: 20px;
    }
    .x-icon {
      font-weight: 400;
      margin-bottom: -2px;
    }
  }

  .dd-header-title {
    display: flex;
    cursor: pointer;
    font-weight: 300;
    max-width: 80%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.text-gray {
      opacity: 0.5;
    }
  }

  .angle-down {
    color: #1a1d25;
    margin-right: 20px;
  }

  .dd-list {
    z-index: 10;
    position: absolute;
    left: -2px;
    width: 100%;
    border: 2px solid #e5e5e5;
    border-top: none;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
    background-color: white;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    padding: 15px 0;
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #1a1d25;
      border-radius: 4px;
    }
  }

  .dd-list-item {
    cursor: pointer;
    width: 100%;
    padding: 8px 10px 8px 20px;
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;

    &.selected {
      background-color: #f6f7f9;
    }

    &:hover {
      background-color: #f6f7f9;
    }
  }

  .dd-list-item-text {
    display: flex;
    align-items: center;
    justify-content: center;

    &.centered-text {
      margin-top: 2px;
    }
  }

  .text-margin {
    margin-left: 10px;
  }

  .dd-list-item-check-icon {
    margin-bottom: 3px;
    margin-left: 10px;
  }
}
