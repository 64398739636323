/* =========================== */
/* ==========!LOADER========== */
/* =========================== */

/* ----------!MAIN---------- */
.loader {
  display: flex;
  z-index: 9999;
}

/* ----------!TYPE---------- */
.loader-splash {
  height: 100vh;
  align-items: center;
  justify-content: center;
}
