.l-ssaver-panel {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.l-ssaver-panel__title {
  padding: var(--b-gap) var(--b-gap3);
  box-shadow: inset 0px -1px 0px rgba(26, 29, 37, 0.05);
  margin-bottom: var(--b-gap2);
}

.l-ssaver-panel__body {
  padding: 0 var(--b-gap3);
  flex: 1;
}

.l-ssaver-panel__body-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--b-gap2);
  row-gap: var(--b-gap3);
  padding: 0 56px;
  margin-bottom: var(--b-gap2);
}

.l-ssaver-panel__option {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 180px;
  height: 144px;
  padding: 7px 10px;
  border-radius: var(--b-gap-micro);
  cursor: pointer;
}

.l-ssaver-panel__option.--selected {
  background-color: var(--color-neutral-10);
}

.l-ssaver-panel__option-img {
  margin-bottom: 10px;
  height: 98px;
  width: 160px;
}

.l-ssaver-panel__footer {
  display: flex;
  justify-content: flex-end;
  padding: var(--b-gap1) var(--b-gap2);
  border-top: 1px solid var(--color-neutral-20);
}

.l-ssaver-panel__button {
  display: flex;
  justify-content: space-between;
  width: 341px;
  height: 72px;
  border: 2px solid rgba(26, 29, 37, 0.15);
  padding: var(--b-gap);
  border-radius: var(--b-gap-micro);
  cursor: pointer;
}

.l-ssaver-panel__button.--disabled {
  cursor: not-allowed;
}

.l-ssaver-panel__button-preview {
  display: flex;
  align-items: center;
}

.l-ssaver-panel__button-preview-img {
  width: 64px;
  height: 40px;
  border-radius: 4px;
  margin-right: var(--b-gap);
}

.l-ssaver-panel__button-icon {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--color-blue-50);
}

.l-ssaver-panel__button-icon.--disabled {
  color: var(--color-neutral-60);
}
