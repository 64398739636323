/* ========================= */
/* ==========!FORM========== */
/* ========================= */

$theme-form-offset: 17px;

/* ----------!BLOCK---------- */
.theme-form-block {
  padding-bottom: $space-paper;
}
.theme-form-block + .theme-form-block,
.theme-form-group + .theme-form-block {
  border-top: 1px solid $color-divider;
  padding-top: ($space-paper - 1px);
}
.theme-form-group + .theme-form-block,
.theme-form-group-space + .theme-form-group-space {
  margin-top: $space-paper * 2;
}
.theme-form-block + .theme-form-group {
  margin-top: $space-paper;
}
.theme-form-block-managed {
  padding-top: 1px;
}

/* ----------!BLOCK-AlIGNED---------- */
.theme-form-block.aligned {
  display: flex;
  flex-wrap: wrap;

  > div {
    padding-top: 20px;
    width: 50%;
  }
}

/* ----------!ERROR---------- */
.theme-form-text-input.is-error,
.theme-form-block-error .theme-form-text-input,
.theme-form-block-error .theme-form-textarea-input,
.theme-form-block-error .day-picker > .DayPickerInput > input,
.theme-form-block-error #drop-area {
  border-color: $color-system-error !important;
}
.theme-form-block-error .theme-form-text-input:hover,
.theme-form-block-error .theme-form-text-input:focus,
.theme-form-block-error .theme-form-textarea-input:hover,
.theme-form-block-error .theme-form-textarea-input:focus,
.theme-form-block-error .day-picker > .DayPickerInput > input:hover,
.theme-form-block-error .day-picker > .DayPickerInput > input:focus,
.theme-form-block-error #drop-area:hover,
.theme-form-block-error #drop-area:focus {
  border-color: $color-system-error-hover !important;
}
.theme-form-error,
.theme-form-error > p,
.theme-form-text-error,
.theme-form-textarea-error {
  color: $color-system-error !important;
}
.theme-form-text-error,
.theme-form-textarea-error {
  padding-top: ($space-paper - 5px) !important;
}
.theme-form-upload + .theme-form-error {
  padding-top: $space-paper;
}
.theme-input-required {
  color: $color-system-error;
  font-size: $font-size-text;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 3px 0 3px 0;
  font-family: $font-family-1;
}

/* ----------!DIVISION---------- */
.theme-form-division {
  margin: -$space-paper;
  margin-top: -$space-paper - 1px;
}
.theme-form-division + .theme-form-division {
  padding-top: $space-paper;
}
.theme-form-division .theme-form-division-header .sticky-background {
  background-color: $color-grey-3;
}

/* ----------!LABEL---------- */
.theme-form-label > label,
.theme-form-group-label,
.theme-form-text-label,
.theme-form-textarea-label,
.theme-form-upload-label {
  margin-top: -6px;
  margin-bottom: -9px;
  color: $color-brand-marengo-ultradark !important;
  display: block;
}
.theme-form-label > label > span,
.theme-form-text-label > span,
.theme-form-textarea-label > span,
.theme-form-check-label > span,
.theme-form-radio-label > span {
  margin-left: 4px;
  color: $color-brand-marengo-medium;
}
.theme-form-text-label > i,
.theme-form-textarea-label > i,
.theme-form-check-label > i,
.theme-form-radio-label > i {
  margin-left: 3px;
  font-style: normal;
  color: $color-system-error;
}
.theme-form-label + .banner {
  margin-top: 20px;
}
.theme-form-path .theme-form-label {
  padding-bottom: 24px;
}

/* ----------!DETAIL---------- */
.theme-form-detail-dark > p {
  color: $color-brand-marengo-ultradark !important;
}

/* ----------!CHECK-RADIO---------- */
.theme-form-check,
.theme-form-radio {
  margin: 0;
  padding: 0;
  display: flex;
}
.theme-form-check + .theme-form-check,
.theme-form-radio:not([class*='theme-form-select-option'])
  + .theme-form-radio:not([class*='theme-form-select-option']) {
  padding-top: 15px;
}
.theme-form-check .theme-form-check-label,
.theme-form-radio:not([class*='theme-form-select-option'])
  .theme-form-radio-label:not([class*='theme-form-select-option-label']) {
  margin-top: -1px;
  margin-bottom: -4px;
  color: $color-brand-marengo-ultradark !important;
}
.theme-form-check .theme-form-check-input,
.theme-form-radio .theme-form-radio-input {
  @include styleInput();
  margin: 0;
  padding: 0;
  position: relative;
  min-width: $space-paper;
  width: $space-paper;
  min-height: $space-paper;
  height: $space-paper;
  appearance: none;
  cursor: pointer;
  box-sizing: border-box;
}
.theme-form-check .theme-form-check-input {
  border-radius: 1px;
}
.theme-form-radio .theme-form-radio-input {
  border-radius: 50%;
}
.theme-form-check .theme-form-check-input:hover,
.theme-form-check .theme-form-check-input:focus,
.theme-form-radio .theme-form-radio-input:hover,
.theme-form-radio .theme-form-radio-input:focus {
  border-color: $color-brand-marengo-medium;
}
.theme-form-check .theme-form-check-input:disabled,
.theme-form-radio .theme-form-radio-input:disabled {
  @include styleInputDisabled();
}
.theme-form-check .theme-form-check-input:checked,
.theme-form-radio .theme-form-radio-input:checked {
  background: $color-system-link;
  border-color: $color-system-link;
}
.theme-form-check .theme-form-check-input:checked:hover,
.theme-form-check .theme-form-check-input:checked:focus,
.theme-form-radio .theme-form-radio-input:checked:hover,
.theme-form-radio .theme-form-radio-input:checked:focus {
  background: $color-system-link-hover;
  border-color: $color-system-link-hover;
}
.theme-form-check .theme-form-check-input:checked:after,
.theme-form-radio .theme-form-radio-input:checked:after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Font Awesome 5 Pro', sans-serif;
  font-size: 11px;
  color: $color-white;
  content: '\f00c';
}
.theme-form-check .theme-form-check-input:checked:disabled,
.theme-form-radio .theme-form-radio-input:checked:disabled {
  background: $color-brand-marengo-ultralight;
  border-color: $color-brand-marengo-ultralight;
}
.theme-form-check .theme-form-check-input:checked:disabled:after,
.theme-form-radio .theme-form-radio-input:checked:disabled:after {
  color: $color-brand-marengo-medium;
}
.theme-form-check .theme-form-check-input + .theme-form-check-label,
.theme-form-radio .theme-form-radio-input + .theme-form-radio-label {
  padding-left: $theme-form-offset;
}

/* ----------!RADIO---------- */
.theme-form-radio-list {
  margin-bottom: 0;
  padding-left: 0;
}
.theme-form-radio-list.with-label {
  padding-top: $space-paper;
}
.theme-form-radio-list > .theme-form-radio-item {
  list-style-type: none;
  display: flex;
}
.theme-form-radio-list > .theme-form-radio-item + .theme-form-radio-item {
  padding-top: 10px;
}
.theme-form-radio-list
  > .theme-form-radio-item
  > .theme-form-radio-input:checked:after {
  border: 2px solid $color-white;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: '';
}

/* ----------!SELECT---------- */
.theme-form-select.no-label {
  padding-top: 0 !important;
}
.theme-form-select .theme-form-select-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.theme-form-select .theme-form-select-label > span {
  padding-top: 1px;
}
.theme-form-select .theme-form-select-option .theme-form-select-option-input {
  display: none;
}
.theme-form-select
  .theme-form-select-option
  .theme-form-select-option-input:checked
  + .theme-form-select-option-label {
  background-color: $color-system-link;
  font-weight: $font-weight-3;
  color: $color-white;
}
.theme-form-select .theme-form-select-option .theme-form-select-option-label {
  margin-bottom: 0;
  padding: 9px 19px 6px;
  width: 100%;
  cursor: pointer;
}
.theme-form-select
  .theme-form-select-option
  .theme-form-select-option-label:active,
.theme-form-select
  .theme-form-select-option
  .theme-form-select-option-label:hover,
.theme-form-select
  .theme-form-select-option
  .theme-form-select-option-label:focus {
  background-color: $color-grey-1;
}
.theme-form-select:not([class*='theme-form-select-multiple']):not(
    [class*='theme-form-select-full']
  )
  .dropdown {
  min-width: 360px;
  width: fit-content;
}
.theme-form-select .dropdown .dropdown-toggle {
  @include styleInput();
  @include styleInputSize();
  align-items: center;
  justify-content: flex-start; // remove after phasing out /src/assets/styles/elements/_modals.scss
}
.theme-form-select .dropdown .dropdown-toggle:hover,
.theme-form-select .dropdown .dropdown-toggle:focus {
  border-color: $color-brand-marengo-medium;
}
.theme-form-select .dropdown .dropdown-toggle::after {
  display: none;
  content: '';
}
.theme-form-select .dropdown .dropdown-toggle:disabled {
  @include styleInputDisabled();
}
.theme-form-select .dropdown .dropdown-toggle .dropdown-toggle-caret {
  padding-left: 10px;
  display: flex;
}
.theme-form-select .dropdown .dropdown-toggle .dropdown-toggle-caret::after {
  border-top: 5px solid;
  border-right: 5px solid $color-transparent;
  border-bottom: 0;
  border-left: 5px solid $color-transparent;
  color: $color-brand-marengo-ultradark;
  content: '';
}
.theme-form-select
  .dropdown
  .dropdown-toggle:disabled
  .dropdown-toggle-caret::after {
  color: $color-brand-marengo-light;
}
.theme-form-select .dropdown .dropdown-menu .dropdown-menu-body {
  max-height: 200px;
}
.theme-form-select .dropdown.show .dropdown-toggle {
  border-color: $color-system-link;
}
.theme-form-select .before-label-cont {
  display: flex;
  align-items: center;
}
.theme-form-select
  .dropdown.show
  .dropdown-toggle
  .dropdown-toggle-caret::after {
  border-top: 0;
  border-bottom: 5px solid;
  color: $color-system-link;
}
.theme-form-select .before-label-cont .before-label {
  margin-right: 20px;
  color: $color-brand-marengo-ultradark;
}
.theme-form-select-multiple {
  display: flex;
  align-items: center;
}
.theme-form-select-multiple .theme-form-select-text {
  padding: 0 $space-paper;
}
.theme-form-select-multiple .dropdown {
  min-width: 210px;
  width: 100%;
}
.theme-form-select-multiple .dropdown + .dropdown {
  margin-left: $space-paper;
}

/* ----------!TEXT---------- */
.theme-form-text-input,
.theme-form-textarea-input {
  @include styleInput();
  @include styleInputSize();
}
.theme-form-text-input:hover,
.theme-form-textarea-input:hover {
  border-color: $color-brand-marengo-medium;
}
.theme-form-text-input:focus,
.theme-form-textarea-input:focus {
  border-color: $color-system-link;
}
.theme-form-text .theme-form-text-input:disabled,
.theme-form-textarea .theme-form-textarea-input:disabled {
  @include styleInputDisabled();
  resize: none;
}

.theme-form-text-input::placeholder,
.theme-form-textarea-input::placeholder {
  color: $color-brand-marengo-light !important;
  -webkit-text-fill-color: $color-brand-marengo-light !important;
}
.theme-form-textarea-input {
  padding: $space-input;
  height: auto;
  min-height: 130px;
  display: block;
}
.theme-form-textarea-input.theme-form-textarea-input-tall {
  min-height: 240px;
}

/* ----------!PASSWORD---------- */
.theme-form-password-wrapper {
  position: relative;
}
.theme-form-password-wrapper .theme-form-text-input {
  padding-right: 55px;
}
.theme-form-password-wrapper .field-password-eye-icon {
  margin-top: 12px;
  margin-right: 20px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

/* ----------!UPLOAD---------- */
.theme-form-upload > .theme-form-node {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.theme-form-upload > .theme-form-node > .btn {
  margin-left: 0 !important;
}
.theme-form-upload-avatar {
  display: flex;
  align-items: center;
}
.theme-form-upload-avatar > .theme-form-node + .theme-form-node {
  padding-left: $space-paper * 2;
}
.theme-form-upload-avatar > .theme-form-node > .btn {
  min-width: 145px;
}
.theme-form-upload-avatar > .theme-form-node > .btn + .btn {
  margin-top: 2px;
}
.theme-form-upload-avatar .theme-form-preview {
  @include styleInput();
  padding: ($space-paper - 2px);
  min-width: 120px;
  min-height: 120px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.theme-form-upload-avatar .theme-form-preview.is-error {
  border-color: #ce3537;
}
// .theme-form-upload-avatar .theme-form-preview:hover,
// .theme-form-upload-avatar .theme-form-preview:hover {
//   border-color: $color-brand-marengo-medium;
// }
.theme-form-upload-avatar .theme-form-preview:disabled {
  @include styleInputDisabled();
}
.theme-form-upload-avatar .theme-form-preview-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme-form-upload-avatar .theme-form-preview-avatar > .avatar {
  width: 80px;
  height: 80px;
}

/* ----------!RANGE---------- */
.theme-form-range > .slider input:disabled {
  @include styleInputDisabled();
}

/* ----------!ENFORCEMENT---------- */
.theme-form-enforcement > .dropdown + .dropdown,
.theme-form-enforcement > .dropdown-case + .dropdown,
.theme-form-enforcement > .day-picker + .dropdown {
  margin-top: 10px;
}
.theme-form-enforcement > .dropdown-case {
  display: flex;
  align-items: center;
}
.theme-form-enforcement > .dropdown-case > .dropdown {
  min-width: 165px !important;
}
.theme-form-enforcement > .dropdown-case > .dropdown + .dropdown-copy {
  margin-left: ($space-paper - 1px);
}
.theme-form-enforcement > .dropdown-case > .dropdown-copy > p {
  color: $color-brand-marengo-ultradark;
}
.theme-form-enforcement > .day-picker-bottom .DayPickerInput-OverlayWrapper {
  margin-top: 0;
  margin-bottom: 40px;
  top: unset;
  bottom: 100%;
}

/* ----------!PREVIEW---------- */
.theme-form-preview > .theme-form-preview-window + .theme-form-preview-action {
  padding-top: ($space-paper * 2) + 1px;
}
.theme-form-preview > .theme-form-preview-window {
  @include styleInput();
  overflow: hidden;
}
.theme-form-preview-window-background {
  background-color: #fe504b;
  padding-top: $space-paper;
}
.theme-form-preview-window-background,
.theme-form-preview-window-image {
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}
.theme-form-preview-window-image {
  background-color: $color-white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 1130px;
  height: 210px;
  position: relative;
  box-shadow: 0 0 $space-paper 0 rgba(0, 0, 0, 0.3);
}
.theme-form-preview-window-branding {
  margin-left: 263px;
  padding-top: 48px;
  padding-left: $space-paper * 2;
  padding-right: 100px;
  width: 770px;
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: center;
}
.theme-form-preview-window-branding
  > .theme-form-preview-window-branding-avatar-case {
  // @include styleInput();
  padding: 0;
  min-width: 64px;
  min-height: 64px;
  font-family: $font-family-1;
  font-weight: $font-weight-2;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.theme-form-preview-window-branding
  > .theme-form-preview-window-branding-avatar-case
  + .theme-form-preview-window-branding-copy {
  padding-left: $space-paper;
  max-width: 600px;
}
.theme-form-preview-window-branding
  > .theme-form-preview-window-branding-avatar-case
  > .theme-form-preview-window-branding-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
}
.theme-form-preview-window-branding
  > .theme-form-preview-window-branding-avatar-case
  > .theme-form-preview-window-branding-avatar
  > .avatar {
  width: 60px;
  height: 60px;
}
.theme-form-preview-window-branding-copy {
  width: 100%;
}
.theme-form-preview-window-branding-copy
  > .theme-form-preview-window-branding-title {
  @include truncate();
  padding-top: 8px;
  padding-bottom: 10px;
  font-family: $font-family-3;
  font-size: 34px;
  font-weight: $font-weight-5;
}
.theme-form-preview-window-branding-copy
  > .theme-form-preview-window-branding-description {
  @include truncate();
  padding-left: 1px;
  font-family: $font-family-4;
  font-size: 14px;
  font-weight: $font-weight-2;
}
.theme-form-preview
  > .theme-form-preview-window
  > .theme-form-preview-window-background,
.theme-form-preview > .theme-form-preview-action {
  display: flex;
  justify-content: center;
}
.theme-form-preview > .theme-form-preview-action > .btn-group > .btn {
  height: 30px;
  font-size: 12px;
  padding: 0 16px;
}
// .theme-form-preview-mode-dark .theme-form-preview-window-branding-avatar-case {
//   border-color: $color-white !important;
// }
.theme-form-preview-mode-dark .theme-form-preview-window-branding-title,
.theme-form-preview-mode-dark .theme-form-preview-window-branding-description {
  color: $color-white !important;
}
@media (max-width: 1525.98px) {
  .theme-form-block-preview {
    display: none !important;
  }
  .theme-form-block-preview + .paper-block {
    border: 0 none $color-transparent !important;
    margin: 0 !important;
    padding: 0 !important;
    padding-bottom: $space-paper !important;
  }
}

/* ----------!COPY---------- */
.theme-form-copy > .theme-form-copy-title {
  font-family: $font-family-headline;
  font-weight: $font-weight-headline;
  font-size: $font-size-headline;
  line-height: $line-height-headline;
  color: $color-brand-marengo-ultradark;
  display: flex;
}

.theme-form-copy-title.form-title-normal {
  font-weight: 400;
  color: $color-brand-marengo-ultradark;
}

p.theme-form-copy-description {
  margin-top: 6px;
  @media (min-width: 1440px) {
    padding-left: 30px;
    margin-top: 0;
    grid-area: 1 / 2 / 3 / 3;
  }
}

.theme-form-grid {
  @media (min-width: 1440px) {
    display: grid;
    grid: auto 1fr / 1fr 1fr;
    .theme-form-copy-description {
      padding-left: 30px;
    }
  }
}

.theme-form-copy .btn-preview {
  padding: 16px;
  margin-top: 22px;
  justify-self: flex-start;
  @media (min-width: 1440px) {
    margin-top: 11px;
  }
}

/* ----------!DISPLAY---------- */
.theme-form-hide {
  display: none;
}
@media (min-width: 1440px) {
  .theme-form-hide-hg {
    display: none;
  }
  .theme-form-show-hg {
    display: flex;
  }
}
.theme-form-show-hg .theme-form-help {
  padding-top: 0;
}

/* ----------!BANNER---------- */
.theme-form-banner {
  display: flex;
  align-items: flex-start;
  background-color: $color-grey-1;
  padding: 20px;
  border-radius: 3px;
  margin-bottom: 40px;

  i {
    margin-right: 10px;
  }

  svg {
    fill: $color-grey-4;
  }
}
@media (min-width: 1440px) {
  .theme-form-block-banner .banner {
    max-width: 550px;
  }
}

/* ============================ */
/* ==========!LIBRARY========== */
/* ============================ */

/* ----------!REGION---------- */
.theme-form-section-aside + .theme-form-section-body {
  margin-top: -$space-paper;
  border-top: 1px solid $color-divider;
}
.theme-form-section-aside .theme-form-copy {
  padding: 5px 0;
  color: $color-brand-marengo-ultradark;
}

/* ----------!TEXT---------- */
.theme-form-help + .theme-form-text > .theme-form-text-input,
.theme-form-help + .theme-form-textarea > .theme-form-textarea-input {
  max-width: 360px;
}

/* ----------!SPACE---------- */
.theme-form-help {
  padding-top: $space-paper + 1px;
  width: 100%;
}
.theme-form-label + .theme-form-detail {
  padding-top: $space-paper + 1px;
}
.theme-form-detail + .theme-form-text,
.theme-form-detail + .theme-form-textarea,
.theme-form-detail + .theme-form-text-input,
.theme-form-detail + .theme-form-textarea-input,
.theme-form-detail + .theme-form-upload {
  margin-top: $space-paper + 5px;
}
.theme-form-block-optional > .row {
  position: relative;

  &::before {
    content: '';
    height: calc(100% - 40px);
    border-right: 2px solid rgba(26, 29, 37, 0.05);
    position: absolute;
    bottom: -3px;
    left: 41px;
  }

  /*  .theme-form-check .theme-form-check-input {
    $size: 20px;
    min-width: $size;
    width: $size;
    height: $size;
    min-height: $size;
  }*/
}

.theme-form-section-body > .node {
  &:first-child {
    padding-top: 20px;
  }
  &:last-child {
    padding-bottom: $space-edge-item - $space-paper;
  }
}
.theme-form-block-optional .theme-form-offset,
.theme-form-block-check .theme-form-offset {
  padding-left: $space-paper + $theme-form-offset;
}
.theme-form-block .theme-form-offset.theme-form-range {
  padding-right: $space-paper + $theme-form-offset;
}
.theme-form-block-optional .theme-form-help + .theme-form-select,
.theme-form-block-optional .theme-form-help + .theme-form-text,
.theme-form-block-optional .theme-form-help + .theme-form-textarea,
.theme-form-block-optional .theme-form-help + .theme-form-upload,
.theme-form-block-optional .theme-form-select + .theme-form-assignment,
.theme-form-block-optional .theme-form-error,
.theme-form-block-managed .theme-form-help + .theme-form-select,
.theme-form-block-managed .theme-form-help + .theme-form-text,
.theme-form-block-managed .theme-form-help + .theme-form-textarea,
.theme-form-block-managed .theme-form-help + .theme-form-upload,
.theme-form-block-managed .theme-form-select + .theme-form-assignment,
.theme-form-block-managed .theme-form-error {
  padding-top: $space-paper + 2px;
}
.theme-form-block-optional .theme-form-check + .theme-form-detail,
.theme-form-block-optional .theme-form-check + .theme-form-text,
.theme-form-block-optional .theme-form-check + .theme-form-textarea,
.theme-form-block-optional .theme-form-help + .theme-form-range {
  padding-top: $space-paper;
}
@media (min-width: 1440px) {
  .theme-form-help {
    padding-left: 0 !important;
  }
  .theme-form-block-optional .theme-form-help,
  .theme-form-block-check .theme-form-help {
    padding-top: 5px;
  }
  .theme-form-block-optional .theme-form-check + .theme-form-select,
  .theme-form-block-optional .theme-form-check + .theme-form-text,
  .theme-form-block-optional .theme-form-check + .theme-form-textarea,
  .theme-form-block-optional .theme-form-check + .theme-form-upload,
  .theme-form-block-optional .theme-form-help + .theme-form-select,
  .theme-form-block-optional .theme-form-help + .theme-form-text,
  .theme-form-block-optional .theme-form-help + .theme-form-textarea,
  .theme-form-block-optional .theme-form-help + .theme-form-upload,
  .theme-form-block-managed .theme-form-help + .theme-form-select,
  .theme-form-block-managed .theme-form-help + .theme-form-text,
  .theme-form-block-managed .theme-form-help + .theme-form-textarea,
  .theme-form-block-managed .theme-form-help + .theme-form-upload {
    padding-top: $space-paper;
  }
  .theme-form-block-optional .theme-form-help + .theme-form-range {
    padding-top: ($space-paper - 5px);
  }
  .theme-form-detail + .theme-form-upload {
    margin-top: $space-paper;
  }
}
.theme-form-text-label + .theme-form-text-detail {
  padding-top: ($space-paper - 5px);
}
.theme-form-text-label + .dropdown,
.theme-form-text-detail + .dropdown {
  margin-top: $space-paper;
}

/* =========================== */
/* ==========!CUSTOM========== */
/* =========================== */

/* ----------!COMBINE---------- */
.theme-form-block-combine
  + .theme-form-block-combine:not([class*='combine-clear']),
.theme-form-block-combine-tight
  + .theme-form-block-combine-tight:not([class*='combine-clear']) {
  border: 0 none $color-transparent;
  margin: 0;
}
.theme-form-block-combine-tight
  + .theme-form-block-combine-tight:not([class*='combine-clear']) {
  padding-top: 0;
}

/* ----------!UPLOAD---------- */
.hook-upload-indented label {
  margin-bottom: 10px;
  color: $color-brand-marengo-ultradark;
}
.hook-upload-indented .hook-upload-container {
  background-color: #fff;
}

/* ----------!PRINTER-FILES---------- */
.printer-files h4 {
  font-family: $font-family-text;
  font-weight: $font-weight-text;
  font-size: $font-size-text;
  line-height: $line-height-text;
  color: $color-brand-marengo-dark;
}
