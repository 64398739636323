.k-library--kandji-login {
}

.k-klogin-group-grid {
  display: grid;
  grid: auto/360px 360px 36px;
  grid-column-gap: var(--b-gap2);
  align-items: center;
}

.k-klogin-group-grid.--header {
}

.k-klogin-exclude-user-grid,
.k-klogin-localusers-grid {
  display: grid;
  grid: auto/360px 36px;
  grid-column-gap: var(--b-gap1);
  align-items: center;
}

.k-klogin-accounts-scrollable {
  max-height: 292px;
  overflow: auto;
  max-width: 423px;
}

.k-klogin-accounts-scrollable.--wide {
  max-width: 830px;
}

/* adds extra padding in provision permissions based on group last select so that
 * its box shadow is not cut off past scroll container */
.k-klogin-accounts-last-select-scroll-spacing .b-select__menu::after {
  content: '';
  height: var(--b-gap2);
  width: 100%;
  position: absolute;
}

.k-klogin-accounts-scrollable.--permissions-based-on-group {
  max-height: 352px;
  width: 830px;
}

.k-klogin-lock-banner-input + .b-txt-input__err-msg {
  bottom: -20px;
}

@media (max-width: 1310px) {
  .k-klogin-accounts-scrollable.--permissions-based-on-group {
    width: 100%;
  }
}

/* ss2 style drawer css */
.b-side-panel-layout__body.--k-ss2-style-body {
  padding: var(--b-gap3);
}

.b-side-panel-layout__body.--k-ss2-style-body > div:not(:last-child) {
  margin-bottom: 48px;
}

.k-ss2-style-drawer-section {
}

.k-ss2-style-drawer-section > h3 {
  margin-bottom: var(--b-gap2);
}

.k-ssw-style-drawer-bg-tip.k-ssw-style-drawer-bg-tip {
  margin: 0;
  color: var(--color-neutral-50);
}

.k-ss2-style-drawer-section .k-ss2-style-drawer-setting:not(:last-child) {
  margin-bottom: var(--b-gap2);
}

.k-ss2-style-drawer-setting-with-toggle {
  display: flex;
  justify-content: space-between;
}

.k-ss2-style-drawer-setting-with-toggle > div:first-child {
  margin-right: var(--b-gap2);
}

.k-ss2-style-drawer-setting-with-toggle > div:first-child > p:first-child {
  margin-bottom: var(--b-gap-micro);
}

.k-ss2-style-drawer-setting-extra {
  margin-top: var(--b-gap2);
}

.k-ss2-style-drawer-setting-extra1 {
  margin-top: var(--b-gap1);
}

.k-ss2-style-input-grid.--hook {
  display: grid;
  grid-auto-rows: auto 35px;
}

.k-klogin-logo-preview .k-library-org-logo__preview {
  background: var(--color-neutral-20);
}

.k-library--kandji-login__help-window-subheader {
  white-space: pre-line;
}

.k-library-banner {
  margin-top: 8px;
}
