/* ========================= */
/* ==========!GRID========== */
/* ========================= */

/* ----------!BREAK---------- */
$grid-breakpoints: (
  // PHONE	0
  xs: 0,
  // TABLET	576px
  sm: 576px,
  // CLASSIC	768px
  md: 768px,
  // LAPTOP	992px
  lg: 992px,
  // DESKTOP	1200px
  xl: 1200px,
  // WIDE		1440px
  hg: 1440px,
  // CINEMA	1712px
  gt: 1712px
) !default;

/* ========================= */
/* ==========!FONT========== */
/* ========================= */

/* ----------!FAMILY---------- */
$font-family-1: var(--font-family-primary);
$font-family-2: var(--font-family-primary);
$font-family-3: 'SF Pro Display', sans-serif;
$font-family-4: 'SF Pro Text', sans-serif;

/* ----------!WEIGHT---------- */
$font-weight-1: 300; // Light
$font-weight-2: 400; // Regular
$font-weight-3: 500; // Medium
$font-weight-4: 600; // SemiBold
$font-weight-5: 700; // Bold

/* ========================== */
/* ==========!COLOR========== */
/* ========================== */

/* ----------!SHADE---------- */
$color-transparent: transparent;
$color-white: #fff;
$color-grey-1: #eef0f6;
$color-grey-2: #e5e5e5;
$color-grey-3: #f6f7f9;
$color-grey-4: #8f97ae;
$color-grey-5: #c9cdd9;
$color-grey-6: #e1e6f4;
$color-black: #000;

/* ----------!BRAND---------- */
$color-brand-marengo-ultradark: #1a1d25ff;
$color-brand-marengo-dark: #1a1d2599;
$color-brand-marengo-medium: #1a1d2566;
$color-brand-marengo-light: #1a1d2526;
$color-brand-marengo-ultralight: #1a1d250d;
$color-brand-green-dark: #00909e;
$color-brand-slate: #b8bdca;
$color-brand-yellow-dark: #ffb627;
$color-brand-orange: #f56f15;

/* ----------!SYSTEM---------- */
$color-system-information: var(--color-neutral-60);
$color-system-link: #0057da;
$color-system-link-light: #0057da66;
$color-system-link-hover: #0065ff;
$color-system-link-active: #034cb9;
$color-system-success: #37ac2c;
$color-system-success-light: #dcf3da;
$color-system-error: #ee3434;
$color-system-error-hover: #ff4d4d;
$color-system-error-active: #ee3434;
$color-system-error-light: #ee343466;
$color-system-warning: #f56f15;
$color-system-warning-light: #fff2e5;

/* ========================== */
/* ==========!STYLE========== */
/* ========================== */

/* ----------!TEXT---------- */
$font-family-text: $font-family-1;
$font-weight-text: $font-weight-2;
$font-size-text: 14px;
$line-height-text: 25px;

/* ----------!HEADLINE---------- */
$font-family-headline: $font-family-1;
$font-weight-headline: $font-weight-3;
$font-size-headline: $font-size-text;
$line-height-headline: $line-height-text;

/* ----------!TITLE---------- */
$font-family-title: $font-family-2;
$font-weight-title: $font-weight-5;
$font-size-title: 26px;
$line-height-title: 30px;

/* ----------!BUTTON---------- */
$font-family-button: $font-family-2;
$font-weight-button: $font-weight-5;
$font-size-button: 14px;
$line-height-button: 20px;

/* ----------!BREADCRUMB---------- */
$font-family-breadcrumb: $font-family-2;
$font-weight-breadcrumb: $font-weight-5;
$font-size-breadcrumb: 10px;
$line-height-breadcrumb: 15px;

/* ----------!COLOR---------- */
$color-divider: $color-brand-marengo-ultralight;
$color-link: $color-system-link;

/* ----------!BORDER---------- */
$border-width-button-default: 2px;
$border-width-button-solid: 2px;
$border-width-divider: 1px;
$border-radius-dull: 5px;
$border-radius-dull-small: 3px;

/* ----------!PADDING---------- */
$padding-width-button: 20px;

/* ----------!HEIGHT---------- */
$height-button: 40px;

/* ----------!SPACE---------- */
$space-input: 12px;
$space-paper: 24px;
$space-remote: 50px;
$space-header: 18px $space-paper;
$space-edge-item: 32px;

/* ----------!SIZE---------- */
$size-icon-default: 20px;
/*$size-sidebar-opened: 256px;
$size-sidebar-closed: 70px;*/

/* =========================== */
/* ==========!EFFECT========== */
/* =========================== */

/* ----------!SHADOW---------- */
$shadow: 0 0 3px 0 rgba(150, 150, 150, 0.3);
