.k-ks-link-preview {
  background: var(--color-neutral-0);
  border-radius: 8px;
  width: 170px;
  padding: var(--b-gap) var(--b-gap1) var(--b-gap);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 174px;
  margin-right: var(--b-gap);
}

.k-ks-link-preview.k-ks-link-preview--editing {
  margin-right: 0;
}

.k-ks-link-preview.--last-item {
  margin-right: 0;
}

div.k-ks-link-preview.k-ks-link-preview.k-ks-link-preview--invalid,
div.k-ks-link-preview.k-ks-link-preview.k-ks-link-preview--invalid:hover {
  border: 2px solid var(--color-red-50);
}

.k-ks-link-preview.k-ks-link-preview--editing {
  width: 170px;
  min-height: 170px;
  border: 2px solid var(--color-neutral-30);
  position: relative;
}

.k-ks-link-preview.k-ks-link-preview--editing:not(
    .k-library-item-link--selected
  ):hover {
  border-color: var(--color-neutral-50);
  cursor: pointer;
}

.k-ks-link-preview--editing.k-library-item-link--selected {
  border-color: var(--color-blue-70);
}

.k-ks-link-preview.k-library-new-success-link {
  padding: 0;
  border: none;
}

.k-ks-link-preview.k-library-new-success-link a {
  width: 100%;
  height: 170px;
}

.k-ks-link-preview.k-library-new-success-link a:hover {
  opacity: 0.8;
}

.k-ks-link-preview.k-library-new-success-link a:active {
  opacity: 1;
}

.k-ks-link-preview__actions {
  position: absolute;
  top: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 9px 0 10px;
  min-height: 19px;
}

.k-ks-link-preview__delete {
  color: var(--color-red-50);
  font-size: 12px;
  height: 12px;
}

.k-ks-link-preview__delete:hover {
  text-decoration: none;
  opacity: 0.8;
  color: var(--color-red-60);
}

.k-ks-link-preview__reorder {
  height: 11px;
}

.k-ks-link-preview__reorder:hover {
  cursor: move;
}

.k-ks-link-preview > img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}

.k-ks-link-preview.k-ks-link-preview--editing > img {
  position: relative;
  top: -4px;
  width: auto;
  height: 40px;
  object-fit: contain;
}

.k-ks-link-preview.k-ks-rocket-link > img {
  width: 35px;
}

.k-ks-link-preview > h3 {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 9.73704px;
  line-height: 12px;
  margin-top: var(--b-gap1);
  margin-bottom: 0;
  max-width: 160px;
  word-break: break-word;
  text-align: center;
}

.k-ks-link-preview.k-ks-link-preview--editing > h3 {
  font-size: 11px;
  margin-top: 7px;
}

.k-ks-link-preview > p {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 9.73704px;
  line-height: 12px;
  margin-top: var(--b-gap-micro);
  color: rgba(0, 0, 0, 0.28);
  margin-bottom: var(--b-gap-tiny);
  padding: 0 var(--b-gap-tiny);
}

.k-ks-link-preview.k-ks-link-preview--editing > p {
  text-align: center;
  margin-top: 6px;
  font-size: 11px;
  line-height: 14px;
}

.k-ks-link-preview > .b-alink {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 0;
  display: block;
  margin-top: auto;
  text-align: center;
  color: rgba(1, 122, 255, 1); /* not part of our DS */
}

.k-ks-link-preview > .b-alink {
  letter-spacing: -0.1px;
  text-transform: uppercase;
  background: #f2f2f7;
  border-radius: 15px;
  padding: 7px 10px;
  min-width: 80px;
  min-height: 24px;
  max-height: 24px;
}

/* trying to correct the issue with the font */
.k-ks-link-preview > .b-alink > span {
  line-height: 0px;
  top: 5px;
  position: relative;
}

.k-ks-link-preview.k-ks-link-preview--editing > .b-alink {
  font-size: 11px;
}

.k-ks-link-preview > .b-alink:hover {
  text-decoration: none;
}

.k-ks-link-preview.k-ks-link-preview--dark {
  background: rgba(30, 30, 30, 1);
}

.k-ks-link-preview.k-ks-link-preview--dark > p,
.k-ks-link-preview.k-ks-link-preview--dark > h3 {
  color: var(--color-neutral-0);
}

.k-ks-new-link-card {
  margin-left: 0;
  position: relative;
  background: white;
}
