.adcs-d__cert-container {
  display: flex;
  gap: var(--b-gap1);
  align-items: center;
}

.adcs-d__cert-image {
  height: 48px;
  width: 48px;
  object-fit: contain;
}

.adcs-d__cert-name {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adcs-d__cert-date {
  margin-top: var(--b-gap-micro);
  color: var(--color-neutral-60);
  font-weight: normal;
}

.k-ethernet-adcs-panel-button__icon {
  height: 48px;
  width: 48px;
}
