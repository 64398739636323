.b-select__group-heading-label {
  color: var(--color-neutral-100);
  font-family: 'Sharp Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
}

.b-select__option--is-disabled .b-select__option-label {
  color: var(--b-primary-marengo-medium);
}

.b-select__option-label {
  flex-grow: 2;
}

.b-select__single-value {
  display: flex;
  width: 90%;
}

.device-icon svg {
  fill: var(--b-system-slate-dark);
}

.device-icon-disabled svg {
  fill: var(--b-primary-marengo-light);
}
