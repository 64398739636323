/* ============================== */
/* ==========!OFFCANVAS========== */
/* ============================== */

/* ----------!MAIN---------- */
.spring-offcanvas {
  background-color: $color-white;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  z-index: 999999;
  box-shadow: $shadow;
}

/* ----------!DEFAULT---------- */
@include media-breakpoint-up(md) {
  .spring-offcanvas-default {
    max-width: 560px;
  }
}
/* ----------!MEDIUM---------- */
@include media-breakpoint-up(lg) {
  .spring-offcanvas-medium {
    max-width: 750px;
  }
}

/* ----------!WIDE---------- */
@include media-breakpoint-up(xl) {
  .spring-offcanvas-wide {
    max-width: 1136px;
  }
}

/* ============================ */
/* ==========!CONTENT========== */
/* ============================ */

/* ----------!PAPER---------- */
.spring-offcanvas .paper {
  padding-bottom: 81px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

/* ----------!ASIDE---------- */
.theme-form-aside {
  z-index: 1;
}
.spring-offcanvas .theme-form-aside .btn-close {
  margin-left: auto;
}

/* ----------!HEADER---------- */
.spring-offcanvas .theme-form-header {
  border-bottom: 1px solid $color-divider;
  position: relative;
}

// we need this trick because shadow should be only on bottom side
.spring-offcanvas .theme-form-header.is-sticky:after {
  content: '';
  position: absolute;
  width: 100%;
  height: 10px;
  z-index: 1;
  display: block;
  background: linear-gradient(180deg, rgba(26, 29, 37, 0.3), transparent);
}
.spring-offcanvas .theme-form-header .theme-form-title {
  margin-top: -4px;
  margin-bottom: -6px;
  font-family: $font-family-title;
  font-weight: $font-weight-title;
  font-size: $font-size-title;
  line-height: $line-height-title;
  color: $color-brand-marengo-ultradark;
}
.spring-offcanvas
  .theme-form-header
  .theme-form-title
  + .theme-form-description {
  padding-top: 24px;
}
.spring-offcanvas .theme-form-header .theme-form-title + .banner {
  margin-top: $space-paper;
}
.spring-offcanvas .theme-form-header .theme-form-description {
  color: $color-brand-marengo-dark;
}

/* ----------!BODY---------- */
.spring-offcanvas .theme-form-body {
  @include prettyScroll();
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
.hubspot-buffer-bottom {
  padding-bottom: 100px !important;
}

/* ----------!FOOTER---------- */
.spring-offcanvas .theme-form-footer {
  background-color: $color-white;
  border-top: 1px solid $color-divider;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  z-index: 120;
}
.spring-offcanvas .theme-form-footer > .node {
  display: flex;
  justify-content: flex-end;
}

/* ----------!SPACE---------- */
.spring-offcanvas .theme-form-aside + .theme-form-header {
  margin-top: -30px;
}

/* ========================= */
/* ==========!FORM========== */
/* ========================= */

/* ----------!GROUP---------- */
.spring-offcanvas .theme-form-group + .theme-form-group,
.spring-offcanvas .theme-form-group + .theme-form-text,
.spring-offcanvas .theme-form-text + .theme-form-text,
.spring-offcanvas .theme-form-text + .theme-form-group {
  padding-top: $space-paper * 2;
}

/* ----------!SPACE---------- */
.spring-offcanvas .theme-form-text-label + .theme-form-text-detail,
.spring-offcanvas .theme-form-textarea-label + .theme-form-textarea-detail {
  padding-top: ($space-paper - 5px);
}
.spring-offcanvas .theme-form-group-label + .theme-form-check,
.spring-offcanvas .theme-form-group-label + .theme-form-set,
.spring-offcanvas .theme-form-text-label + .dropdown,
.spring-offcanvas .theme-form-text-detail + .dropdown,
.spring-offcanvas .theme-form-text-label + .theme-form-text-input,
.spring-offcanvas .theme-form-text-label + .theme-form-password-wrapper,
.spring-offcanvas .theme-form-text-detail + .theme-form-text-input,
.spring-offcanvas .theme-form-text-detail + .theme-form-password-wrapper,
.spring-offcanvas .theme-form-textarea-label + .theme-form-textarea-input,
.spring-offcanvas .theme-form-textarea-detail + .theme-form-textarea-input {
  margin-top: $space-paper;
}
.spring-offcanvas .theme-form-text + .theme-form-help,
.spring-offcanvas .theme-form-textarea + .theme-form-help,
.spring-offcanvas .theme-form-check + .theme-form-help,
.spring-offcanvas .theme-form-set + .theme-form-help {
  padding-top: $space-paper;
}
