.tab-parameters-blocker {
  display: flex;
  justify-content: center;
  height: 300px;
  padding-top: 70px;
  background: $white;
  border-radius: 5px;

  box-shadow: rgba(16, 36, 94, 0.25) 0px 50px 100px -30px;

  .wrapper {
    display: flex;
    max-width: 500px;
    flex-direction: column;

    .message {
      font-weight: 200;
      font-size: 26px;
    }

    a {
      color: $darkest-blue;
    }
  }
}

.blueprint-tab-parameters-blocker {
  display: flex;
  justify-content: center;
  height: 200px;
  padding-top: 70px;
  background: inherit;
  border-radius: 5px;

  .wrapper {
    display: flex;
    max-width: 500px;
    flex-direction: column;

    .message {
      font-weight: 200;
      font-size: 26px;
    }

    a {
      color: $darkest-blue;
    }
  }
}
