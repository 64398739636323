/* ========================= */
/* ==========!VIEW========== */
/* ========================= */

/* ----------!STATUS---------- */
.view-status > .filter-block {
  margin-bottom: 20px;
}
.view-status > .filter-block .filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
