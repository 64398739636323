.fe-library-tabs .b-tabs__tab {
  min-width: 99px;
  text-align: center;
  padding: 5px 0px 12px 0px;
}

.k-library.k-library--kandji-setup {
  min-width: 900px;
  margin: 0 auto;
  --ks-apple-margin: 20px;
  /* dimensions brandon using for the logos */
  --brandon-help-logo: 86px;
  --brandon-install-logo: 44px;
}

@media (max-width: 1711px) {
  .k-library.k-library--kandji-setup {
    width: 100%;
  }
}

@media (min-width: 1712px) {
  .k-library.k-library--kandji-setup {
    width: 1360px;
  }
}

.b-chip-active-md,
.b-chip-active-md.b-chip-active-md--disabled {
  transition: all 250ms ease-in-out;
  transition-property: opacity;
}
.b-chip__root.b-chip--kind-active.b-chip-active-md--disabled {
  opacity: 0.5;
}

.b-chip__root:first-child {
  margin-left: 0;
}

.b-chip__root.b-chip--kind-inactive.b-chip--size-md.b-chip-active-md--disabled {
  opacity: 0.5;
}

.k-ks-toggle {
  background: #eef0f6;
  border-radius: 4px;
  opacity: 1;
}

.k-ks-toggle.--hidden {
  opacity: 0;
}

.k-ks-toggle,
.k-ks-toggle.--hidden {
  transition: all 250ms ease-in-out;
  transition-property: opacity;
}

.b-txt.b-txt.k-ks-dark-blue {
  color: var(--color-neutral-60);
}

.k-ks-toggle-button.k-ks-toggle-button {
  padding: 8px;
  border-radius: 4px;
  width: 114px;
}

.k-ks-toggle-button.--active {
  background: #fff;
  border: 2px solid #eef0f6;
  color: #1a1d25;
}

.k-ks-toggle-button:focus {
  outline: 0;
}

.k-library--kandji-setup .b-library__setting-rows {
  padding: var(--b-gap2);
}

.k-library--kandji-setup .b-library-summary__body {
  padding: var(--b-gap2) var(--b-gap2) var(--b-gap3);
}

.k-library--kandji-setup .b-library-summary__publisher {
  grid-row-gap: 5px;
}

.k-library--kandji-setup .b-library-summary__assign {
  margin-top: var(--b-gap2);
}

.k-library--kandji-setup
  .library__setting-card__header.b-library__setting-card__header {
  min-height: 0;
}

.k-library--kandji-setup .b-library__setting-card__header h3 {
  line-height: 1.2;
}

/* for thd old activity table */
.k-library--kandji-setup .block > .zone {
  padding-top: 0;
}

/* side panel header shadow */
div.b-side-panel-layout__header {
  box-shadow: inset 0px -1px 0px rgba(26, 29, 37, 0.05);
  transition: box-shadow 250ms ease-in-out;
}
.b-side-panel-layout__header.--with-shadow {
  box-shadow:
    inset 0 -1px 0 rgba(26, 29, 37, 0.05),
    var(--b-shadow-bottom);
  transition: box-shadow 250ms ease-in-out;
}

.k-library--kandji-setup .b-select-multi-menu {
  margin-top: 0;
}

.k-library--kandji-setup .b-select-multi__add-new {
  margin-left: 4px;
}
