/* ========================= */
/* ==========!MAIN========== */
/* ========================= */

/* ----------!H1---------- */
h1 {
  margin-top: -4px;
  margin-bottom: -6px;
  margin-left: -2px;
  margin-right: -2px;
  font-family: $font-family-title;
  font-weight: $font-weight-title;
  font-size: $font-size-title;
  line-height: $line-height-title;
  color: $color-brand-marengo-ultradark;
}
