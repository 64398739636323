.company-settings-container {
  padding: 0;
  .company-settings-container-row {
    margin-top: 15px;
    margin-bottom: 15px;
    .col {
      input[type='checkbox'],
      button {
        vertical-align: middle;
        //vertical-align: -.3rem;
      }
    }
  }

  .company-settings-container-row:last-child {
    margin-top: 0;
    margin-bottom: 0;
  }

  .company-settings-title {
    line-height: 31px;
    font-weight: 400;
    display: flex;
    align-items: center;
    &:not(:empty) {
      margin-right: 20px;
    }
  }

  .company-settings-field {
    display: flex;
    margin: 0;
    padding: 0;
    button {
      i {
        margin-right: 0;
      }
      margin-right: 5px;
    }
    .form-group {
      margin-bottom: 0;
    }
    .field-error-msg {
      font-weight: 400;
    }
  }

  .company-settings-witherror {
    flex-direction: column;
  }

  button {
    //margin: 15px;
    //margin: 35px 15px;
  }
}

.company-btns {
  margin: 35px 0;
}
