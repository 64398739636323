.login-form {
  width: 346px;
}

.google-signin-button,
.ms-signup-btn {
  display: inline-block;
  background: #ffffff;
  color: rgba(0, 0, 0, 0.54);
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid #b3b3b3;
  transition-duration: 0.2s;
}

.google-signin-button:hover,
.ms-signup-btn:hover {
  cursor: pointer;
  border: 1px solid $darkest-blue;
}

.google-signin-button-logo,
.ms-signup-logo {
  display: inline-block;
  padding-right: 18px;
}

.google-signin-button-text,
.ms-signup-btn-text {
  font-size: 14px;
}

.login-or,
.login-forgot-password {
  color: $darkest-blue;
  margin: 30px 0 37px 0;
}

.login-forgot-password {
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
}

.sign-in {
  font-family: var(--font-family-primary);
  @-moz-document url-prefix() {
    input[type='password'] {
      font-family: 'pass', Helvetica, Arial, sans-serif;
      font-size: 14px;
    }
  }
  .icon {
    .center-block {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .image-responsive {
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
  .heading {
    text-align: center;
    h2 {
      font-weight: 400;
      font-size: 50px;
    }
  }
  .signin-options {
    .title {
      font-weight: 500;
    }
    .registration-title {
      font-size: 1.5rem;
    }
    .btn {
      font-weight: bold;
      transition:
        background-color 0.3s ease,
        color 0.3s ease,
        border-color 0.3s ease,
        fill 0.3s ease;
      border: 2px solid $primary-blue;
      display: block;
      margin: 35px 0;
      width: 100%;
      font-size: 13px;
      text-transform: uppercase;
      border-radius: 27px;
      letter-spacing: 0.2em;
      height: 50px;
      &:hover,
      &:active,
      &:focus {
        box-shadow: none !important;
        background-color: $orange-selected !important;
        border-color: $orange-selected !important;
        color: #fff;
        outline: 0 !important;
        fill: white;
      }
      &.btn-google {
        margin-top: 42px;
      }
      &.btn-tertiary {
        path {
          fill: white;
        }
      }
      svg {
        height: 26px;
        margin: -10px 0 -5px 12px;
        vertical-align: middle;
        //transition: fill 0.3s ease;
        &:hover {
          fill: white;
        }
      }
    }
    .or {
      display: block;
      position: relative;
      text-align: center;
      padding: 40px 0;
      &::before,
      &::after {
        background-color: $primary-blue;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        top: 50%;
        width: calc(50% - 36px);
      }
      &::after {
        left: auto;
        right: 0;
      }
    }
    .or-horizontal {
      width: 100%;
      text-align: center;
      border-bottom: 1px solid $black;
      line-height: 0.1em;
      margin: 10px 0 20px;

      span {
        background: $grey-80;
        padding: 0 10px;
      }
    }
    @media screen and (min-width: 768px) {
      .or {
        height: 230px;
        line-height: 72px;
        padding: 79px 0 0;
        &::before,
        &::after {
          height: calc(50% - 45px);
          left: 50%;
          right: 50%;
          top: 16px;
          width: 1px;

          background-color: $primary-blue;
          content: '';
          position: absolute;
        }
        &::after {
          bottom: 16px;
          top: auto;
        }
      }
    }
    .form-row {
      label {
        z-index: 1;
        letter-spacing: 0.2em;
        -webkit-transition: transform 150ms ease;
        -o-transition: transform 150ms ease;
        transition: transform 150ms ease;
        -webkit-transform-origin: 0 0 0;
        -moz-transform-origin: 0 0 0;
        -ms-transform-origin: 0 0 0;
        transform-origin: 0 0 0;
        font-size: 13px;
        font-weight: 700;
        line-height: 50px;
        padding-left: 30px;
        position: absolute;
        //top: -57px;
        //left: -25px;
        text-transform: uppercase;
        will-change: transform;
        &.used {
          -moz-transform: translateY(-30px) scale(0.8);
          -ms-transform: translateY(-30px) scale(0.8);
          -webkit-transform: translateY(-30px) scale(0.8);
          transform: translateY(-30px) scale(0.8);
        }
      }
      input {
        z-index: 2;
        background-color: transparent;
        height: 50px;
        padding-left: 30px;
        padding-right: 30px;
        -moz-border-radius: 25px;
        -webkit-border-radius: 25px;
        border-radius: 25px;
        -webkit-box-shadow: inset -2px -2px 6px rgba(0, 0, 0, 0.15);
        box-shadow: inset -2px -2px 6px rgba(0, 0, 0, 0.15);
        &:-webkit-autofill {
          transition: background-color 0.1s ease-in-out 0s;
        }
      }
      &[type='email'] {
        margin-top: 42px;
      }
    }
  }
}
