/* =========================== */
/* ==========!STICKY========== */
/* =========================== */

// TODO: refactor .sticky to have margin-bottom: -5px; as well while spacing between elements use +
// TODO: phase out .sticky-background in favor of having the children wrapped in their own background-color
// TODO: possibly combine .sticky-shadow and .sticky-background and be part of the children

/* ----------!MAIN---------- */
.sticky-on {
  margin-bottom: -5px; // this is set to show the 5px shadow
  padding-bottom: 5px; // this is set to show the 5px shadow
  position: sticky;
  overflow: hidden;
  z-index: 120;
}
.sticky-on > .sticky-shadow {
  transition: box-shadow 0.3s ease-in-out;
}
.sticky-on.stuck {
  border-radius: 0 !important;

  > * {
    box-shadow: 0px 0px 10px rgba(26, 29, 37, 0.15);
  }
}
.sticky-on.stuck > .sticky-shadow {
  box-shadow: $shadow;
}

/* ----------!ORIGIN---------- */
.sticky-card {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  margin: -20px -30px 15px;
}
.sticky-card .sticky-background {
  background-color: #fff;
}
