/* =========================== */
/* ==========!SHROUD========== */
/* =========================== */

/* ----------!MAIN---------- */
.shroud {
  background-color: $color-brand-marengo-medium;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
  user-select: none;
}
