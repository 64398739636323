.settings {
  > div {
    padding-left: 40px;
  }
  h5 {
    padding: 20px 0;
    font-weight: 700;
    border-bottom: 1px solid $light-grey-border;
  }
  .settings-contact-info {
    border-bottom: 1px solid $light-grey-border;
  }
}

.reset-password-btn {
  margin-left: 15px;
  margin-top: 8px;
}
