.reset-password-container {
  min-height: 900px;
  text-align: center;
  .reset-password-title {
    margin: 50px auto 110px auto;

    font-size: 25px;
  }
  .form-group {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 400px;
    margin-bottom: 30px;
  }
  input {
    width: 400px;
  }
  .password-reset-btn {
    margin: 50px auto 50px auto;
    &:active {
    }
  }
  .reset-password-cancel {
    &:hover {
      cursor: pointer;
    }
  }
}
