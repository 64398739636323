.pin-input {
  display: flex;
  flex-direction: row;
  margin-top: var(--b-gap-tiny);
}

.pin-number {
  align-items: center;
  border: 1px solid var(--color-neutral-20);
  border-radius: 4px;
  box-sizing: border-box;
  color: var(--color-neutral-60);
  display: flex;
  font-size: 20px;
  height: 48px;
  justify-content: center;
  margin-right: var(--b-gap1);
  width: 48px;
}
