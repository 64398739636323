.day-picker {
  $widthDayPicker: 360px;
  $calendarIcon: url(../../assets/img/library/calendarIcon.svg);
  $calendarArrow: url(../../assets/img/library/calendarArrow.svg);

  max-width: $widthDayPicker;
  position: relative;

  input {
    background: #fff $calendarIcon no-repeat 320px 50%;
    border: 2px solid #1a1d2526; /* $color-brand-marengo-light */
    border-radius: 5px;
    padding: 2px 18px 0;
    width: 100%;
    height: 40px;
    line-height: 25px;
    color: #1a1d25ff; /* $color-brand-marengo-ultradark */
    opacity: 1;
    outline: none;
  }

  input:disabled {
    background: #fff $calendarIcon no-repeat 320px 50% !important;
    border-color: #1a1d250d !important; /*$color-brand-marengo-ultralight*/
    color: #1a1d2599 !important; /* $color-brand-marengo-dark */
    -webkit-text-fill-color: #1a1d2599 !important; /* $color-brand-marengo-dark */
    opacity: 1;
    cursor: not-allowed;
  }
  input::placeholder {
    color: #1a1d2599; /* $color-brand-marengo-dark */
    -webkit-text-fill-color: #1a1d2599; /* $color-brand-marengo-dark */
  }
  input:hover {
    border-color: #1a1d2566; /* $color-brand-marengo-medium */
  }
  input:focus {
    border-color: #0057da; /* $color-system-link */
  }
  input::placeholder {
    color: #1a1d2599; /* $color-brand-marengo-dark */
    -webkit-text-fill-color: #1a1d2599; /* $color-brand-marengo-dark */
  }
  &.error input {
    border-color: #ee3434; /* $color-system-error */
  }
  .DayPickerInput {
    display: block;
  }
  .DayPickerInput-OverlayWrapper {
    margin-top: 10px;
    min-height: 250px;
    position: absolute;
    top: 100%;
    left: 0;
    float: left;
    z-index: 9999;
  }
  .picker {
    width: $widthDayPicker;
    .DayPicker-NavButton--prev {
      position: absolute;
      left: 23px;
      margin: 0;
      top: 14px;
      background-image: $calendarArrow;
    }
    .DayPicker-NavButton--next {
      position: absolute;
      right: 23px;
      margin: 0;
      top: 14px;
      transform: rotate(180deg);
      background-image: $calendarArrow;
    }
    .DayPicker-Body {
      margin-top: -10px;
    }
    .DayPicker-Month {
      width: 100%;
      table-layout: fixed;
      padding: 0;
      margin: 0;
      border-collapse: separate;
      -webkit-border-horizontal-spacing: 23px !important;
      -webkit-border-vertical-spacing: 5px !important;
      border-spacing: 14px;
    }
    .DayPicker-Caption {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 16px;
      line-height: 20px;
      color: #1a1d25;
      text-align: center;
      background: rgba(238, 240, 246, 0.5);
      border-radius: 4px 4px 0 0;
      margin: 0;
      padding: 13px;
    }
    .DayPicker-Weekdays {
      font-family: var(--font-family-primary);
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: rgba(26, 29, 37, 0.5);
      &:after {
        position: absolute;
        top: 76px;
        left: 25px;
        height: 1px;
        display: block;
        width: 350px;
        background: rgba(26, 29, 37, 0.05);
        border-right: 1px white;
        content: '';
      }
    }
    .DayPicker-Day {
      font-family: var(--font-family-primary);
      border-radius: 2px;
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      text-transform: uppercase;
      color: #1a1d25;
      cursor: pointer;
      padding: 7px 0 7px 0;
      &:hover {
        background-color: rgba(201, 205, 217, 0.4) !important;
      }
    }
    .DayPicker-Day--disabled {
      color: rgba(26, 29, 37, 0.3);
      cursor: default;
      &:hover {
        background-color: transparent !important;
      }
    }
    .DayPicker-Day--today {
      background-color: #eef0f6 !important;
    }
    .DayPicker-Day--selected {
      color: #ffffff !important;
      background-color: #1a1d25 !important;
      border-radius: 2px;
    }
  }
}
