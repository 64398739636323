/* ========================= */
/* ==========!MAIN========== */
/* ========================= */

/* ----------!NODE---------- */
.node-height-full {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ----------!BLOCK---------- */
.block + .block:not([class*='view']) > .zone,
.shroud + .block > .zone,
.spring-offcanvas + .block > .zone {
  padding-top: $space-paper * 2 !important;
}
/*main > .block + header.information.block > .zone {
  padding-top: 20px;

  .gutter-40 {
    margin-top: -18px;
    margin-bottom: -18px;
  }
}*/

/* ----------!PAPER---------- */
.paper + .paper,
.shroud + .paper,
.spring-offcanvas + .paper {
  margin-top: $space-paper;
}
.banner + .paper {
  margin-top: $space-paper * 2;
}

/* =========================== */
/* ==========!WIDGET========== */
/* =========================== */

/* ----------!STATUS---------- */
.status + .toggle {
  margin-left: $space-paper;
}

/* ============================ */
/* ==========!SECTION========== */
/* ============================ */

/* ----------!PATH---------- */
.path > .zone {
  padding-top: $space-paper * 2;
}

/* ----------!MASTHEAD---------- */
.masthead > .zone {
  padding-top: ($space-paper * 2) - 15; // after refactoring Sidebar, change this to be just $space-paper (20px)
}
.masthead + .feed > .zone {
  padding-top: $space-paper + 2 !important;
}
.masthead + .navigation {
  margin-top: -($space-paper + 10px);
}

/* ----------!NAVIGATION---------- */
// .navigation > .zone {
//   padding-top: ($space-paper * 2) + 15px;
// }
.navigation + .view > .zone {
  padding-top: $space-paper;
}

/* =========================== */
/* ==========!ACTION========== */
/* =========================== */

/* ----------!BUTTON---------- */
.btn-default + .btn-default,
.btn-default + .btn-hollow,
.btn-hollow + .btn-hollow,
.btn-hollow + .btn-default,
.group-hollow > .btn:not(:first-child),
.group-hollow > .btn-group:not(:first-child) {
  margin-left: $space-paper;
}

/* ----------!GROUP---------- */
.group-hollow + .group-hollow {
  border-left: $border-width-divider solid $color-divider;
  margin-left: $space-paper;
  padding-left: $space-paper - $border-width-divider;
}

/* ----------!MIX---------- */
.btn + .group,
.group + .btn,
.group-solid + .group-solid,
.group-solid + .group-hollow,
.group-hollow + .group-solid {
  margin-left: $space-paper * 2;
}
