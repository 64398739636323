/* ======================== */
/* ==========!TAB========== */
/* ======================== */

/* ----------!MAIN---------- */
.theme-table {
  margin: 0;
}
.theme-table th,
.theme-table td {
  outline: none;
  position: relative;
}
.theme-table td {
  padding: 16px $space-paper;
  font-family: var(--font-family-primary);
}
.theme-table th + th:before {
  content: '';
  border-left: 1px solid rgba(26, 29, 37, 0.05);
  position: absolute;
  top: 10px;
  height: calc(100% - 20px);
  left: 0;
}

.theme-table th {
  font-family: var(--font-family-primary);
  font-size: 14px;
  font-weight: 500;
  user-select: none;
  padding-left: 20px;
  .table-header__information {
    display: flex;
    align-items: center;

    .dropdown {
      margin-left: 8px;

      svg {
        fill: $color-brand-marengo-ultradark;
      }
    }
  }
}
.theme-table td {
  border-top: 1px solid $color-divider;
}
.theme-table td:first-of-type {
  font-weight: 400;
}
.theme-table thead th {
  border-top: 0 none $color-transparent;
  border-bottom: 1px solid $color-divider;
  position: relative;

  .table-header__sortable {
    cursor: pointer;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 12px 24px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .table-header__sortable--inner {
      display: flex;
      align-items: center;

      .caret {
        margin-left: 8px;
      }
    }
  }
}

.caret {
  width: 0;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-bottom: 0;
  border-left: 4px solid transparent;
  color: $color-brand-marengo-ultradark;

  &.turned {
    transform: rotate(180deg);
  }
}

.theme-table .dropdown-action .dropdown-menu {
  margin-right: 0;
}

/* ----------!CHIP---------- */
.theme-table .chip {
  width: fit-content;
}

/* ----------!OVERRIDE---------- */
.theme-table .dSaKCW,
.theme-table .eUEupn,
.theme-table .eebMFi {
  min-height: unset !important;
}

.table-footer {
  border-top: 1px solid $color-divider;
  display: flex;
  height: 64px;
  padding: 0 32px;
  align-items: center;
  justify-content: space-between;
  color: $color-brand-marengo-ultradark;

  .kandji-pagination {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    font-family: $font-family-1;
    font-size: 14px;

    .per-page-label {
      margin-right: 12px;
    }

    .per-page-value {
      margin-right: 32px;
    }

    .per-page-select {
      min-width: 80px;
      margin-right: 29px;
    }

    .current-page-indexes {
      margin-right: 42px;
    }

    .pagination-previous-btn {
      margin-right: 32px;
    }

    .icon {
      width: fit-content;
      height: fit-content;
    }

    .pagination-next-btn {
      transform: rotate(180deg);
    }

    .is-active-pagination {
      cursor: pointer;

      path {
        stroke: $color-system-link-active;
        opacity: 1;
      }
    }
  }
}

.table-custom-overlay--wrapper {
  position: relative;
}
.table-custom-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ffffff99;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.table-custom-overlay--wrapper__loading .table-custom-no-data {
  visibility: hidden;
}
.table-custom-no-data {
  padding: 24px 0;

  .table-custom-no-data--descriptions {
    max-width: 400px;
  }

  .table-custom-no-data--beehive {
    width: auto;
    height: auto;

    path {
      stroke: $color-grey-6;
    }

    path:not([stroke-dasharray]) {
      fill: $color-grey-6;
    }
  }
}
