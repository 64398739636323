.k-blueprint-conflict-modal {
  width: 560px;
  overflow-y: scroll !important;

  .b-modal__header_wrapper {
    border-bottom: 2px solid var(--color-neutral-20);
    padding-bottom: var(--b-gap2);

    .k-modal-header {
      .b-txt {
        font-size: 14px;
      }
    }
  }

  .b-modal__content-wrapper {
    padding: 0;
  }
}

.k-library__icon--warning {
  color: var(--color-red-50);
}

.k-library__blueprint-conflicts-modal {
  max-height: 400px;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
}

.k-library__blueprint-conflict-assignment {
  padding: var(--b-gap2) 0;
  margin: 0 var(--b-gap3);
}

div.k-library__blueprint-conflict-assignment:not(:last-of-type) {
  border-bottom: 1px solid var(--color-neutral-20);
}

.k-library__blueprint-conflict-radio-desc {
  margin-left: 0.5ch;
}

.k-library__blueprint-conflict-radio--selected .b-radio-text {
  color: var(--color-blue-50);
}

.k-library__blueprint-conflict-desc {
  display: flex;
  align-items: baseline;
}

.k-library__blueprint-conflicts-item {
  /* The <Radio /> component does not know about min-content, so we have to set a width manually. */
  width: 450px;
}

.k-library__blueprint-conflicts-item .b-flex {
  align-items: center;
}

.k-library__blueprint-conflicts-item .b-library-summary__all-blueprints {
  margin-right: 6px;
}

.k-library__blueprint-conflicts-item
  .b-library-summary__all-blueprints
  .b-chip--kind-info {
  padding-left: 6px;
  padding-right: 6px;
  min-width: unset;
}

.k-library__blueprint-conflicts-item
  .b-library-summary__all-blueprints
  .b-chip--kind-info
  .b-chip__icon {
  margin: 0;
}

.k-library__blueprint-conflicts-instance-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.k-library__blueprint-conflict-assignment .k-library__blueprint-conflict-chip {
  margin-right: 0.5ch;
}

.k-library__blueprint-conflicts-modal-banner {
  background-color: var(--b-system-slate-dark);
  min-width: unset;
}
