/* ============================= */
/* ==========!DROPDOWN========== */
/* ============================= */

/* ----------!MAIN---------- */
.dropdown.show {
  z-index: 9999;
}

.dropdown .placeholder {
  color: $color-brand-marengo-medium;
  padding: 0 6px;
}

.dropdown .dropdown-menu {
  margin: 0;
}

$dropdownArrowSize: 8px;
.dropdown .dropdown-menu-center {
  left: 50%;
  transform: translateX(-50%);

  &.dropdown-menu-with-arrow:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    left: 50%;
    border: $dropdownArrowSize solid black;
    transform: translateX(-50%) rotate(-45deg);
  }
}
.dropdown .dropdown-menu-top {
  margin-top: 10px;

  &.dropdown-menu-with-arrow {
    margin-top: 16px;

    &:after {
      border-color: white white transparent transparent;
      box-shadow: 2px -2px 2px 0 rgba(150, 150, 150, 0.3);
      top: -$dropdownArrowSize;
    }
  }
}
.dropdown .dropdown-menu-bottom {
  margin-bottom: 10px;
  top: unset;
  bottom: 100%;

  &.dropdown-menu-with-arrow {
    margin-bottom: 16px;

    &:after {
      border-color: transparent transparent white white;
      box-shadow: -2px 2px 2px 0 rgba(150, 150, 150, 0.3);
      bottom: -$dropdownArrowSize;
    }
  }
}
.dropdown .dropdown-menu {
  border-color: $color-divider;
  border-radius: $border-radius-dull;
  padding: 15px 0 16px;
  width: 100%;
  color: $color-brand-marengo-ultradark;
  box-shadow: $shadow;
}
.dropdown .dropdown-menu .dropdown-menu-body-scroll {
  @include prettyScroll();
  overflow-y: scroll;
}
.dropdown .dropdown-menu .dropdown-menu-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.dropdown .dropdown-menu .dropdown-menu-list > .dropdown-menu-list-item > .btn {
  padding: 0 $space-input;
  width: 100%;
  justify-content: flex-start;
  font-weight: 400;
  font-family: $font-family-1;
}
.dropdown
  .dropdown-menu
  .dropdown-menu-list
  > .dropdown-menu-list-item:hover
  > .btn {
  background-color: $color-grey-1;
  border-radius: 0;
}

/* ----------!TOGGLE---------- */
.dropdown .dropdown-toggle {
  background-color: $color-transparent;
  border: 0 none $color-transparent;
  padding: 0;
  width: 100%;
  user-select: none;
}
.dropdown .dropdown-toggle:focus {
  outline: none;
}
.dropdown .dropdown-toggle.no-caret::after {
  content: none;
}
.dropdown .dropdown-toggle > .icon > svg {
  fill: $color-system-link;
}

/* ----------!TYPE---------- */
.dropdown-action {
  margin-right: -10px;
}
.dropdown-action .dropdown-toggle {
  margin: -$space-paper 0;
  padding: $space-paper 0;
}
.dropdown-action .dropdown-menu {
  margin-right: -25px;
}

/* ----------!MULTI-SELECT---------- */
.ms-dropdown .dropdown .dropdown-menu {
  padding: 0;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-header {
  border-bottom: 1px solid $color-divider;
  padding-right: ($space-paper - 1px);
  display: flex;
  align-items: center;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-search {
  border: 0 none $color-transparent;
  border-radius: 0;
  padding: 0 ($space-paper - 1px);
  display: block;
  width: 100%;
  height: 40px;
  font-size: $font-size-button;
  font-weight: $font-weight-2;
  line-height: 30px;
  color: $color-brand-marengo-ultradark;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-search:focus {
  color: $color-brand-marengo-ultradark;
  box-shadow: none;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-search::placeholder {
  color: $color-brand-marengo-dark;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-search + .icon > svg {
  fill: $color-grey-2;
  stroke: $color-grey-2;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-body,
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-footer {
  padding: ($space-paper - 5px) ($space-paper - 1px) ($space-paper - 4px);
}

.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-footer {
  padding-top: 0;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-fallback {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-footer > p,
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-fallback > p {
  margin: 0;
}
.ms-dropdown .dropdown .dropdown-menu .dropdown-menu-fallback > p > a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ms-dropdown .dropdown .chip > span {
  font-weight: $font-weight-3;
}
.ms-dropdown .dropdown .field-list-caret {
  padding: 0 10px;
  display: flex;
}
.ms-dropdown .dropdown .field-list-caret::after {
  border-top: 5px solid;
  border-right: 5px solid $color-transparent;
  border-bottom: 0;
  border-left: 5px solid $color-transparent;
  color: $color-brand-marengo-ultradark;
  content: '';
}
.ms-dropdown .dropdown .dropdown-toggle:disabled .field-list-wrap {
  border-color: $color-brand-marengo-ultralight;
}
.ms-dropdown .dropdown .dropdown-toggle:disabled .field-list-caret::after {
  color: $color-brand-marengo-light;
}
.ms-dropdown .dropdown.show .field-list-wrap {
  border-color: $color-system-link;
}
.ms-dropdown .dropdown.show .field-list-caret::after {
  border-top: 0;
  border-bottom: 5px solid;
  color: $color-system-link;
}
