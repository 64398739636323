.li-library-list {
}

.li-library-list__category-grid {
  display: grid;
  grid-gap: var(--b-gap3);
}

.li-library-list__category-items-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(357px, 1fr));
  grid-gap: var(--b-gap2);
}

.li-library-list_category-header {
  font-size: 20px;
  line-height: var(--b-gap2);
}

/* temporary until design system updates */
.li-library-list__category-button {
  height: var(--b-gap2);
  width: var(--b-gap2);
}

.li-library-list__category-button > button {
  color: var(--color-neutral-70);
  background-color: var(--color-neutral-20);
  border-radius: 48px;
}

.li-library-list__category-button > button:hover {
  background-color: var(--color-neutral-30);
}

.li-library-list__category-button > button,
.li-library-list__category-button > button:hover {
  width: 24px;
  height: 24px;
  padding: 2px !important;
}

.li-library-list__category-button > button > i,
.li-library-list__category-button > button > i:before {
  height: 20px;
  width: 20px;
}
