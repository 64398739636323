.slider {
  display: flex;
  flex-direction: column;
}

.slider label {
  color: $color-brand-marengo-ultradark;
  display: flex;
  align-items: center;
}

.slider input[type='number'] {
  margin: 0 10px;
  appearance: none;
  outline: none;
  background: $color-white;
  border: 2px solid $color-brand-marengo-light;
  padding: 2px 16px 0;
  border-radius: 4px;
  width: 55px;
  height: 40px;
  text-align: right;
  color: $color-brand-marengo-ultradark;
  &:hover {
    border-color: $color-brand-marengo-medium;
  }
  &:focus {
    border-color: $color-system-link;
  }
  &:disabled {
    border-color: $color-brand-marengo-ultralight;
  }
}

.slider input[type='range'] {
  width: 100%;
  margin-top: $space-paper * 2;
  -webkit-appearance: none;
  background-color: $color-grey-2;
  height: 4px;
  border-radius: 5px;
}

.slider input[type='range']:focus {
  outline: none;
}

.slider input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 24px;
  height: 28px;
  background: url(../../theme/images/slider.svg) no-repeat; // must be relative to web root
  cursor: -webkit-grab;
}

.slider input[type='range']::-moz-range-thumb {
  -moz-appearance: none;
  width: 24px;
  height: 28px;
  background: url(../../theme/images/slider.svg) no-repeat; // must be relative to web root
  cursor: -moz-grab;
}

.slider input[type='range']:disabled::-webkit-slider-thumb {
  cursor: not-allowed;
}

.slider input[type='range']:disabled::-moz-range-thumb {
  cursor: not-allowed;
}

%slider-markings {
  display: flex;
  justify-content: space-between;
  margin-top: 14px;
}

.slider .slider-markings {
  padding: 0 11px;
  @extend %slider-markings;
}

.slider .slider-labels {
  @extend %slider-markings;
}

.slider .slider-labels span {
  text-align: center;
  max-width: 100px;
  overflow-wrap: break-word;
  font-size: 10px;
}

.slider .slider-markings i {
  position: relative;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 1px;
  background: $color-brand-marengo-ultradark;
  height: 5px;
  line-height: 40px;
}

.slider > .label {
  margin: 0;
  display: flex;
  align-content: center;
}

.slider > .label > .count {
  margin-left: 8px;
  padding-top: 1px;
}

.slider > .label > .count > span {
  padding-left: $space-paper;
  padding-right: $space-paper;
  font-weight: $font-weight-3;
}
