/*
 * Helper class to justify left align all parent and nested helper copy on large
 * screens for one level of nested controls
 */
.k-ade-secondary-ctrl-grid-gap-align
  .b-library__setting-secondary-controls-row {
  grid-template-columns: 1fr 1fr;
  column-gap: 0;
}

.k-ade-prefill.k-ade-prefill {
  margin-bottom: var(--b-gap2);
}

.k-ade-prefill-nested-ctrl {
  margin-top: 0;
}

.k-ade-nested-row-helper {
  background: none;
  padding: 20px 0 0 0;
  grid: auto;
}

.k-hidden {
  display: none;
}

@media (max-width: 1440px) {
  .k-ade-nested-helper__txt {
    padding-left: var(--nested-ctrl-left-pad);

    --nested-ctrl-left-pad: 36px;
  }

  .k-ade-prefill-helper__txt {
    max-width: 490px;
  }

  .k-ade-prefill-helper.--last .k-ade-prefill-helper__txt,
  .k-ade-prefill-helper.--first .k-ade-prefill-helper__txt {
    padding-left: 0;
  }

  .k-ade-prefill.k-ade-prefill {
    margin-bottom: var(--b-gap-micro);
  }

  .k-ade-nested-row-helper {
    padding: 0;
  }
}

.k-ade-match-group-row {
  background: transparent;
  padding-left: 0;
  border-top: 1px solid var(--color-neutral-20);
  margin-top: var(--b-gap2);
  padding-bottom: 0;
}

@media (min-width: 1441px) {
  .k-ade-require-auth-helper.k-ade-require-auth-helper,
  .k-ade-match-group-row .b-library-second-ctrl-row-helper {
    grid-area: 1/2/3/3;
  }

  .k-ade-match-group-nested {
    grid-area: 2/1/3/3;
  }
}

.k-ade-match-group-nested,
.k-ade-match-group-nested > div:first-child {
  margin: 0;
}

.k-ade-group-editor .b-txt-input__sublabel {
  margin-top: var(--b-gap);
  margin-left: var(--b-gap-tiny);
}

@media (min-width: 1441px) {
  .k-ade-org-activation-lock {
    margin-top: var(--b-gap3);
  }

  .k-ade-secondary-subrow-lite {
    display: grid;
    grid: auto/1fr 1fr;
  }

  .k-ade-lock-prefilled-ctrl:after {
    height: 30px;
    top: unset;
    bottom: 0;
  }

  .k-ade-prefill-ctrl:after {
    height: 40px;
    top: 14px;
  }
}

@media (max-width: 1440px) {
  .k-ade-secondary-subrow-lite > .b-library__setting-helpers {
    margin-top: var(--b-gap-micro);
    margin-bottom: 0;
  }
}

.k-ade-shared-input {
  width: 84px;
  min-width: 84px;
}

.k-ade-adjust-secondary-width {
  max-width: calc(50% - 36px);
}

.k-ade-max-input.k-ade-max-input {
  display: grid;
  grid: auto 15px / auto;
}

.k-ade-max-input.k-ade-max-input .b-input-char-count {
  position: relative;
  top: var(--b-gap1);
}

.k-ade-fullname-row,
.k-ade-shortname-row {
  padding-bottom: 34px;
}

.k-ade-password-row {
  padding-bottom: 0;
}

.k-ade-password-row::after {
  display: none;
}

.k-ade-pwd-input .b-txt-input__err-msg {
  display: none;
}

.k-ade-chip--m0.k-ade-chip--m0 {
  margin: 0;
}

.b-flex-wrap-gtiny div.k-ade-chip--m0:not(:first-of-type) {
  margin-left: var(--b-gap-tiny);
}
