@import '../../../../assets/styles/base/variables';

.awesome-table {
  //margin: 20px 0;
  box-shadow:
    0 7px 14px 0 rgba(60, 66, 87, 0.1),
    0 3px 6px 0 rgba(0, 0, 0, 0.07);
  //position: relative;
  visibility: hidden;
  border: 1px solid $grey-130;
  border-radius: 5px;
  &.isReady {
    visibility: visible;
  }
}
