.b-icon-up {
}

.b-icon-up-xs {
  height: 54px;
  width: 54px;
  border-radius: 50%;
}

.b-icon-up-xs-img {
  height: auto;
  width: 90%;
}

.b-icon-up-xs__loaded {
  border-radius: 0;
}

.b-icon-up-xs-container {
  --normal: var(--color-blue-50);
  --hover-focus: var(--color-blue-60);
  --active: var(--color-blue-70);
  --error: var(--color-red-50);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed var(--normal);
  border-radius: 50%;
  color: var(--normal);
}

.b-icon-up-xs-container:hover,
.b-icon-up-xs-container:focus {
  border: 2px solid var(--hover-focus);
  color: var(--hover-focus);
}

.b-icon-up-xs-container:active {
  border: 2px solid var(--active);
  color: var(--active);
}

.b-icon-up-xs-container__disabled,
.b-icon-up-xs-container__disabled__with-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-neutral-30);
  border: none;
}

.b-icon-up-xs-container__disabled__with-icon {
  background-color: transparent;
}

.b-icon-up-xs-container__loading {
  border: 2px dashed var(--color-neutral-30);
}

.b-icon-up-xs-container__error {
  border: 2px solid var(--color-red-50);
}

.b-icon-up-xs-container__loaded {
  border: none;
}

.b-icon-up-xs__icon,
.b-icon-up-xs__icon:hover,
.b-icon-up-xs__icon:focus,
.b-icon-up-xs__icon:active {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: inherit;
}

.b-icon-up-xs-icon__disabled,
.b-icon-up-xs-icon__disabled:hover,
.b-icon-up-xs-icon__disabled:active,
.b-icon-up-xs-icon__disabled:focus {
  cursor: auto;
  color: var(--color-neutral-0);
  font-size: 1.3rem;
}

.b-icon-up-xs__input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border: 2px solid transparent;
  z-index: -1;
}
