//$fa-font-path: "../../../node_modules/@fortawesome/fontawesome-pro/webfonts";
//@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome';
//@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/regular';
//@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/solid';
//@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/light';
//@import '../../../node_modules/@fortawesome/fontawesome-pro/scss/brands';
@import 'base/bootstrap_custom';
@import '../../../node_modules/bootstrap/scss/bootstrap';
//@import "../../../node_modules/bootstrap-select-v4/sass/bootstrap-select";
@import '../../../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
@import '../../../node_modules/react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
@import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

// Base
@import 'base/variables';
@import 'base/mixins';
@import 'base/layout';
@import 'base/typography';
@import 'base/fonts';
@import 'base/common';

// Elements
@import 'elements/table';
@import 'elements/table_kandji';
// @import 'elements/breadcrumbs';
@import 'elements/buttons';
@import 'elements/modals';
@import 'elements/forms';
@import 'elements/indicators';

// Components
@import 'components/main';
@import 'components/computers_list';
@import 'components/computer_detailed';
@import 'components/nav';
@import 'components/notes';
@import 'components/login';
@import 'components/settings';
@import 'components/blueprint_record';
@import 'components/users';
@import 'components/pagination';
@import 'components/dropdown';
@import 'components/parameter_page';
@import 'components/company';
@import 'components/registration';
@import 'components/reset_password';
@import 'components/blockers';
@import 'components/filters';
@import 'components/integrations';
@import 'components/tooltips';
@import 'components/icon_circle';
@import 'components/blueprint_element';
@import 'components/blueprint_list';
@import 'components/search_string';
@import 'components/activity';

// Theme (Remove this when shared package/library is implemented)
@import '../../theme/styles/style';

// Bumblebee
@import '@kandji-inc/bumblebee/lib/index.scss';
