/* ========================= */
/* ==========!ICON========== */
/* ========================= */

$offset-icon-check: 3px;
$offset-icon-envelope: 2px;
$offset-icon-pencil: 2px;
$offset-icon-plus-circle-hollow: 2px;
$offset-icon-printer: 3px;
$offset-icon-users: 2px;

/* ----------!MAIN---------- */
.icon {
  width: $size-icon-default;
  height: $size-icon-default;
  display: flex;
}

/* ----------!SPACE---------- */
.icon + .label,
.label + .icon {
  margin-left: 8px;
}

/* ============================ */
/* ==========!OFFSET=========== */
/* ============================ */

/* ----------!CHECK---------- */
.icon-check > svg {
  margin: -$offset-icon-check;
  width: $size-icon-default + ($offset-icon-check * 2);
  height: $size-icon-default + ($offset-icon-check * 2);
}

/* ----------!ENVELOPE---------- */
.icon-envelope > svg {
  margin: -$offset-icon-envelope;
  width: $size-icon-default + ($offset-icon-envelope * 2);
  height: $size-icon-default + ($offset-icon-envelope * 2);
}

/* ----------!PENCIL---------- */
.icon-pencil {
  padding-top: $offset-icon-pencil;
}
.icon-pencil > svg {
  margin: -$offset-icon-pencil;
  width: $size-icon-default + ($offset-icon-pencil * 2);
  height: $size-icon-default + ($offset-icon-pencil * 2);
}
#app .icon-pencil > svg {
  stroke: none;
}

/* ----------!PRINTER---------- */
.icon-printer > svg {
  margin: -$offset-icon-printer;
  width: $size-icon-default + ($offset-icon-printer * 2);
  height: $size-icon-default + ($offset-icon-printer * 2);
}

/* ----------!USERS---------- */
.icon-users > svg {
  margin: -$offset-icon-users;
  /*width: $size-icon-default + ($offset-icon-users * 2);*/
  width: 30px;
  height: $size-icon-default + ($offset-icon-users * 2);
}

/* ----------!USERS---------- */
.icon-plus-circle-hollow > svg {
  margin: -$offset-icon-plus-circle-hollow;
  width: $size-icon-default + ($offset-icon-plus-circle-hollow * 2);
  height: $size-icon-default + ($offset-icon-plus-circle-hollow * 2);
}

/* ============================== */
/* ==========!OVERRIDE=========== */
/* ============================== */

/* ----------!EYE-SLASH---------- */
.icon-eye-slash {
  padding-top: 2px;
}

/* ----------!EYE-SLASH---------- */
.icon-kandji-hive {
  width: auto;
  height: auto;
}

/* ----------!GOOGLE-WORKSPACE---------- */
.icon-google-workspace {
  margin-left: 2px;
  margin-right: -3px;
}
.paper-title > .icon-google-workspace {
  margin-left: 0;
  padding-top: 2px;
}
