// TODO: phase out

.btn {
  text-transform: capitalize;
  background: white;
  color: $darkest-blue;
  border-radius: 25px;
  outline: none !important;
  padding: 0 30px 0 30px;
  height: 34px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid $light-grey-border;
  > .fa,
  .far,
  .fas,
  .fab {
    margin-right: 10px;
  }
  //&:focus, &:hover, &:active {
  //  //border: 1px solid $light-grey-border;
  //  //outline: none !important;
  //  //box-shadow: none;
  //  background: white !important;
  //  outline: none !important;
  //  border: 1px solid transparent !important;
  //  box-shadow: 0 0 0 2px $darkest-blue !important;
  //}
  &:disabled,
  &.disabled {
    pointer-events: none;
  }
  &.btn-success {
    border-color: transparent;
    background-color: $success;
    color: white !important;
    &:focus,
    &:hover,
    &:active {
      color: $success !important;
      background: white !important;
      outline: none !important;
      border-color: transparent;
      box-shadow: 0 0 0 2px $success !important;
    }
  }
  &.btn-secondary {
    color: $darkest-blue !important;
    &:disabled {
      background-color: #fff;
      border-color: transparent;
    }
  }
  &.btn-primary {
    border-color: transparent;
    background-color: $yellow-500;
    color: #000 !important;
    &.btn-cancel {
      &:focus,
      &:active {
        background-color: $darkest-blue !important;
        color: white !important;
        box-shadow: none !important;
      }
      &:hover {
        color: $darkest-blue !important;
        background: white !important;
        outline: none !important;
        border-color: transparent;
        box-shadow: 0 0 0 2px $darkest-blue !important;
      }
    }
    &:focus,
    &:hover,
    &:active {
      color: $darkest-blue !important;
      background: white !important;
      outline: none !important;
      border-color: transparent;
      box-shadow: 0 0 0 2px $darkest-blue !important;
    }
    &[disabled] {
      border-color: transparent !important;
      background-color: $darkest-blue !important;
      outline: none !important;
      color: white !important;
      box-shadow: none !important;
    }
  }
  &.btn-danger {
    border-color: $danger;
    background-color: white;
    color: $danger !important;
    &:focus,
    &:hover,
    &:active {
      color: white !important;
      background: $danger !important;
      outline: none !important;
      border-color: transparent;
      box-shadow: 0 0 0 2px $danger !important;
    }
    &[disabled] {
      border-color: $light-pink !important;
      background-color: transparent !important;
      outline: none !important;
      box-shadow: none !important;
      &:focus,
      &:hover,
      &:active {
        color: $danger !important;
      }
    }
  }
  &.btn-tertiary {
    font-weight: bold;
    transition:
      background-color 0.3s ease,
      color 0.3s ease,
      border-color 0.3s ease,
      fill 0.3s ease;
    border: 0.125rem solid $marengo-700;
    display: block;
    margin: 2.125rem 0;
    width: 100%;
    text-transform: uppercase;
    border-radius: 1.625rem;
    letter-spacing: 0.2em;
    height: 3rem;
    background-color: $marengo-700;
    border-color: $marengo-700;
    color: white;
    &:disabled {
      color: white !important;
    }
    &.btn-small {
      width: auto;
      height: 2.5rem;
      font-size: 0.8rem;
    }
    &.btn-bg-transparent {
      background-color: transparent;
      color: $marengo-700;
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      background-color: $orange-selected !important;
      border-color: $orange-selected !important;
      color: white;
      outline: 0 !important;
      fill: white;
    }
  }
  &.btn-yellow {
    font-weight: bold;
    transition:
      background-color 0.3s ease,
      color 0.3s ease,
      border-color 0.3s ease,
      fill 0.3s ease;
    border: 0.125rem solid $yellow-500;
    display: block;
    margin: 2.125rem 0;
    width: 100%;
    text-transform: uppercase;
    border-radius: 1.625rem;
    letter-spacing: 0.2em;
    height: 3rem;
    background-color: $yellow-500;
    border-color: $yellow-500;
    color: white;
    &.btn-small {
      width: auto;
      height: 2.5rem;
      font-size: 0.8rem;
    }
    &.btn-bg-transparent {
      background-color: transparent;
      color: $yellow-500;
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      background-color: $orange-selected !important;
      border-color: $orange-selected !important;
      color: white;
      outline: 0 !important;
      fill: white;
    }
  }
  &.btn-transparent {
    font-weight: bold;
    transition: all 0.3s ease;
    border: 0.125rem solid $yellow-500;
    display: block;
    margin: 2.125rem 0;
    width: 100%;
    text-transform: uppercase;
    border-radius: 1.625rem;
    letter-spacing: 0.2em;
    height: 3rem;
    background-color: transparent;
    border-color: transparent;
    color: $marengo-700;
    &.btn-small {
      width: auto;
      height: 2.5rem;
      font-size: 0.8rem;
    }
    &.btn-bg-transparent {
      background-color: transparent;
      color: $yellow-500;
    }
    &:hover,
    &:active,
    &:focus {
      box-shadow: none !important;
      background-color: $orange-selected !important;
      border-color: $orange-selected !important;
      color: white;
      outline: 0 !important;
      fill: white;
    }
  }
}

.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
}

.dropdown-button {
  display: inline-block;
  .dropdown-menu {
    color: $darkest-blue;
    padding: 0.3rem 0;
    &.multiple-select {
      max-height: 400px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $darkest-blue;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $darkest-blue;
      }
    }
    &.inline-search {
      min-width: 350px !important;
    }
    .dropdown-header-search {
      margin: 5px 0;
      .dropdown-header {
        line-height: 25px;
        display: inline;
      }
      .search-icon {
        position: absolute;
        top: 14px;
        right: 20px;
        background-color: white;
        height: 20px;
        z-index: 1099;
        cursor: pointer;
      }
    }
    .filter-inline-search {
      position: relative;
      display: inline;
      float: right;
      height: 25px;
      min-width: 100px;
      width: 210px;
      margin-right: 15px;
      vertical-align: middle;
    }
    .divider,
    .dropdown-divider {
      margin: 0.3rem 0;
    }
    .dropdown-item {
      color: $grey-600;
      display: flex;
      align-items: center;
      padding-right: 2rem;
    }
    li {
      cursor: pointer;
      &.selected {
        span.check-mark,
        i.check-mark {
          color: $darkest-blue;
          position: absolute;
          display: inline-block;
          right: 10px;
          font-size: 12px;
        }
        margin-right: 6px;
        background-color: $selected-blue !important;
      }
      &:hover:not(.DayPickerSelector) {
        text-decoration: none;
        background-color: $light-grey !important;
        border: none;
        color: black;
      }
      &.divider {
        height: 1px;
        width: 100%;
        border-bottom: 1px solid $light-grey-border;
      }
    }
    &.left {
      left: -90px !important;
    }
    &.inner {
      padding-bottom: 0 !important;
      overflow-x: hidden;
      max-width: 450px;
    }
    .dropdown-header,
    .popover-title {
      display: block;
      padding: 0.5rem 20px;
      margin-bottom: 0;
      font-size: 14px;
      font-weight: 400;
      color: $text-normal-grey;
      white-space: nowrap;
      button {
        display: none;
      }
    }
  }
  .dropdown-toggle-button {
    display: flex;
    position: relative;
    //padding-left: 15px !important;
    padding-left: 0 !important;
    padding-right: 25px !important;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 0.7rem;
    letter-spacing: 0.25em;
    max-width: 220px;
    background-color: transparent;
    > div {
      overflow: hidden;
      margin-right: 11px;
    }
    &:after {
      color: inherit;
      font-family: 'Font Awesome 5 Pro', sans-serif;
      //font-weight: 400;
      width: 0;
      height: 0;
      margin-top: 0.07em;
      content: '\f0d7';
      border: none;
      font-size: inherit;
    }
    &.show {
      &::after {
        content: '\f0d8';
      }
    }
  }
}

.select-white {
  .bootstrap-select {
    &.no-tick {
      li.selected a span.check-mark,
      li.selected a i.check-mark {
        display: none !important;
      }
    }
  }
}

.bootstrap-select {
  .filter-option {
    display: flex !important;
    align-items: center;
    .fa,
    .far,
    .fab,
    .fas {
      display: none;
    }
    .fa.oa-3c {
      height: 13px;
      width: 21px;
      margin-right: 5px;
    }
  }
}

.dropdown-button {
  .dropdown-toggle-button {
    &.btn {
      box-shadow: none !important;
      font-size: 0.8rem;
      color: #666;
      //border: 1px solid #d9e0e8!important;
      &:hover {
        //box-shadow: 0 0 0 2px $darkest-blue !important;
        color: black;
      }
    }
  }
  .dropdown-toggle {
    padding: 0 25px 0 16px;
    &.btn-primary {
      border-color: transparent !important;
      background-color: $darkest-blue !important;
      color: white !important;

      &:hover {
        color: $darkest-blue !important;
        background: white !important;
        outline: none !important;
        border-color: transparent;
        box-shadow: 0 0 0 2px $darkest-blue !important;
      }
    }
  }
  .dropdown-dotted {
    color: $darkest-blue;
    width: 34px;
    height: 24px;
    background: none;
    font-size: 20px;
    line-height: 18px;
    padding: 0;
    text-align: center;
    border: 1px solid $light-grey;
    outline: none !important;
    box-shadow: none !important;
    &::after {
      content: none !important;
    }
    &:hover {
      color: white !important;
      background: $darkest-blue !important;
      border: 1px solid transparent !important;
      box-shadow: 0 0 0 2px $darkest-blue !important;
    }
  }
  &.show {
    .dropdown-dotted {
      color: white;
      background: $darkest-blue;
      border: 1px solid transparent !important;
      box-shadow: 0 0 0 2px $darkest-blue !important;
    }
    .dropdown-toggle {
      &.btn-primary {
        &:focus,
        &:active {
          color: $darkest-blue !important;
          background: white !important;
          outline: none !important;
          border-color: transparent;
          box-shadow: 0 0 0 2px $darkest-blue !important;
        }
        &::after {
          content: '\F106';
        }
      }
    }
  }
  .borderless {
    border: none;
  }
}

.dropdown-item {
  .fa,
  .fas,
  .far,
  .fab,
  .fal {
    padding-top: 0 !important;
  }
}

.btn-sm,
.btn-group-sm > .btn {
  height: 26px !important;
  border: 1px solid $light-grey-border;
  font-size: 14px;
  line-height: 1.5;
  &:not(.dropdown-toggle) {
    padding: 0 15px;
  }
}

.fa.oa-3c {
  height: 16px;
  width: 27px;
  content: url(../../img/icons/status_3.png);
}

.icon-blueprint-edit {
  &:before {
    height: 16px;
    background: url(../../img/icons/blueprint_edit_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    display: block;
  }
}

.icon-blueprint-duplicate {
  &:before {
    height: 16px;
    background: url(../../img/icons/blueprint_duplicate_icon.png);
    background-size: contain;
    background-repeat: no-repeat;
    content: '';
    display: block;
  }
}

input,
textarea {
  font-weight: 400;
}

.select-standard {
  height: 31px;
  button {
    @include elementState();
    color: black !important;
  }
  button {
    border-radius: 0;
  }
}
.btn-profile {
  margin: 25px;
  width: 200px;
}

.btn-type-2 {
  display: flex;
  padding: 0 27px 0 19px;
}

.circle-button {
  padding: 0;
  width: 34px;
  margin-right: 50px;
  i {
    margin: 0 !important;
    line-height: 1.5 !important;
  }
}

.add-new-button {
  margin-bottom: 15px;
  width: 34px;
  height: 34px;
  padding: 6px 0 6px 10px;
  border-radius: 17px;
  text-align: center;
  font-size: 14px;
  line-height: 1.42857;
}

.action-button-for-table {
  user-select: none;
  place-items: center;
  > div {
    line-height: 24px;
    font-size: 20px;
    margin-right: 1.2rem;
  }
}

.overlay-button {
  position: fixed;
  bottom: 2.75rem;
  right: 3rem;
  z-index: 2147483647;
  .add-circle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 80px;
    width: 80px;
    background-color: $yellow-500;
    border-radius: 40px;
    //transition: .3s cubic-bezier(0, 1, 0.5, 1);
    transition:
      0.1s cubic-bezier(0, 1, 0.5, 1),
      box-shadow 0.3s ease;
    &:hover {
      box-shadow:
        0 18px 35px rgba(50, 50, 93, 0.1),
        0 8px 15px rgba(0, 0, 0, 0.07);
      transform: translateY(-1px);
    }
    i {
      color: $marengo-700;
      font-size: 2.3em;
    }
  }
}

.simple-icon-button-delete {
  border: 0 none transparent !important;
}
