.k-klogin-toggle {
  background: #eef0f6;
  border-radius: 4px;
  width: 228px;
  opacity: 1;
}

.k-klogin-toggle.--hidden {
  opacity: 0;
}

.k-klogin-toggle,
.k-klogin-toggle.--hidden {
  transition: all 250ms ease-in-out;
  transition-property: opacity;
}

.b-txt.b-txt.k-ks-dark-blue {
  color: var(--color-neutral-60);
}

.b-txt.b-txt.k-ks-dark-blue.--active {
  color: #1a1d25;
}

.k-klogin-toggle-button.k-klogin-toggle-button {
  padding: 8px;
  border-radius: 4px;
  min-width: 114px;
  max-width: fit-content;
}

.k-klogin-toggle-button.--active {
  background: #fff;
  border: 2px solid #eef0f6;
  color: #1a1d25;
}

.k-klogin-toggle-button:focus {
  outline: 0;
}
.b-txt.b-txt.k-klogin-dark-blue {
  color: #1a1d25;
}
