.k-rp-mc__rotate-controls {
  margin-top: 31px;
  padding-bottom: 28px;
}

.k-rp-mc__day-row {
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  min-width: unset;
}
.k-rp-mc__day-row .b-txt-input {
  width: 84px;
  margin-left: 58px;
  margin-right: 15px;
}
.k-rp-mc__day-row .b-txt-input__err-msg {
  width: max-content;
}

.k-rp-mc__day-row-input {
  width: 100%;
}

.k-rp-mc__passwords .b-txt-input__err-msg {
  position: static;
  width: max-content;
}

.k-rp-mc__legacy-pass-cont {
  width: 100%;
}
.k-rp-mc__legacy-pass {
  display: flex;
}
.k-rp-mc__legacy-pass > *:first-child {
  margin-right: var(--b-gap-micro);
}
.k-rp-mc__legacy-pass .b-txt-input__err-msg {
  position: static;
  margin-top: var(--b-gap1);
}

.k-rp-mc__legacy-pass .btn-icon {
  margin-top: 2px;
}
