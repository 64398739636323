.b-flex-wrap-gtiny div.k-ethernetv2-protocols-general__tag:not(:first-of-type) {
  margin-left: 0;
}
.k-ethernetv2-protocols-general__tag:not(:last-of-type) {
  margin-right: var(--b-gap-tiny);
}
div.k-ethernetv2-protocols-general__tag.k-ade-chip--m0.k-ade-chip--m0 {
  margin-bottom: var(--b-gap-tiny);
}

.b-library__setting-card__header.--no-padding {
  padding: 0;
}

.k-ethernetv2-protocols-pkcs-drawer__row.--no-padding-bottom {
  padding-bottom: 0;
}

.k-ethernetv2-protocols-pkcs-drawer__row.--no-padding-top {
  padding-top: 0;
}

.b-library__setting-card__header.--no-shadow {
  box-shadow: none;
}

.small-input-wrapper {
  display: flex;
  align-items: center;
  width: 100%;

  & > div {
    &:last-child {
      padding-left: var(--b-gap);
    }

    &:first-child {
      flex: 0 1 84px;
      input {
        width: 100%;
      }
    }
  }
}

.mb-gap1 {
  margin-bottom: var(--b-gap1);
}

.k-ss2-drawer-setting-extra1 > .k-file-select__container {
  height: 100px;
}

.k-trusted-certificate-file {
  justify-content: space-around;
}

.--no-grid {
  display: block;
}

.--no-left-border {
  padding-left: 0;
  &:after {
    display: none;
  }
}

.k-ethernetv2-trust-scn__input-wrapper {
  display: flex;
  margin-top: var(--b-gap2);

  button {
    margin-left: 8px;
  }
}

.k-ethernetv2-trust-scn__btn {
  margin-top: var(--b-gap2);
}

.k-ethernetv2-protocols-pkcs-drawer,
.k-ethernetv2-drawer {
  &__header {
    margin: var(--b-gap2) 0;
  }

  &__row {
    margin-top: 0;
    border-top: var(--color-neutral-20) solid 1px;
    margin-bottom: 0;
    padding: var(--b-gap2) 0;

    &.k-ss2-drawer-setting:not(:last-child) {
      margin-bottom: 0;
    }
    &-desc {
      margin-top: var(--b-gap-micro);
    }
    &-input {
      margin-top: var(--b-gap1);
    }
    &-checkbox-desc {
      margin-top: var(--b-gap1);
      padding-left: 36px;
    }
    &-header {
      &--big,
      &--big.b-txt-bold {
        font-size: 16px;
      }
    }
  }
}

.k-ethernetv2-protocols-proxy__proxy-inputs {
  display: flex;
  width: 360px;
  align-items: center;
  .b-txt-input:first-child {
    flex: 0 0 276px;
  }
  span {
    margin: 0 8px;
  }
  .b-txt-input:last-child {
    flex: 0 1 64px;
    input {
      width: 100%;
    }
  }
}

.k-ethernetv2-fastlane-drawer__search-empty,
.k-ethernetv2-fastlane-specific-apps-empty {
  text-align: center;
  &__img {
    img {
      width: 151.5px;
      height: auto;
    }
  }

  &__title {
    margin-top: var(--b-gap);
    font-size: 16px;
  }

  &__text {
    margin-top: var(--b-gap);
    display: flex;
    justify-content: center;
  }

  &__btn {
    margin-top: var(--b-gap);
    display: flex;
    justify-content: center;
  }
}

.k-ethernetv2-fastlane-specific-apps-empty {
  padding: 40px 0 8px;

  &__text {
    p {
      flex: 0 1 496px;
    }
  }
}

.k-ethernetv2-fastlane-drawer__search-empty {
  padding: var(--b-gap3) 0;

  &__text {
    p {
      flex: 0 1 340px;
    }
  }
}

.k-ethernetv2-fastlane-drawer {
  &__selected-apps {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--b-gap1) - var(--b-gap-tiny));

    &-tag {
      margin-top: var(--b-gap-tiny);
      margin-right: var(--b-gap-tiny);
    }
  }
  &-form {
    display: flex;
    flex-direction: column;
  }
  &__search-input {
    margin-top: var(--b-gap);
    .b-txt-input__label {
      margin-bottom: var(--b-gap1);
    }
  }

  &__search-input-btn {
    margin-top: var(--b-gap1);
    display: flex;
    justify-content: flex-end;
  }

  &__search-result {
    max-height: 540px;
    overflow-y: scroll;
  }
  &__item {
    display: flex;
    height: 108px;
    &:not(:first-child) {
      border-top: var(--color-neutral-20) solid 1px;
    }

    .checkbox {
      flex: 0 0 56px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .b-checkbox {
        margin-right: var(--b-gap1);
      }
    }
    .content {
      padding-left: var(--b-gap-tiny);
      flex: 1 0;
      display: flex;

      &__logo {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 36px;
          height: 36px;
          object-fit: cover;
        }
      }

      &__info {
        margin-left: var(--b-gap1);
        margin-top: auto;
        margin-bottom: auto;

        &-tags {
          display: flex;
          align-items: center;
          margin-top: var(--b-gap-micro);
        }

        &-tag {
          display: flex;
          line-height: 24px;
          border-radius: 3px;
          background: var(--color-purple-20);
          &:not(:first-child) {
            margin-left: var(--b-gap-tiny);
          }

          p.b-txt {
            color: var(--color-purple-70);
            font-size: 12px;
            line-height: 24px;
            padding: 0 var(--b-gap-tiny);
          }
        }
      }
    }
  }
  &__item.--checked {
    .checkbox {
      background: var(--color-purple-20);
    }
    .content {
      background: var(--color-purple-20);
    }
  }
}

.content__info-tag {
  display: flex;
  line-height: 24px;
  border-radius: 3px;
  background: var(--color-purple-20);
  &:not(:first-child) {
    margin-left: var(--b-gap-tiny);
  }

  p.b-txt {
    color: var(--color-purple-70);
    font-size: 12px;
    line-height: 24px;
    padding: 0 var(--b-gap-tiny);
  }
}

.k-ethernetv2-fastlane-table {
  border-bottom: 1px solid var(--color-neutral-20);
  border-top: 1px solid var(--color-neutral-20);
  &__name-column {
    flex: 1 1 700px;
  }
  &__bundle-column {
    flex: 1 1 364px;
    align-items: center;
  }
  &__actions-column {
    flex: 0 1 56px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      color: var(--color-neutral-50);
    }
  }
  &__name-row {
    display: flex;
    align-items: center;
    &-logo {
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
      }
    }
    &-name {
      margin-left: var(--b-gap-tiny);
    }
    &-tags {
      display: flex;
      margin-left: var(--b-gap1);
    }
  }
  &__btn {
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--color-neutral-20);
  }
}

@media (min-width: 1441px) {
  .k-ethernet__eap-fast-control.b-library__setting-controls {
    grid-area: 2/1/3/2;
  }
}

.k-li-top-border {
  border-top: 1px solid var(--color-neutral-20);
}
