.b-mdf-table {
  padding-bottom: 0 !important;
}

.b-mdf-table__footer {
  padding: 18px var(--b-gap);
}

.b-mdf-cell__error {
  padding-bottom: 32px;
}

.b-mdf-table .b-txt-input__err-msg {
  position: unset;
  padding-top: var(--b-gap-tiny);
  height: 36px;
}

.b-mdf-checkbox {
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.b-mdf-checkbox__error .b-checkbox-square,
.b-mdf-checkbox__error .b-checkbox-square:hover {
  border: 2px solid var(--color-red-50);
}

.b-mdf-checkbox__error > i {
  font-size: var(--b-gap);
}

.b-mdf-checkbox__error-text.b-mdf-checkbox__error-text {
  width: 250px;
  color: var(--color-red-50);
  height: 36px;
  padding-bottom: 6px;
}

.b-table__body .b-table__row {
  min-height: 56px;
  max-height: 86px;
}

.b-mdf-text-input-cell {
  padding: 6px 0;
}

.b-table__body .b-table__cell.b-table__cell {
  padding-top: 0;
  padding-bottom: 0;
}

.b-mdf-action-btn {
  height: fit-content;
  padding: 8px 0;
}

.tippy-box[data-theme~='basic-white'].tippy-box[data-theme~='basic-white'] {
  background-color: #fff;
  filter: drop-shadow(0px 0px 10px rgba(26, 29, 37, 0.15));
  border-radius: 4px;
}

.tippy-box[data-theme~='basic-white'] .tippy-content {
  padding: var(--b-gap);
}

.tippy-box[data-theme~='basic-white'][data-placement^='top'] .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='basic-white'][data-placement^='bottom'] .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='basic-white'][data-placement^='left'] .tippy-arrow {
  color: #fff;
}
.tippy-box[data-theme~='basic-white'][data-placement^='right'] .tippy-arrow {
  color: #fff;
}
