.k-library__actions {
  position: fixed;
  height: 64px;
  bottom: 0;
  box-shadow: 0px -4px 5px rgb(0 0 0 / 4%);
  width: calc(100% - 255px);
  background: var(--color-neutral-0);
  padding: var(--b-gap1) var(--b-gap2);
  right: 0px;
  display: flex;
  align-items: center;
}

.full-width-sidebar-closed .k-library__actions {
  width: calc(100% - 77px);
}

.k-library__actions-inner {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

@media (min-width: 1712px) {
  body .k-library__actions-inner {
    width: 1360px;
    margin: auto;
  }
}

.k-library__actions-secondary {
}

.k-library__actions-primary {
  min-width: 158px;
  margin-left: auto;
  position: relative;
  left: 1px;
}
