@import '../../scss/mq.def';

.b-library {
  min-width: 900px;
  margin: 0 auto;
  padding-bottom: calc(30px + var(--b-gap3));
  @media (max-width: #{$--upto-lg}) {
    width: 100%;
  }
  @media (min-width: #{$--lg-above}) {
    width: 1360px;
  }
}

.b-library__bread {
  margin: var(--b-gap3) 0 var(--b-gap2);
}

.b-library__tabs-nav {
  margin: var(--b-gap3) 0 var(--b-gap3);
}
