// assign-rules.js
.k-rules-library {
  min-height: 40px;
  min-width: 0;
  display: flex;
}

.k-rules-library > .label,
.k-rules-library-tags,
.k-rules-library-action {
  display: flex;
  min-width: 0;
  align-items: center;
}

.k-rules-library > .label {
  min-width: 122px;
  max-height: 32px;
  white-space: nowrap;
}

.k-rules-library-tags {
  margin-right: -16px;
  flex-wrap: wrap;
}

.k-rules-library-action {
  margin-top: -8px;

  .b-btn-action-link,
  .b-btn-error-link {
    font-size: 14px;
  }
}

// builder-modal.js
.k-rules-library-modal {
  min-width: 1050px;
  overflow: scroll !important;
}

.k-rules-library-modal strong {
  font-weight: 600;
}

.k-rules-library-modal .k-divider {
  border-top: 1px solid var(--color-neutral-20);
  padding-top: var(--b-gap);
}

.k-rules-library-builder {
  border-top: 1px solid var(--color-neutral-20);
  margin-left: calc(-1 * var(--b-gap3));
  margin-right: calc(-1 * var(--b-gap3));
  padding: 20px var(--b-gap3) 0;
}

.k-rules-library-builder > .k-rules-builder {
  min-width: 880px;
  min-height: 500px;
}

// delete-modal.js
.k-rules-library-delete-modal {
  width: 560px;
}

.k-rules-library-delete-modal .b-btn-error {
  border-color: var(--color-red-70);
  background-color: var(--color-red-70);
}
