/* ========================== */
/* ==========!PAPER========== */
/* ========================== */

/* ----------!MAIN---------- */
.paper,
.paper > .sticky .sticky-background {
  background-color: $color-white;
  border-radius: $border-radius-dull;
}
.paper > .sticky .sticky-background,
.paper > .sticky .sticky-background > .node {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.paper > .sticky .sticky-border {
  border-bottom: 1px solid $color-divider;
}
.paper .paper,
.paper .paper > .sticky .sticky-background {
  background-color: var(--color-neutral-5);
}

/* ----------!TEXT---------- */
.paper p {
  margin-top: -6px;
  margin-bottom: -9px;
  padding: 0;
}
.paper p + p {
  padding-top: ($space-paper - 1px);
}
.paper p > a {
  color: $color-system-link;
}
.paper p > a:active {
  color: $color-system-link-active;
}
.paper p > a:hover,
.paper p > a:focus {
  color: $color-system-link-hover;
}
.paper p > strong {
  font-weight: $font-weight-3;
}
.paper .headline {
  font-weight: $font-weight-headline;
  color: $color-brand-marengo-ultradark;
}

/* ----------!NODE---------- */
.paper .node,
.paper .division .node {
  padding: $space-paper $space-edge-item;
}
.paper .sticky .node {
  padding: $space-header;
}
.library .paper header.division .node {
  padding-bottom: 12px;
  padding-top: 12px;
}

.library .paper header.division + section > .node:first-child {
  padding-top: $space-edge-item;
}
.library .paper footer.division > .node:last-child {
  padding-bottom: $space-edge-item;
}
/* ----------!BLOCK---------- */
.paper .paper-block {
  padding-bottom: $space-paper;
}
.paper .paper-block + .paper-block {
  border-top: 1px solid $color-divider;
  margin-top: $space-paper;
  padding-top: ($space-paper - 1px);
}

/* ----------!TITLE---------- */
.paper .paper-search {
  display: flex;
  align-items: center;
  margin: 0 $space-paper 0 0;
  position: relative;

  .search-label {
    display: none;
  }

  input {
    width: 360px;
    padding-right: 2 * $space-input + 20px;

    + * {
      display: flex;
      align-items: center;
    }

    &::placeholder {
      color: $color-brand-marengo-dark !important;
      -webkit-text-fill-color: $color-brand-marengo-dark !important;
    }
    &:placeholder-shown:not(:focus) {
      opacity: 0;
      width: 100px;

      + * {
        > i {
          right: unset;
          left: -$space-input;
        }

        > .search-label {
          position: absolute;
          display: block;
          right: $space-paper;
          color: $color-system-link-active;
          font-size: 12px;
          font-weight: 700;
          cursor: pointer;
        }
      }
    }
  }
  i {
    position: absolute;
    right: $space-input;
    cursor: pointer;

    svg {
      fill: $color-system-link-active;
    }
  }
}
.paper .paper-title {
  font-family: $font-family-title;
  font-weight: $font-weight-title;
  font-size: 16px;
  line-height: 20px;
  color: $color-brand-marengo-ultradark;
  display: flex;
}
.paper .paper-title > .icon > svg {
  fill: $color-brand-marengo-ultradark;
}
.paper .paper-title > span + .icon,
.paper .paper-title > .icon + span {
  margin-left: 5px;
}
.paper .paper .paper-title {
  font-size: $font-size-text;
}

.paper-title svg {
  margin-left: 5px;
}

/* ----------!COPY---------- */
.paper .paper-copy > p {
  color: $color-brand-marengo-ultradark;
}
.paper .paper-copy > .btn-hollow {
  margin: 15px auto 3px;
}
.paper .paper-copy-center {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.paper .paper-copy-center > .icon {
  margin: auto;
}
.paper .paper-copy-center > .icon + p {
  padding-top: $space-paper + 5px;
}

/* ----------!DIVIDE---------- */
.paper > .divide {
  border-top: 1px solid $color-divider;
}

/* ----------!REGION---------- */
.paper .paper-header:not([class*='sticky']) {
  border-bottom: 1px solid $color-divider;
}
.paper .paper-header .field {
  height: 100%;
  display: flex;
  align-items: center;
}
.paper .paper-aside + .paper-body {
  margin-top: -20px;
  border-top: 1px solid $color-divider;
}
.paper .paper-aside .paper-copy {
  padding: 5px 0;
  color: $color-brand-marengo-ultradark;
}
.paper .paper-footer {
  border-top: 1px solid $color-divider;
}
.paper .paper-footer .btn-hollow {
  margin-top: -10px;
  margin-bottom: -10px;
}

/* ========================= */
/* ==========!FORM========== */
/* ========================= */

/* ----------!MAIN---------- */
.paper .paper-block .theme-form-block {
  padding: 0;
}
.paper
  .paper-block
  .theme-form-block
  .theme-form-text-label
  + .theme-form-text-input {
  margin-top: $space-paper;
}
.paper .paper-block .theme-form-block .theme-form-text + .theme-form-text {
  padding-top: $space-paper * 2;
}
.paper
  .paper-block
  .theme-form-block
  .theme-form-upload-label
  + .theme-form-upload-detail {
  padding-top: ($space-paper - 5px);
}
