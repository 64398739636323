.wallpaper-container {
  position: relative;
  display: flex;
}

.wallpaper-dropzone {
  position: relative;
  width: 100%;
  padding: 13px;
}

.wallpaper-droparea {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  outline: none;
}

.wallpaper-iphone-notch {
  display: block;
  position: absolute;
  top: 20px;

  background-color: var(--color-neutral-20);
  width: 57px;
  height: 17px;
  border-radius: 10px;
}

.wallpaper-upload-icon {
  color: var(--color-neutral-20);
}

.wallpaper-upload-error {
  color: var(--color-red-50);
  display: flex;
}

.wallpaper-upload-error p.b-txt {
  color: var(--color-red-50);
  margin-left: var(--b-gap-tiny);
  text-align: left;
  font-size: 12px;
}

.wallpaper-device-frames-iphone {
  border-radius: 30px;
}

.wallpaper-device-frames-disabled,
.wallpaper-dropzone-disabled {
  opacity: 0.7;
}

.wallpaper-image-hover-iphone {
  z-index: 0;
  position: relative;
}

.wallpaper-image-hover > img {
  opacity: 0.5;
}

.wallpaper-image-delete {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallpaper-image-delete div {
  color: var(--color-neutral-0);
  border-radius: 3px;
  background: var(--color-red-50);
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.wallpaper-image-delete svg:hover {
  cursor: pointer;
}

.wallpaper-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.wallpaper-loading-spinner {
  width: 20px;
  height: 20px;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #3498db;
  border-radius: 50%;
  animation: wallpaperspin 1s linear infinite;
}

@keyframes wallpaperspin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.device-frame-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
