.sidebar-opened .main-error-boundary {
  margin-left: 256px;
  width: calc(100% - 256px);
}

.main-error-boundary {
  text-align: center;
  height: calc(100vh - 120px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-left: 77px;
  width: calc(100% - 77px);
}

.main-error-boundary img {
  width: 80%;
  margin-bottom: 50px;
}

.main-error-boundary h1 {
  font-size: 30px;
}
