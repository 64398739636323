.search-string-container {
  display: flex;
  align-items: center;
  border-bottom: 3px solid $grey-200;
  height: 36px;
  position: relative;
  width: 30vw;
  min-width: 200px;
  max-width: 300px;
  svg {
    font-size: 13px;
    margin-bottom: 8px;
  }
  label {
    color: $marengo-700;
    z-index: 1;
    letter-spacing: 0.2em;
    transition: transform 0.15s ease;
    transform-origin: 0 0 0;
    font-size: 12px;
    font-weight: 600;
    line-height: 36px;
    padding-left: 30px;
    position: absolute;
    text-transform: uppercase;
    will-change: transform;
    &.active {
      line-height: 1.125rem;
      transform: translateY(-20px) translateX(16px) scale(0.8);
    }
  }
  input {
    z-index: 2;
    background-color: transparent;
    height: 36px;
    padding: 0 1.875rem;
    border: none;
    box-shadow: none;
    outline: none;
    &:-webkit-autofill {
      transition: background-color 0.1s ease-in-out 0s;
    }
    color: $marengo-700;
    font-size: 12px;
  }
}

.search-string-container-icon-right {
  label {
    padding: 0;
  }
  input {
    padding-left: 0;
  }
}

.search-string-container-icon-none {
  border-bottom: none;
  label {
    padding: 0;
  }
  input {
    padding-left: 0;
  }
}
