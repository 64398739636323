.k-ss2-header {
}

.k-ss2-header .b-chip__text {
  text-transform: none;
}

.k-ss2-customize {
  padding: var(--b-gap2) 0 0;
  min-width: 973px;
}

.b-side-panel-layout__body.--k-ss2 {
  padding: var(--b-gap3);
}

.k-ss2-customize__buttons {
  display: flex;
  align-items: center;
}

.k-ss2-customize__buttons > *:first-child {
  margin-right: var(--b-gap);
}

.k-ss2-preview {
  position: relative;
  background: var(--color-neutral-20);
  margin-top: var(--b-gap2);
  border-radius: 4px;
  min-height: 282px;
  max-height: 282px;
  overflow: hidden;
}

.k-ss2-preview__images {
  position: absolute;
  width: 912px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  top: var(--b-gap2);
}

.k-ss2-preview__item {
  position: absolute;
  box-shadow:
    0px 2px 2px rgba(0, 0, 0, 0.05),
    0px 4px 6px rgba(0, 0, 0, 0.1);
}

.k-ss2-preview-frame {
  width: 428px;
}

.k-ss2-preview__item,
.k-ss2-preview-frame {
  border-radius: 12px;
}

.k-ss2-preview__item.--list {
  left: 0;
}

.k-ss2-preview__item.--modal {
  right: 0;
}

.k-ss2-preview-app {
  position: absolute;
  width: 296px;
  /*height: 98px;*/
  display: flex;
  top: 76px;
  left: 16px;
}

.k-ss2-preview-app.--modal {
  top: 40px;
  left: 32px;
  width: 355px;
}

.k-ss2-preview-app-image {
  width: 72px;
  height: 72px;
  padding: 9px;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #c7c7cc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-ss2-preview-app-image > img {
  width: 54px;
  height: 54px;
  object-fit: contain;
}

.k-ss2-preview-app.--modal > img {
  width: 66px;
  height: 66px;
}

.k-ss2-preview-app__details {
  margin-left: var(--b-gap);
  width: 100%;
  display: flex;
  flex-direction: column;
}

.k-ss2-preview-app-star {
  width: 13px;
  position: relative;
  left: 0px;
  top: -2px;
}

.k-ss2-preview-app__details > p:nth-child(1) {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  margin: 0;
  position: relative;
  top: 1px;
}

.k-ss2-preview-app__details.--modal > p:nth-child(1) {
  font-size: 15px;
  line-height: 20px;
}

.k-ss2-preview-app__details > p:nth-child(2) {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 14px;
  margin: 0;
  position: relative;
  top: 1px;
  color: rgba(0, 0, 0, 0.5);
  max-height: 31px;
  overflow: hidden;
}

.k-ss2-preview-app__details.--modal > p:nth-child(2) {
  font-size: 13px;
  line-height: 16px;
}

.k-ss2-preview-app__button {
  min-width: 88px;
  height: 21px;
  background: rgba(242, 242, 247, 0.7);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin-top: 24px;*/
  max-width: 88px;
  margin-top: auto;
}

.k-ss2-preview-app__button > span {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #007aff;
  text-transform: uppercase;
}

/* modal */
.k-ss2-preview-modal-extras {
  position: absolute;
  bottom: 18px;
  right: 24px;
  width: 380px;
}

.k-ss2-preview-modal-extras-category {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.151601px;
  margin-bottom: 8px;
}

.k-ss2-preview-modal-extras-category-detail {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.151601px;
  margin: 0;
  color: rgba(0, 0, 0, 0.5);
}

.k-ss2-preview-modal-extras-long-text {
  height: 164px;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.151601px;
  margin: 0;
  margin-top: 16px;
}

.k-ss2-preview-modal-grid {
  display: grid;
  grid: auto/1fr 1fr 1fr;
}

.k-ss2-preview-modal-grid > div {
  text-align: center;
}

/* */

.k-ss2-preview__question-wrapper {
  width: 24px;
  height: 24px;
  margin-top: 245px;
  margin-left: 14px;
}

.k-ss2-preview__question {
  width: 24px;
  height: 24px;
  bottom: var(--b-gap1);
  left: var(--b-gap1);
}

.k-ss2-preview__question:hover {
  cursor: pointer;
}

.b-side-panel-layout__body.--k-ss2 > div:not(:last-child) {
  margin-bottom: 48px;
}

.b-side-panel-layout__body.--k-ss2 > p {
  margin-bottom: var(--b-gap2);
}

.k-ss2-drawer-section {
}

.k-ss2-drawer-section > h3 {
  margin-bottom: var(--b-gap2);
}

.k-ss2-drawer-section .k-ss2-drawer-setting:not(:last-child) {
  margin-bottom: var(--b-gap2);
}

.k-ss2-drawer-setting-with-toggle {
  display: flex;
  justify-content: space-between;
}

.k-ss2-drawer-setting-with-toggle > div:first-child {
  margin-right: var(--b-gap2);
}

.k-ss2-drawer-setting-with-toggle > div:first-child > p:first-child {
  margin-bottom: var(--b-gap-micro);
}

.k-ss2-drawer-setting-extra {
  margin-top: var(--b-gap2);
}

.k-ss2-drawer-setting-extra1 {
  margin-top: var(--b-gap1);
}

.k-ss2-input-grid.--hook {
  display: grid;
  grid-auto-rows: auto 35px;
}

@media (min-width: 1441px) {
  .k-ss2-preview__item.--list,
  .k-ss2-preview-frame.--list {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

@media (max-width: 1440px) {
  .k-ss2-preview__images {
    width: 428px;
  }

  .k-ss2-preview__item.--modal {
    bottom: -112px;
  }

  .k-ss2-preview {
    min-height: 564px;
    max-height: 564px;
  }

  .k-ss2-preview__question-wrapper {
    margin-top: 522px;
  }
}
