.__v-table-ns {
  /* using raw hex value to avoid overlay transparency issues with `marengo-super-ultralight` CSS var */
  --v-marengo-super-ultralight-opaque: #fbfbfb;
  --v-table-blue-ultralight: #eff6ff;
  --v-table-blue-border: #0057da80;

  --shadow-main: 0 0 10px 0 var(--color-neutral-30);
  --shadow-main-border-right: inset 4px 0px 4px -2px var(--color-neutral-20);
  --shadow-border-bottom: inset 0 -1px 0 var(--color-neutral-20);
  --shadow-border-top: inset 0 1px 0 var(--color-neutral-20);
  --shadow-border-right: inset 1px 0 0 var(--color-neutral-20);

  --rounded-border-main: 4px;

  --stack-level-low: 1;
  --stack-level-med: 2;
  --stack-level-high: 3;
  --stack-level-higher: 4;
}

.v-table.__v-table-ns {
  display: grid;
  justify-items: start;
  grid-auto-rows: min-content;
  align-items: center;
  overflow: auto;
  opacity: var(--loading-opacity);
}

.v-table-outer.__v-table-ns {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
}

/* NOTE: when we have the new color tokens - ensures all Bumblebee link button
 * SVG icons in the table have the correct blue color if the `color` prop is
 * insufficient */
/* .v-table-outer button.b-btn-action-link svg {
  fill: var(--color-blue-50);
} */

.v-table-container.__v-table-ns {
  border-radius: var(--rounded-border-main);
  background: var(--color-neutral-0);
  min-height: 200px;
}

.v-table-loader-container.__v-table-ns {
  --centering-offset-top: 0;

  position: absolute;
  top: 0;
  left: 0;
  display: grid;
  justify-items: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-top: var(--centering-offset-top);
  z-index: var(--stack-level-10);
}

.v-table-title.__v-table-ns {
  padding: 18px var(--b-gap2);
  box-shadow: var(--shadow-border-bottom);
}

.v-table-title-update-btn.__v-table-ns {
  padding: 0;
}

.b-select__option[id*='k-multi-select-apply-option'] {
  padding-left: 0;
  padding-right: 0;
}

.v-table__select-counter.__v-table-ns {
  display: flex;
  justify-content: center;
  align-items: center;
  height: min-content;
  padding: var(--b-gap-micro) var(--b-gap-tiny);
  border-radius: var(--b-gap3);
  background: var(--color-neutral-20);
  color: var(--color-neutral-60);
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  min-width: 40px;
}

.v-table-filters__more-filters.__v-table-ns {
  position: relative;
  padding: var(--b-gap3);
  background: var(--color-neutral-0);
  border-radius: var(--rounded-border-main);
  margin-top: var(--b-gap1);
}

.v-table-filters__more-filters-arrow {
  top: -8px;
  right: 60px;
  position: absolute;
  background: inherit;
}

.v-table-filters__more-filters-arrow:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 20px;
  background: inherit;
  transform: rotate(45deg);
}

.v-table-filters__more-filters-close.__v-table-ns {
  position: absolute;
  top: 0;
  right: 0;
  padding: var(--b-gap1);
  color: var(--color-blue-50);
}

.v-table-filters.__v-table-ns .b-select-multi__menu {
  z-index: var(--stack-level-higher);
}

.v-table-pagination.__v-table-ns {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  background: var(--color-neutral-0);
  padding: var(--b-gap);
  box-shadow: var(--shadow-border-top);
}

.v-table-pagination__right-controls.__v-table-ns {
  --pagination-template-columns: repeat(4, auto);

  display: grid;
  grid-template-columns: var(--pagination-template-columns);
  align-items: center;
  justify-self: end;
  gap: var(--b-gap-tiny);
}

.v-table-pagination__page-num-control.__v-table-ns {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

[data-v-table-frozen='true'] {
  position: sticky;
  background: var(--color-neutral-0);
}

[role='gridcell'].__v-table-ns {
  display: grid;
  justify-content: left;
  align-items: center;
  text-align: left;
  width: var(--post-layout-effect-full-width);
  height: 100%;
  padding: 18px var(--b-gap2);
  box-shadow: var(--shadow-border-bottom);
  background: var(--color-neutral-0);
  color: var(--color-neutral-60);
}

[role='gridcell'].__v-table-ns a:link,
[role='gridcell'].__v-table-ns a:visited {
  color: var(--color-blue-50);
}

[data-v-table-row-action-type] {
  display: grid;
  place-items: center;
  width: var(--post-layout-effect-full-width);
  height: 100%;
  box-shadow: var(--shadow-border-bottom);
  background: var(--color-neutral-0);
}

[data-v-table-expanded='true'].__v-table-ns {
  border-top: 1px solid var(--v-table-blue-border);
  border-bottom: 1px solid var(--v-table-blue-border);
  background: var(--v-table-blue-ultralight);
}

[data-v-table-expanded='true'][data-v-table-col-num='1'] {
  border-left: 1px solid var(--v-table-blue-border);
}

[data-v-table-expanded='true'][data-v-table-last-col='true'] {
  border-right: 1px solid var(--v-table-blue-border);
  width: 100%;
}

[role='rowgroup'][data-v-table-row-expanded='true'] {
  display: grid;
  grid-column: 1 / -1;
  align-items: start;
  position: sticky;
  left: 0;
  width: 100%;
  padding-left: var(--b-gap2);
  padding-right: var(--b-gap2);
  background: var(--v-table-blue-ultralight);
  contain: paint;
}

[role='rowheader'] {
  position: relative;
  padding-top: 18px;
  padding-bottom: 18px;
  color: var(--color-neutral-60);
}

[role='gridcell'].__v-table-ns.--expanded {
  position: relative;
  box-shadow: none;
  background: none;
  padding: 18px calc(2 * var(--b-gap3)) 18px 0;
}

[role='gridcell'].__v-table-ns.--expanded::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: calc(var(--offset-frozen-col-left) * -2px);
  width: 100vw;
  height: 1px;
  box-shadow: var(--shadow-border-bottom);
}

[role='gridcell'][data-v-table-col-type='actionsmenucol'].__v-table-ns {
  position: relative;
  justify-content: end;
}

[role='gridcell'][data-v-table-col-type='actionsmenucol'].__v-table-ns
  .actions-menu__toggle-btn {
  color: var(--color-neutral-50);
}

[role='columnheader'].__v-table-ns {
  display: flex;
  align-items: center;
  gap: var(--b-gap-tiny);
  position: sticky;
  top: 0;
  width: var(--post-layout-effect-full-width);
  height: 100%;
  padding: 14px;
  padding: 14px var(--b-gap2);
  box-shadow: var(--shadow-border-bottom);
  background: var(--color-neutral-0);
  font-weight: 700;
  z-index: var(--stack-level-med);
}

[role='columnheader'].__v-table-ns:not([data-v-table-last-col='true']):after {
  position: absolute;
  content: '';
  right: 0;
  width: 1px;
  height: var(--b-gap2);
  box-shadow: var(--shadow-border-right);
}

[role='columnheader'][data-v-table-frozen='true'].__v-table-ns:not(
    [data-v-table-last-col='true']
  ):after {
  width: 5px;
  height: var(--show-frozen-pane-border-height);
  right: -5.2px; /* float px for correctly positioning :after border shadow */
  box-shadow: var(--show-frozen-pane-border-shadow);
}

[role='columnheader'][data-v-table-frozen='true'].__v-table-ns {
  top: 0;
  z-index: var(--stack-level-high);
}

[role='columnheader'].header--no-divider.__v-table-ns::after {
  width: 0;
}

.frozen-pane-overlay-container.__v-table-ns {
  position: sticky;
  left: 0;
  grid-column: 1 / span var(--last-frozen-col-num);
  grid-row: 1 / -1;
  width: 100%;
  height: 100%;
  transform: translate3d(20%, 0, 0);
  pointer-events: none;
}

.frozen-pane-overlay.__v-table-ns {
  width: 80%;
  height: 100%;
  box-shadow: var(--shadow-main);
  opacity: var(--display-frozen-pane);
  pointer-events: none;
}

.expand-row-action__btn.__v-table-ns {
  color: var(--color-neutral-60);
  transform: rotate(-90deg);
  cursor: pointer;
}

.expand-row-action__btn.__v-table-ns i {
  transform: rotate(-90deg);
  transition:
    color var(--b-animation1b),
    transform var(--b-animation1b);
}

.expand-row-action__btn.__v-table-ns.--expanded {
  transform: none;
  color: var(--color-neutral-100);
}

.expand-row-action__btn.__v-table-ns.--expanded i {
  transform: none;
  transition:
    color var(--b-animation1b),
    transform var(--b-animation1b);
}

.header__sort-btn.__v-table-ns {
  display: inline-block;
  padding: 0;
  color: var(--color-neutral-100);
}

.header__info.__v-table-ns {
  display: inline-block;
}

.header__info-content.__v-table-ns {
  position: relative;
}

.header__info-icon.__v-table-ns {
  color: var(--color-neutral-60);
  cursor: default;
}

.header__info-icon.__v-table-ns i.bi {
  line-height: unset;
}

.v-table-no-content {
  grid-column-start: 1;
  grid-column-end: -1;
  justify-self: center;
  padding: var(--b-gap3);
  width: 480px;
  text-align: center;
}

.v-table__no-content-img {
  /* width: 143px;
  height: 50px; */
  width: 177px;
  height: 73px;
}

.v-table-sort-indicator__toggle-btn {
  padding: 0;
}

.v-table-sort-indicator__icon {
  --icon-opacity: 1;

  opacity: var(--icon-opacity);
  transition: opacity var(--b-animation1b);
}
