/* ======================== */
/* ==========!BAR========== */
/* ======================== */

/* TODO: add variables into theme package */

$bar-height: 35px;

/* ----------!MAIN---------- */
.bar {
  background-color: #fff6e1;
  height: $bar-height;
  z-index: 9999;
}

/* ----------!TYPE---------- */
.bar-disabled-tariff-notificator {
  background: $warning;
  font-weight: 500;
  color: $white;
  a {
    color: $white;
    text-decoration: underline;
    &:hover {
      color: $grey-200;
    }
  }
}
