@import '../../../assets/styles/base/variables';

.activity-tab-new-description {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 31px;
  padding: 9px 10px 9px 0;

  .expand-wrapper {
    width: 16px;
    padding-right: 22px;

    .expand-icon {
      font-size: 16px;
      color: $marengo-680;
    }
  }
  .icon-tag-wrapper {
    width: 20px;
    padding-right: 36px;
    color: #666;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .description-text-wrapper {
    white-space: pre-line;
    .title {
      font-weight: 500;
    }
  }
}

.activity-tab-no-data-view {
  width: 100%;
  height: 59px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-family: var(--font-family-primary);
  background-color: $white;
}

.activity-tab-cancel-command-btn:hover {
  background-color: var(--color-neutral-0);
}

.activity-tab-btn-group {
  display: flex;
  flex-flow: row wrap;
  > div {
    width: 230px;
    margin: 0 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
