.self-service {
  margin-bottom: 17px;
}

/* Outer table */
.self-service .inner {
  border-top: 1px solid $color-divider;
}

/* Inner table header */
.self-service .inner .header > div {
  color: $color-black;
  padding: $space-paper;

  font-weight: 500;
  font-size: 12px !important;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 1;

  &.expand {
    flex: 1 1 auto;
    display: flex;
    align-items: center;

    &:hover {
      cursor: pointer;
    }

    &:hover .caret {
      display: inline-block;
    }
  }
}

.ss-sort-caret.caret {
  display: none;
  background-color: $color-white;
  margin-top: 2px;
  margin-left: 10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: $color-system-link;
}

.self-service .inner .header .line {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -15px;
    top: 25%;
    width: 1px;
    height: 50%;
    background: $color-divider;
  }
}

/*
 * Rows
 * During move tranistion - elements are attached to the body, losing their current context and any inhertited styles
 * Use global unique ids to attach styles
 */

%content-inner-styles {
  font-family: var(--font-family-primary);
  font-weight: 500;
  font-size: 14px;
  color: $color-black;

  padding: 18px 0;
  line-height: 2;
}

.self-service-sort-content {
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-divider;

  &.edit {
    background-color: rgba(26, 29, 37, 0.05);
  }

  &:focus {
    outline: none;
  }

  &.editable:hover {
    background-color: rgba(26, 29, 37, 0.05);
  }
}

.self-service-sort-icon {
  padding: 14px 14px 14px 0;
  width: 100px;

  position: relative;

  @extend %content-inner-styles;
  cursor: pointer;

  display: flex;
  align-items: center;

  &.static {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  img {
    width: 27px;
    height: 27px;
    object-fit: contain;
  }
}

#self-service-sort-expand {
  padding: 12px 0 12px 10px;
  @extend %content-inner-styles;
  font-family: var(--font-family-primary);
  font-weight: 700;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &.static {
    cursor: default;
  }

  &.edit {
    @include styleInput();
    @include styleInputSize();
    // overrides
    padding: 6px ($space-paper - 2px);
    font-weight: 400;
    cursor: default;
  }

  &.edit-name {
    border-color: $color-system-link;
    cursor: text;
  }
}

div.self-service-sort-move {
  @extend %content-inner-styles;

  padding: $space-paper;
  width: 90px;

  .move-icon {
    border-top: 2px solid $color-system-link;
    border-bottom: 2px solid $color-system-link;
    padding: 1px;
    width: 17px;
    height: 30%;
    text-align: center;
  }

  .move-icon:not(.disabled) {
    cursor: row-resize;
  }

  .move-icon.disabled {
    border-top: 2px solid $color-brand-marengo-medium;
    border-bottom: 2px solid $color-brand-marengo-medium;
    pointer-events: initial;
    cursor: not-allowed;
  }
}

.self-service-sort-edit {
  padding: 0 30px 0 0;
  width: 65px;
  position: relative;
  @extend %content-inner-styles;

  display: flex;
  justify-content: flex-end;
  color: $color-system-link;
  font-size: 20px;

  &.disabled {
    color: $color-brand-marengo-medium;
    pointer-events: initial;

    > .icon {
      cursor: not-allowed;
    }

    > .icon > svg {
      fill: $color-brand-marengo-medium;
    }
  }

  &:not(.disabled) {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  &.is-error.is-editing {
    position: relative;
    top: -13px;
  }

  > .icon {
    margin-left: -8px;
    margin-right: -8px;
    height: 16px;
  }

  > .icon > svg {
    fill: $color-system-link;
  }

  .fas {
    font-weight: $font-weight-2;
  }
}

/* Add new row */
.self-service .add {
  color: $color-system-link;
  font-weight: 500;
  padding: 20px;
  display: flex;

  .add-text {
    margin-left: 8px;
    cursor: pointer;
  }

  svg {
    cursor: pointer;
    fill: $color-system-link;
  }
}

.self-service .add.disabled .add-text,
.self-service .add.disabled svg {
  color: $color-brand-marengo-medium !important;
  fill: $color-brand-marengo-medium !important;
  opacity: 1;
  pointer-events: initial;
  cursor: not-allowed;
}

div.self-service-sort-icon.ss-icon-th.ss-icon-header {
  padding: 12px 0 12px 0;
  width: auto;
  font-family: var(--font-family-primary);
  font-weight: 700;
}

.ss-categ-header.header {
  display: grid;
  grid: 45px / 87px 110px 1fr 60px;
  border-bottom: 1px solid $color-divider;
}

.ss-cat-input.theme-form-text-input[readonly] {
  border: none;
  font-weight: 500;
  padding-left: 16px;
  &:not(.static):hover {
    cursor: pointer;
  }
}

.ss-cat-input:not([readonly]).is-error {
  margin-top: 4px;
}

.ss-cat-input[readonly].is-error::placeholder {
  color: $color-system-error;
}

.ss-cat-input,
.ss-cat-input::placeholder {
  font-family: $font-family-1;
  font-size: $font-size-text;
}

.ss-cat-input.theme-form-text-input.static:hover {
  cursor: default;
}

.ss-cat-icons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  div {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;

    &:hover {
      background-color: $color-grey-1;
    }

    &.selected {
      background-color: $color-system-link;

      img {
        filter: invert(1);
      }
    }

    img {
      width: 20px;
      height: 20px;
      object-fit: contain;
    }
  }
}

.dropdown-menu.show.ss-icons-dropdown {
  position: absolute;
  top: calc(100% + 11px);
  left: -62px;
  width: 346px;
  height: 224px;
  overflow-y: scroll;
  padding: 12px;
}

.ss-cat-dropdown .dropdown-toggle.caret:empty::after,
.ss-cat-dropdown .dropdown-toggle.caret::after {
  content: '';
  background-color: transparent;
  margin-left: 20px !important;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-top-color: $color-system-link;
}

.ss-cat-dropdown .dropdown-toggle.caret {
  display: flex;
  align-items: center;
}

.ss-extra-options {
  width: 10px;
  margin: 0 30px;
}

.ss-extra-options.is-error.is-editing {
  position: relative;
  top: -13px;
}

.ss-extra-options .dropdown-toggle[disabled] svg {
  fill: rgba(26, 29, 37, 0.4);
}

.ss-extra-options .dropdown-toggle[disabled] {
  cursor: not-allowed;
}

.ss-extra-options .icon-ellipsis {
  width: 10px;
  height: 17px;
}

.ss-extra-options .dropdown-toggle {
  padding: 0;
  margin: 0;
}

.ss-extra-options .dropdown-action {
  margin-right: 0;
}

.ss-extra-options .dropdown-menu.show {
  min-width: 113px;
  min-height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  right: -3px;
}

.ss-extra-options .dropdown-menu.show button {
  font-weight: 500;
}
