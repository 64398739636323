.pkcs-d__cert-container {
  display: flex;
  gap: var(--b-gap1);
  align-items: center;
}

.pkcs-d__cert-image {
  height: 48px;
  width: 48px;
  object-fit: contain;
}

.pkcs-d__cert-date {
  margin-top: var(--b-gap-micro);
  color: var(--color-neutral-60);
  font-weight: normal;
}
