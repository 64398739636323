.multi-row .inner {
  border: 1px solid $color-grey-2;
  border-radius: 5px;
}

.multi-row .inner .header,
.multi-row .inner .content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $color-grey-2;
}

.multi-row .inner .content:last-child {
  border-bottom: none;
}

.multi-row .inner .content .remove {
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
    fill: $color-black;
  }
}

.multi-row .inner .content > div,
.multi-row .inner .header > div {
  flex: 1 0 25%;
  padding: 20px;
  text-transform: uppercase;
}

.multi-row .inner .header > div {
  color: $color-black;
  letter-spacing: 2px;
  font-weight: 500;
  font-size: 12px;
  line-height: 0.5;
}

.multi-row .inner .header .line {
  position: relative;
}

.multi-row .inner .header .line:before {
  content: '';
  position: absolute;
  left: 0;
  top: 25%;
  width: 1px;
  height: 50%;
  background: $color-grey-2;
}

.multi-row .add {
  padding: 20px;
  display: flex;

  .add-text {
    margin-left: 10px;
  }

  svg {
    cursor: pointer;
    fill: $color-black;
  }
}
