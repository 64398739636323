.k-kandji-link-config-wrapper {
  position: absolute;
  top: 195px;
  left: 0;
}

.k-ks-link-config {
  /* couldnt find the color in design system */
  background: rgba(245, 245, 245, 1);
  padding: 24px 24px 52px;
  width: 728px;
  border-radius: 4px;
  margin-bottom: var(--b-gap3);
}

.k-ks-comp-link-preview .k-library-org-logo__preview {
  background: #fff;
}

.k-ks-link-config-form .b-grid-fields.--hook:not(:last-child) {
  margin-bottom: 24px;
}
