.blueprint-list {
  margin-top: 2rem;
  display: grid; /* 1 */
  grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
  grid-gap: 2rem; /* 3 */
  //justify-content: space-between; /* 4 */
}

@media (max-width: 1200px) {
  .blueprint-list {
    //justify-content: space-evenly;
  }
}

.add-blueprint-container {
  width: 100%;
  height: 240px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
}

@media (max-width: 1000px) {
  .add-blueprint-container {
    //width: 350px;
  }
  .blueprint-list {
    //grid-template-columns: repeat(auto-fill, 350px);
  }
}

.svg-background-dotted {
  width: 180px;
  height: 180px;
  cursor: pointer;
  background-size: 100% 100%;
  background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 120 120' version='1.1' xmlns='http://www.w3.org/2000/svg'><circle cx='60' cy='60' r='50' fill='transparent'/><circle cx='60' cy='60' r='47' stroke='%23999' stroke-width='2' stroke-dasharray='0 5' stroke-linecap='round' fill='transparent'/></svg>")
    no-repeat;
}

.svg-container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.add-blueprint-text {
  color: $grey-400;
  font-size: 0.8rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.add-blueprint-icon {
  color: $grey-400;
}

.blueprint-search-input {
  -webkit-appearance: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 3em;
  color: $grey-400;
  width: 100%;
}

.input-style {
  &::placeholder {
    font-family: var(--font-family-primary);
    font-size: 14px;
    line-height: 17px;
    color: rgba(26, 29, 37, 0.5);
  }
}

@media (max-width: 1200px) {
  .btn-bt-add {
    margin-left: 0 !important;
  }
}
