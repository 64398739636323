.modal-feature-request {
  min-height: unset;
}
@media (min-width: 768px) {
  .modal-feature-request {
    max-width: 600px;
  }
}
.modal-feature-request .b-txt-input--error-state {
  margin-bottom: var(--b-gap2);
}
.modal-feature-request .b-modal__footer-wrapper {
  justify-content: space-between;
}
.modal-feature-request .b-modal__header_icon {
  color: var(--color-red-50);
}
.modal-feature-request .theme-form-block-valid {
  padding-top: var(--b-gap2);
}
.modal-feature-request .theme-form-textarea-input {
  min-height: 210px;
}
.modal-feature-request .theme-form-label > label,
.modal-feature-request .theme-form-group-label,
.modal-feature-request .theme-form-text-label,
.modal-feature-request .theme-form-textarea-label,
.modal-feature-request .theme-form-upload-label {
  margin-bottom: var(--b-gap1);
}
.fr-tile-loop {
  margin: -8px;
  padding-top: 20px;
  display: flex;
}
.fr-tile {
  padding: 8px;
  width: 50%;
}
.fr-tile > .tile {
  border: 2px solid var(--color-neutral-30);
  border-radius: 10px;
  padding: 30px 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  outline: 0 !important;
  box-shadow: none !important;
  min-height: 276px;
}
.fr-tile > .tile:hover,
.fr-tile > .tile:focus {
  border-color: var(--color-neutral-50);
}
.fr-tile > .tile .fr-icon {
  width: 45px;
  height: 45px;
}
.fr-tile > .tile .fr-icon + .label {
  margin-top: 20px;
}
.fr-tile > .tile .fr-icon {
  color: #98989d;
}
.fr-tile > .tile .label {
  margin: 0;
}
.fr-tile > .tile .label + .description {
  margin-top: 3px;
}
.fr-tile > .tile .description {
  color: var(--color-neutral-50);
}
