.k-am-os-rule-list {
  display: grid;
  margin-left: 8px;
  padding: 12px;
  border: 1px solid #D7E1ED;
  border-radius: 4px;
  width: 930px;
  gap: 10px;
}

.k-am-os-rule {
  display: grid;
  grid-template-columns: auto auto auto;
}

.k-am-os-input {
  width: 226px;
  padding-right: 8px;
}

.k-am-os-operator {
  width: 250px;
  padding-right: 8px;
}

.k-am-os-value {
  width: 385px;

    .b-txt-input {
      margin-bottom: 0;
    }

    .b-txt-input__err-msg {
      position: relative;
      bottom: -4px;
      display: flex;
      align-items: start;
      gap: 2px;

      .b-txt--error {
        padding-top: 2px;
      }
    }
}
