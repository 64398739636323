.registration {
  min-height: 900px;
  padding-left: 100px;
  font-weight: 400;
  .registration-title,
  .registration-or {
    font-size: 25px;
  }
  .registration-title {
    margin: 40px 0 70px 0;
  }
  form {
    width: 100%;
    label {
      width: 100%;
    }
  }
  .registration-user-info {
    background: $param-switcher-area-dark-grey;
    border: 1px solid #ced4da;
    border-radius: 10px;
    padding: 20px 40px;
    .form-control:disabled {
      background-color: inherit;
      opacity: 1;
      border: 0;
    }
  }
  .registration-or,
  .registration-google {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .registration-google {
    justify-content: space-between;
  }

  .user-meta-info-container {
    margin-top: 50px;
    font-size: 14px;
    .user-meta-info {
      margin-bottom: 20px;
      .user-meta-info-value {
        margin-left: 10px;
      }
    }
  }
  .registration-terms {
    font-size: 14px;
    .registration-terms-text {
      height: 150px;
      overflow-y: auto;
      background: $param-switcher-area-dark-grey;
      border: 1px solid #ced4da;
      padding: 10px;
    }
    .registration-terms-checkbox {
      margin: 20px;
    }
  }

  .registration-btn {
    text-align: right;
    button {
      width: 150px;
    }
  }
}
