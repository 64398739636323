/* ========================= */
/* ==========!CHIP========== */
/* ========================= */

/* ----------!MAIN---------- */
.chip > span {
  background-color: var(--color-neutral-10);
  border-radius: $border-radius-dull-small;
  padding: 0 8px;
  height: 24px;
  font-size: 12px;
  line-height: 10px;
  text-align: center;
  letter-spacing: 0.2px;
  color: $color-system-information;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.1s ease 0.1s;
}
.chip > span > b {
  margin-top: 1px;
  margin-left: 8px;
  font-size: 14px;
  font-weight: $font-weight-1;
}
.chip > span > .icon {
  margin-left: 8px;
  width: 10px;
  height: 10px;
}
.chip > span > .icon > svg {
  fill: $color-system-information;
}

/* ----------!EMPTY---------- */
.chip-empty > span {
  background-color: $color-transparent;
  border: 1px solid var(--color-neutral-10);
  font-weight: $font-weight-3;
}

/* ----------!LIST---------- */
.chip-list {
  margin: -4px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.chip-list > .chip {
  padding: 4px;
}

/* ----------!WRAP---------- */
.chip-list-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
