.k-library-org-logo {
  display: grid;
  grid: auto auto / 108px 1fr;
  grid-column-gap: 16px;
  position: relative;
}

.k-library-org-logo.--large-preview {
  grid: auto auto / 157px 1fr;
  grid-column-gap: 20px;
}

.k-library-org-logo.k-library-org-logo--disabled {
  opacity: 0.5;
}

.k-library-org-logo__uploader {
  position: relative;
}

.k-library-org-logo__preview {
  position: relative;
  color: inherit;
  border: 1px solid var(--color-neutral-20);
  border-radius: 12px;
  width: 108px;
  height: 108px;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 108px;
  background: var(--color-neutral-20);
}

.k-library-org-logo__preview.--large-preview {
  width: 157px;
}

.k-library-org-logo__preview.--dark-mode {
  background: rgba(46, 46, 47, 1);
}

.k-library-org-logo__preview.--has-error {
  border: 2px solid var(--color-red-50);
}

.k-library-org-logo__preview--delete {
  border: 2px solid var(--color-red-50);
}

.k-library-org-logo__preview.--round-corner-preview img {
  border-radius: 9px;
}

.k-library-org-logo__preview > img {
  width: 72px;
  height: 72px;
  object-fit: contain;
  object-position: center;
}

.k-library-org-logo__preview.--small-preview > img {
  width: 72px;
}

.k-library-org-logo__preview.--large-preview > img {
  width: 153px;
  height: 100px;
}

.k-library-org-logo__preview__delete-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(238, 52, 52, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-library-org-logo__preview__delete-overlay {
  color: var(--color-red-50);
  padding: 3px;
  border-radius: 3px;
  background: rgb(255 255 255 / 75%);
}

.k-library-org-logo__preview__delete-overlay:hover {
  cursor: pointer;
}

.k-library-org-logo__droparea {
  padding: 0;
  height: 106px;
  border: 2px dashed var(--color-neutral-30);
  background: var(--color-neutral-0);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 4px;
  outline: none;
}

.k-library-org-logo__droparea.k-library-org-logo__droparea--active {
  border: 2px solid var(--color-blue-60);
}

.k-library-org-logo__error {
  color: var(--color-red-50);
  display: grid;
  grid: auto/auto 1fr;
  grid-column-gap: 4px;
  align-items: flex-start;
  grid-area: 2/1/3/3;
  margin-top: var(--b-gap-tiny);
}

.k-library-org-logo__error p.b-txt {
  color: var(--color-red-50);
}

.k-library-org-logo__droparea .bi {
  color: var(--color-neutral-30);
  font-size: 20px;
  margin-bottom: 6px;
  position: relative;
  top: 1px;
  left: 4px;
}

.k-library-org-logo__droparea p {
  margin: 0;
  margin-top: 4px;
  position: relative;
  left: 4px;
}
