@import '../../../../../assets/styles/base/variables';

.PrettyInputWrapper {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0 0 0 1px $grey-250;
  padding: 10px 15px;
  background-color: $grey-80;
  transition: all 0.2s;
  margin-bottom: 15px;
  &.active {
    box-shadow: 0 0 0 2px $grey-900;
    .PrettyInputLabel {
      color: $grey-999;
    }
  }
  .PrettyInputLabel {
    color: $grey-400;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.2em;
  }
  .PrettyInputInput {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    outline: none;
    box-shadow: none;
    border: none;
    background-color: inherit;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    color: $grey-999;
  }
  .PrettyInputTextarea {
    overflow: auto;
    outline: none;
    box-shadow: none;
    border: none;
    background-color: inherit;
    border-radius: 0;
    font-size: 14px;
    font-weight: 400;
    font-family: var(--font-family-primary);
    color: $grey-999;
    resize: none;
    padding: 0;
    height: 84px;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: $grey-400;
      border-radius: 4px;
    }
  }
}
