$dark-blue: #2274c8;
$light-blue: #3087e0;
$blue-link: #000000;
$primary-blue: #101629;
$darkest-blue: #071e4c;
$dark-grey-new: #2b2d50;
$light-grey-new: #697386;
$beige: #fff6e1;
$orange: #ff5100;
$orange-selected: #f36a19;
$selected-blue: #f3f9ff;
$text-normal-grey: #636c72;
$grey-border: #d9e0e8;
$light-pink: #f05b7e;
$pale-blue: #accff3;
$light-grey-border: #e3eaf2;
$breakpoint-tablet: 768px;
$dark-grey-text: #797979;
$light-grey-text: #8ea5bc;
$text-black: #2e3338;
$text-dark-grey: #7290af;
$text-light-grey: #b5bfca;
$body-bgc: #f5f7fa;
$dark-red: #e02e2e;
$param-switcher-area-grey: #f9fafd;
$param-switcher-area-dark-grey: #eaebed;
$param-switcher-shadow-grey: #d4d2d4;

$red-500: #e52d2e;

$orange-500: #f16116;
$orange-400: #f27029;
$orange-300: #f19c16;

$yellow-500: #ffcf01;
$yellow-400: #ffd229;
$yellow-300: #fccd01;

$green-500: #71a75b;
$green-400: #80b069;
$green-300: #bfc45b;

$aqua-800: #4575b1;
$aqua-500: #5ba6a7;

$purple-500: #504ea5;
$purple-400: #826ab7;

$marengo-700: #1b1f29;
$marengo-699: #1d242f;
$marengo-680: #1f242f;
$marengo-650: #292f3e;
$marengo-640: #363f53;
$marengo-630: #3d4557;
$marengo-600: #3b4459;
$marengo-550: #424f6c;
$marengo-500: #42506b;
$marengo-450: #4e5b78;
$marengo-400: #4e5c77;
$marengo-380: #595e6b;
$marengo-350: #606673;
$marengo-320: #a6a8af;
$marengo-300: #888e9d;
$marengo-250: #9ca3b3;
$marengo-200: #a5aab5;
$marengo-100: #caceda;
$marengo-50: #dcdfe6;
$marengo-70: #d8deeb;
$marengo-30: #e6e9f0;

$grey-999: #0f0f0f;
$grey-900: #1a1d25;
$grey-800: #333;
$grey-700: #444;
$grey-600: #555;
$grey-500: #666;
$grey-450: #6c757d;
$grey-430: #808080;
$grey-410: #909196;
$grey-405: #92969e;
$grey-400: #999;
$grey-300: #b2b2b2;
$grey-280: #afb2b8;
$grey-270: #bfbfbf;
$grey-250: #ccc;
$grey-230: #d6d6d6;
$grey-200: #dedede;
$grey-150: #dee0e4;
$grey-130: #e0e0e0;
$grey-100: #f0f0f0;
$grey-80: #f0f2f7;
$grey-70: #f2f4f9;
$grey-50: #f6f6f6;

$blue-500: #2274c8;
$blue-400: #3087e0;
$blue-50: #f0f2f7;

$marengo-700-transparent-1: unquote('#1B1F29FA');
$marengo-600-transparent-300: unquote('#3B455950');

$grey-300-transparent-300: unquote('#B7B7B750');
$grey-300-transparent-500: unquote('#B7B7B799');
$grey-200-transparent-300: unquote('#DEDEDE50');

$white: #fff;
$black: #000;

$button-primary-bg: #1f242f;
$button-primary-hover-bg: #585e6c;
