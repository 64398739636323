/* =============================== */
/* ==========!BREADCRUMB========== */
/* =============================== */

/* ----------!MAIN---------- */
.breadcrumb {
  background-color: $color-transparent;
  border-radius: 0;
  margin-top: -4px;
  margin-bottom: -4px;
  padding: 0;
}
.breadcrumb > .breadcrumb-item,
.breadcrumb > .breadcrumb-item > a {
  font-family: $font-family-breadcrumb;
  font-weight: $font-weight-breadcrumb;
  font-size: $font-size-breadcrumb;
  line-height: $line-height-breadcrumb;
  text-transform: uppercase;
  text-decoration: none;
  letter-spacing: 1.5px;
}

.breadcrumb > .breadcrumb-item.active {
  max-width: 15rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.breadcrumb > .breadcrumb-item,
.breadcrumb > .breadcrumb-item > a {
  color: $color-system-link;
}
.breadcrumb > .breadcrumb-item > a:active {
  color: $color-system-link-active;
}
.breadcrumb > .breadcrumb-item > a:hover,
.breadcrumb > .breadcrumb-item > a:focus {
  color: $color-system-link-hover;
}
.breadcrumb > .breadcrumb-item + .breadcrumb-item::before {
  padding-right: 8px;
  color: $color-brand-marengo-ultradark;
}

.breadcrumb > .breadcrumb-item.active {
  color: $color-brand-marengo-ultradark;
  max-width: unset;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
