body {
  font-family: var(--font-family-primary);
  font-size: 14px;
}

.user-portal-form {
  margin: 20px 0;
  padding: 20px;
  color: black;
  .admin-portal-header {
    margin: 10px 0;
    div {
      div {
        span {
          color: black;
          font-weight: 400;
        }
      }
    }
  }
}

.specialselect {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  border: 0 solid red;
  outline: none;
  cursor: pointer;
  background-color: white;
  &:disabled {
    background-color: inherit;
  }
}

.snackbar-root {
  left: 92px !important;
  bottom: 95px !important;
  div {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
    max-width: 380px;
    padding: 15px 70px 15px 20px;
    font-family: var(--font-family-primary);
    font-size: 14px;
    line-height: 20px;
    background: #1b1f29;
    color: #eef0f6;
    border-radius: 4px;
  }
  svg {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    opacity: 0.7;
  }
  path {
    fill: #eef0f6;
  }
}

div#hubspot-messages-iframe-container {
  z-index: 600;
  //bottom: 70px!important;
  &.widget-align-right {
    //right: 130px!important;
  }
  .shadow {
    box-shadow: none !important;
    &.active {
      background: none !important;
    }
  }
}

iframe {
  .widget-launcher {
    box-shadow: none;
    &:hover {
      box-shadow: none;
    }
  }
  .chat-widget {
    box-shadow: none;
  }
}

.b-side-panel {
  z-index: 600;
}
