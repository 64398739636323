.adcs-authentication {
  /* override the global body margin-bottom */
  margin-bottom: 0;

  height: 100vh;
  width: 100vw;
}

.adcs-authentication #app {
  min-width: unset;
}

.k-adcs-container {
  padding-top: 60px;
  font-family:
    'SF Pro Text',
    -apple-system,
    BlinkMacSystemFont,
    sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.k-adcs-logo {
  width: 175px;
  height: auto;
}
.k-adcs-content {
  padding-top: 60px;
  padding-bottom: 20px;
  max-width: 420px;
  text-align: center;
}
.k-adcs-content.k-adcs-loader {
  padding-top: 35px;
}
.k-adcs-content.k-adcs-loader
  > .loader
  .loader-with-text2
  .loader-with-text-data {
  color: var(--color-neutral-100);
}
.k-adcs-content.k-adcs-loader > .k-adcs-footer {
  padding-top: 20px;
}
.k-adcs-message {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
}
.k-adcs-info {
  padding-top: 15px;
}
.k-adcs-button {
  padding-top: 40px;
  display: flex;
  justify-content: center;
}
.k-adcs-footer {
  padding-top: 40px;
  font-weight: 400;
  color: var(--color-neutral-50);
}
