.k-cert-li__san-container {
  /* max-height: 450px;
    overflow: auto; */
}

.k-cert-li__san-row > button {
  margin-top: var(--b-gap3);
}

.k-cert-li__san-inputs {
  width: 760px;
  margin-bottom: var(--b-gap1);
}
.b-select__option {
  font-family: var(--font-family-primary);
  font-size: 14px;
}
