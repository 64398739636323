/** Bumblebee should be updated to be more modular around disabled state */
:not(.b-select__option--is-selected).b-select__option--is-disabled {
  color: var(--color-neutral-30) !important;
  user-select: none;
  pointer-events: none;
}

.b-select__option--is-selected.b-select__option--is-disabled:hover {
  background: var(--color-blue-70) !important;
}

.b-select__option--is-disabled:hover {
  user-select: none;
  pointer-events: none;
}
