.company-integration-container {
  min-height: 300px;

  > * {
    margin-bottom: 15px;
  }

  .integration-tabs {
    .integration-tab {
      height: 50px;

      &.active {
        border-bottom: 1px solid $darkest-blue;
      }
    }
  }
}
.slack-integration-item {
  margin-top: 17px;
  border-radius: 4px;
  margin-bottom: 10px;
  background-color: #f6f7f9;
  width: 100%;
  //max-width: 900px;
  //min-width: 500px;
  .header {
    height: 71px;
    align-items: center;
    .logo {
      width: 60px;
    }
    .name {
      font-weight: 500;
      width: 150px;
      font-size: 14px;
      line-height: 17px;
      font-family: var(--font-family-primary);
    }
    .domain {
      font-weight: 200;
      color: $light-grey-new;
      padding: 0 20px;
      font-size: 14px;
      line-height: 17px;
      font-family: var(--font-family-primary);
    }
    .edit {
      margin-left: auto;
      padding: 20px;
      color: #1d212a;
      cursor: pointer;
      font-size: 10px;
      font-weight: 700;
      line-height: 13px;
      letter-spacing: 0.15em;
      text-transform: uppercase;
      i {
        font-size: 17px;
      }
      &:hover {
        color: $dark-grey-new;
      }
    }
  }
  .body {
    padding: 20px;
    min-height: 200px;
    border-top: 2px solid rgba(26, 29, 37, 0.05);
    .auth {
      &:hover {
        color: $darkest-blue;
      }
    }
    .buttons {
      margin-top: 20px;
      padding-top: 20px;
      border-top: 2px solid rgba(26, 29, 37, 0.05);
      button {
        margin-right: 10px;
      }
    }
    .group-title {
      margin-top: 20px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.03em;
    }
    .setting-group {
      padding-top: 20px;
      padding-bottom: 10px;
      &:last-of-type {
        border-bottom: None;
      }
      .setting-title {
        font-weight: 500;
        width: 100px;
        padding-right: 20px;
      }
    }
  }
}
