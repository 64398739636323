.agent-logs table {
  width: 100%;
  font-family: 'Untitled Sans', sans-serif;
  font-size: 12px;
  background-color: white;
  border: 1px solid #CAD5E5;
  line-height: 1.5;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
}

.agent-logs tbody {
  text-align: left;
  font-weight: normal;
}

.agent-logs th {
  font-weight: 500;
}

.agent-logs td {
  height: 50px;
}

.agent-logs th, .agent-logs td {
  padding: 8px 12px;
  border-bottom: 1px solid #CAD5E5;
}

.agent-logs tr:hover {
  background-color: #ECF1F7;
}

.agent-logs th:first-child, .agent-logs td:first-child {
  border-right: 1px solid #CAD5E5;
}

.agent-logs .log-message, .agent-logs .device-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.agent-logs .log-message {
  -webkit-line-clamp: 2;
}

.agent-logs .device-name {
  -webkit-line-clamp: 1;
}